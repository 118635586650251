import moment from 'moment';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import Loader from '../../components/loader/Loder';
import TransparentButton from '../../components/transparentButton/TransparentButton';
import SelectionTypeEnum from '../../models/enums/SelectionTypeEnum';
import SelectionApi from '../../services/SelectionAdminApi';
import SelectionView from '../../view/selections/admin/selection/SelectionView';

export default class SelectionController extends Component {
	constructor(props) {
		super(props);
		const query = props.location.search.substring(1).split('&');

		this.state = {
			_id: query[0].split('=')[1],
			name: '',
			notice: '',
			typeSelectionList: '',
			typeSelection: '',
			registrationPeriodBegins: '',
			registrationPeriodEnds: '',
			totalVacancies: 0,
			studentsInterested: [],
			studentsRegistered: [],
			studentsRejected: [],
			currentTab: 0,
			loading: true,
			modalStatus: false
		};
	}

	componentDidMount() {
		SelectionApi.getSelection(this.state._id).then(
			(res) => {
				const {
					registrationPeriodBegins,
					registrationPeriodEnds,
					studentsInterested,
					studentsRegistered,
					studentsRejected,
				} = res.data.message;
				const formatRegistrationPeriodBegins = moment(registrationPeriodBegins)
					.format('DD-MM-YYYY')
					.replace(/-/g, '/');
				const formatRegistrationPeriodEnds = moment(registrationPeriodEnds)
					.format('DD-MM-YYYY')
					.replace(/-/g, '/');

				const studentsInterestedMaped = this.studentsMapToArray(studentsInterested);
				const studentsRegisteredMaped = this.studentsMapToArray(studentsRegistered);
				const studentsRejectedMaped = this.studentsMapToArray(studentsRejected);
				this.setState({
					...res.data.message,
					studentsInterested: studentsInterestedMaped,
					studentsRegistered: studentsRegisteredMaped,
					studentsRejected: studentsRejectedMaped,
					registrationPeriodBegins: formatRegistrationPeriodBegins,
					registrationPeriodEnds: formatRegistrationPeriodEnds,
					loading: false,
				});
			},
			(err) => this.props.history.push(`/usuario/admin/selecoes/ver`)
		);
	}
	studentsMapToArray = (map) =>
		Object.entries(map)
			.map(([key, value]) => {
				value._id = key;
				return value;
			})
			.sort((studentA, studentB) => (studentA.name > studentB.name ? 1 : studentA.name < studentB.name ? -1 : 0));

	includesStudent = (list, student) => list.filter((studentData) => studentData._id === student._id).length > 0;
	actionTemplate = (rowData) => {
		const { notice, status, studentsRegistered, studentsRejected } = this.state;

		return (
			!this.includesStudent(studentsRegistered, rowData) &&
			!this.includesStudent(studentsRejected, rowData) && (
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<TransparentButton
						medium
						onClick={() =>
							this.props.history.push(
								`/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${notice}&deferred=false&openInForm=true`
							)
						}
					>
						Ver inscrição
					</TransparentButton>
					{status !== 'OPEN' && (
						<TransparentButton
							medium
							onClick={() =>
								this.props.history.push(
									`/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${notice}&deferred=false&openInForm=false`
								)
							}
						>
							{' '}
							Ficha de avaliação
						</TransparentButton>
					)}
				</div>
			)
		);
	};
	actionTemplate1 = (rowData) => {
		const { notice } = this.state;
		return (
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<TransparentButton
					medium
					onClick={() =>
						this.props.history.push(
							`/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${notice}&deferred=false&openInForm=true`
						)
					}
				>
					Ver inscrição
				</TransparentButton>
				<TransparentButton
					medium
					onClick={() =>
						this.props.history.push(
							`/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${notice}&deferred=false&openInForm=false`
						)
					}
				>
					Editar ficha de avaliação
				</TransparentButton>
			</div>
		);
	};
	changeTab = (tab) => this.setState({ currentTab: tab });

	render() {
		const {
			name,
			typeSelection,
			registrationPeriodBegins,
			registrationPeriodEnds,
			totalVacancies,
			studentsInterested,
			studentsRegistered,
			currentTab,
			loading,
			studentsRejected,
			noticeName,
		} = this.state;

		return (
			<>
				<Growl ref={(el) => (this.growl = el)} />
				{loading ? (
					<Loader start={loading} />
				) : (
					<SelectionView
						name={name}
						noticeName={noticeName}
						currentTab={currentTab}
						typeSelection={typeSelection}
						typeSelectionList={Object.values(SelectionTypeEnum)}
						registrationPeriodBegins={registrationPeriodBegins}
						registrationPeriodEnds={registrationPeriodEnds}
						totalVacancies={totalVacancies}
						studentsRegistered={studentsRegistered}
						studentsInterested={studentsInterested}
						studentsRejected={studentsRejected}
						actionTemplate={this.actionTemplate}
						actionTemplate1={this.actionTemplate1}
						changeTab={this.changeTab}

					/>
				)}
			</>
		);
	}
}
