import React from 'react';
import { ModalButtonArea, ModalContainer, ModalTitle, ModalMessage } from './Styles';

import { ButtonPrimary } from '../../../../components/buttonDefault/Button';
import Modal from '../../../../components/modal/Modal';

const InterviewView = (props) => {
	const { modalStatus, closeModal, goBack, edit } = props;

	return (
		<Modal isOpen={modalStatus}>
			<ModalContainer>
				<ModalTitle>Entrevista {edit ? 'atualizada' : 'criada'}!</ModalTitle>
				<ModalMessage>Deseja voltar para a tela de seleção?</ModalMessage>
				<ModalButtonArea>
					<ButtonPrimary small onClick={() => goBack()}>
						Sim
					</ButtonPrimary>
					<ButtonPrimary small onClick={() => closeModal()}>
						Não
					</ButtonPrimary>
				</ModalButtonArea>
			</ModalContainer>
		</Modal>
	);
};
export default InterviewView;
