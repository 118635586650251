import React from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, Grid, TextField } from "@material-ui/core";
import { convert } from "../utils/MaskFunctions";

const Input = ({
  value,
  onChange,
  label,
  disabled,
  isPassword = false,
  type = "text",
  changeEye,
  name,
  isNumber,
  isMoney,
  units,
  fullWidth,
  inputProps,
  helperText
}) => {
  const input = (
    <TextField
      name={name}
      value={value}
      autoComplete="new-password"
      type={type}
      disabled={disabled}
      label={label}
      variant="filled"
      helperText={helperText}
      onChange={(e) => {
        if (e.target.value) {
          if (isMoney) {
            e.target.value = convert(
              e.target.value,
              { toMask: true, fixValue: false },
              { unit: "R$" }
            );
          } else if (units) {
            e.target.value = convert(
              e.target.value,
              { toMask: true, fixValue: false },
              units
            );
          } else if (isNumber) {
            e.target.value = convert(e.target.value, {
              toMask: true,
              fixValue: false,
            });
          }
        }
        onChange(e);
      }}
      InputProps={{
        disableUnderline: true,
        ...inputProps,
      }}
      fullWidth={fullWidth}
      InputLabelProps={{
        style: { color: "#fff", fontSize: "14px", fontWeight: "bold" },
      }}
    />
  );

  return isPassword ? (
    <div style={{ marginLeft: "35px"}}>
      <Grid container>
        <Grid item>{input}</Grid>
        <Grid item>
          <IconButton
            aria-label="toggle password visibility"
            onClick={changeEye}
            edge="end"
          >
            {type !== "password" ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Grid>
      </Grid>
    </div>
  ) : (
    input
  );
};
export default Input;
