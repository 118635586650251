import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataTableFamiliarGroup, DataTableContainer, StyledColumn, StyledColumnButtons } from '../Styles';
const Interview = (props) => {
	const { studentsList, actionTemplate, thisView } = props;

	const hasDisabilities = studentsList.some((student) => student.hasDisabilities);

	return (
		<DataTableContainer length={studentsList.length + 1}>
			<DataTableFamiliarGroup>
				<DataTable
					value={studentsList}
					paginator={studentsList.length > 10}
					rows={10}
					emptyMessage={'Não há estudantes'}
				>
					<Column
						filter={true}
						field="_id"
						header="Matrícula"
						filterHeaderStyle={{
							border: 'none',
						}}
						filterPlaceholder="filtro"
						style={{ ...StyledColumn, textAlign: 'center' }}
					/>
					<Column filter={true} field="name" header="Nome" filterPlaceholder="filtro" style={StyledColumn} />

					{hasDisabilities && <Column filter={true} field="hasDisabilities" header="PcD ♿*" filterPlaceholder="filtro" style={StyledColumn} footer="*Declarado no SCA" />}

					<Column
						filter={true}
						field="email"
						header="Email"
						filterPlaceholder="filtro"
						style={StyledColumn}
					/>

					<Column style={{ ...StyledColumnButtons }} body={actionTemplate.bind(thisView)} />
				</DataTable>
			</DataTableFamiliarGroup>
		</DataTableContainer>
	);
};
export default Interview;
