import React from "react";
import {
  CardMenu,
  DataTableFamiliarGroup,
  DataTableContainer,
  StyledColumn,
  DeleteColumn,
  SelectionRow200px,
  Text,
} from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import Dropdown from "../../../../components/dropdown/Dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export default function familyHealthSituationView(props) {
  const {
    familyHealthSituation,
    addMembersChronicIllness,
    familyHealthSituationRowData,
    onChangeFamilyHealthSituationServicesValue,
    onChangeFamilyHealthSituationServicesTypeService,
    onChangeFamilyHealthSituationRowDataTypes,
    familyHealthSituationDropdowns,
    familiarGroupListNameFiltered,
    deleteButtonDeleteMembersChronicIllness,
  } = props;

  return (
    <CardMenu>
      <SelectionRow200px>
        <Dropdown
          placeholder="Que serviços de saúde você utiliza? "
          options={familyHealthSituationDropdowns.typeIncome}
          value={
            familyHealthSituation.services &&
            familyHealthSituation.services.typeService
              ? familyHealthSituation.services.typeService
              : ""
          }
          onChange={onChangeFamilyHealthSituationServicesTypeService}
        />

        <InputFloat
          label="Valor de gastos"
          isMoney
          name="other"
          value={
            familyHealthSituation.services &&
            familyHealthSituation.services.value
              ? familyHealthSituation.services.value
              : ""
          }
          onChange={onChangeFamilyHealthSituationServicesValue}
        />
      </SelectionRow200px>
      <Text>
        Algum membro da família apresenta doença crônica ou deficiência? Se sim,
        especifique abaixo
      </Text>
      <SelectionRow200px>
        <Dropdown
          placeholder="Familiar"
          options={familiarGroupListNameFiltered}
          value={familyHealthSituationRowData.name}
          onChange={onChangeFamilyHealthSituationRowDataTypes}
        />

        <InputFloat
          label="Tipo de Doença / Deficiência "
          name="typeIllness"
          value={familyHealthSituationRowData.typeIllness}
          onChange={onChangeFamilyHealthSituationRowDataTypes}
        />
      </SelectionRow200px>
      <Button
        style={{ margin: "1em auto" }}
        onClick={addMembersChronicIllness}
        label="adicionar"
      ></Button>
      <DataTableContainer
        length={familyHealthSituation.membersChronicIllness.length}
      >
        <DataTableFamiliarGroup>
          <DataTable
            emptyMessage={"Não há"}
            value={familyHealthSituation.membersChronicIllness}
          >
            <Column
              style={{ ...StyledColumn, width: "100px" }}
              field="name"
              header="Nome completo"
            />

            <Column
              style={{ ...StyledColumn, width: "100px" }}
              field="typeIllness"
              header="Tipo de Doença / Deficiência "
            />

            <Column
              style={{ ...DeleteColumn, width: "50px" }}
              body={deleteButtonDeleteMembersChronicIllness.bind(this)}
            />
          </DataTable>
        </DataTableFamiliarGroup>
      </DataTableContainer>

      <br />
    </CardMenu>
  );
}
