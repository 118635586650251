import ApiBase from './ApiBaseUrl';

const Selection = {
	getSelections: (_id) => ApiBase().get(`/selectionAdmin/?_id=${_id}`),
	createSelection: (data) => ApiBase().post('/selectionAdmin/', data),
	delete: (id) => ApiBase().delete(`/selectionAdmin/?_id=${id}`),
	getSelection: (id) => ApiBase().get(`/selectionAdmin/getSelection?_id=${id}`),
	getStudentData: (noticeId, _idStudent) =>
		ApiBase().get(`/selectionAdmin/getStudentData?_idNotice=${noticeId}&_idStudent=${_idStudent}`),
	makeInterview: (data) => ApiBase().post(`/selectionAdmin/makeEnterview`, data),
	getInterview: (_idNotice, _idStudent, _idApplicationForm) =>
		ApiBase().get(
			`/selectionAdmin/getEnterview?_idNotice=${_idNotice}&_idStudent=${_idStudent}&_idApplicationForm=${_idApplicationForm}`
		),
	updateInterview: (data) => ApiBase().put(`/selectionAdmin/updateEnterview`, data),
	createNotice: (name) => ApiBase().post(`/noticeAdmin`, name),
	getNotices: () => ApiBase().get(`/noticeAdmin?_id=all`),
	getNotice: (_id) => ApiBase().get(`/noticeAdmin?_id=?_id=${_id}`),
	studentDistance: (_idStudent, _idNotice, _distanceType) =>
		ApiBase().get(`/selectionAdmin/studentDistance?_idStudent=${_idStudent}&_idNotice=${_idNotice}` + (_distanceType ? `&_distanceType=${_distanceType}` : '')),
	updateStudentAplForm: (data) => ApiBase().put(`/selectionAdmin/updateStudentApplicationForm`, data),


};

export default Selection;
