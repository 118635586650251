import styled from 'styled-components';

export const UltraContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px 10px;
	margin: 15px 0px;
	width: 100%;
	justify-content: center;
	${(props) => props.paddingTopBottom && `padding:0px 16px; `}
	${(props) => props.paddingBottom && `padding:16px 16px 0px; `}
`;

export const CardMenu = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 98%;
	justify-content: center;
	text-align: center;
	background: #fff;
	flex-wrap: wrap;
  margin:10px;
	@media(max-width:900px) {
		margin: 15px auto;
	}
`;

export const TitleSelectionRow = styled.div`
	width: 100%;
	position: relative;
	background: #2e82c8;
	height: 2px;
	margin-bottom: 15px;
	p {
		position: absolute;
		font-size: 20px;
		top: -11px;
		background: #ffff;
		padding: 0px 5px 0px 0px;
		left: 0px;
		color: #2e82c8;
		@media (max-width: 500px) {
			font-size: 16px;
		}
	}
	${(props) => props.middleTitle && `margin-top: 25px;`}
`;
export const StyledColumn = {
  border: 'none',
  maxWidth: '100px',
  borderBottom: '1px solid gray',
  background: '#ffffff',
  textAlign: "center"
};


export const ButtonsSpace = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px;
	padding-bottom: 0px;
	width: 100%;
`;


export const ButtonSpace = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px;
`;

export const DataTableContainer = styled.div`
	display: flex;
	width: ${(props) => (props.width ? `calc(100vw - 100px) ` : `calc(100vw - 80px)`)};
	overflow: auto;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 2px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.5);
	}
	@media (max-width: 750px) {
		width: calc(100vw - 45px);
	}
`;
export const DataTableFamiliarGroup = styled.div`
	display: flex;
	width: 100%;
	min-width: 1200px;
	overflow: hidden;
`;




