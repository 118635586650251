import React from "react";
import { Link } from 'react-router-dom';
import InputFloat from "../../components/InputFloat";
import { ButtonLogin } from "../../components/buttonDefault/Button";
import BlueLogo from "../../assets/icons/login/blueLogo.png";
import UfcgBlueLogo from "../../assets/icons/login/blueIcons/ufcgBlueLogo.png";
import PracBlueLogo from "../../assets/icons/login/blueIcons/pracBlueLogo.png";
import DesidesBlueLogo from "../../assets/icons/login/blueIcons/desidesBlueLogo.png";
import {
  CardContainer,
  Title,
  Container,
  BlueLogosContainer,
  BlueLogosSpace,
} from "./Styles";

const LoginView = (props) => {
  const { registration, password, closedEye, changeEye } = props;

  return (

    <Container>
      <img
        src={BlueLogo}
        style={{ width: "180px", height: "80px" }}
        alt=""
      />
      <CardContainer>
        <Title>Digite seu login</Title>
        <InputFloat
          label="CPF"
          onChange={props.onChangeRegistration}
          value={registration}
        />

        <InputFloat
          label="Senha"
          isPassword={true}
          type={closedEye ? "password" : ""}
          changeEye={changeEye}
          onChange={props.onChangePassword}
          value={password}
        />
        <ButtonLogin medium onClick={props.handleSubmit}>
          Vamos lá
        </ButtonLogin>
        <Link to="/faq" style={{ fontSize: '14px', marginBottom: '10px' }}>Perguntas frequentes</Link>
      </CardContainer>
      <BlueLogosContainer>
        <p>Parceiros:</p>
        <BlueLogosSpace>
          <img src={PracBlueLogo} alt="" />

          <img src={DesidesBlueLogo} alt="" />

          <img src={UfcgBlueLogo} alt="" />
        </BlueLogosSpace>
      </BlueLogosContainer>
    </Container>

  );
};
export default LoginView;
