import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DataTableContainer, StyledColumn, VDColumn } from './Styles';

const Enterview = (props) => {
	const { lists, actionTemplate, thisView } = props;
	return lists.map((list) => (
		<DataTableContainer length={list.value.length} key={list.id}>
			<DataTable value={list.value}>
				<Column columnKey={list.id} body={actionTemplate.bind(thisView)} style={VDColumn} />
				<Column field="value" header="VD" style={VDColumn} />
				<Column field="option" header={list.title} style={StyledColumn} />
			</DataTable>
		</DataTableContainer>
	));
};
export default Enterview;
