import React from 'react';

import { ModalButtonArea, ModalContainer, ModalTitle } from './styles/Styles';
import Modal from '../../../components/modal/Modal';
import InputFloat from '../../../components/InputFloat';
import { ButtonPrimary } from '../../../components/buttonDefault/Button';

const ModalView = (props) => {
	const { onChangeNoticeData, modalStatus, onChangeModalStatus, createNotice, noticeModalData } = props;
	const { name, registrationPeriodBegins, registrationPeriodEnds, parcialDeadline, finalDeadline } = noticeModalData;

	return (
		<Modal isOpen={modalStatus}>
			<ModalContainer>
				<ModalTitle>Criar Edital</ModalTitle>
				<InputFloat label="Nome do edital" value={name} onChange={(e) => onChangeNoticeData(e, 'name')} />
				<InputFloat
					label="Prazo parcial"
					value={parcialDeadline}
					onChange={(e) => onChangeNoticeData(e, 'parcialDeadline')}
				/>
				<InputFloat
					label="Prazo final"
					value={finalDeadline}
					onChange={(e) => onChangeNoticeData(e, 'finalDeadline')}
				/>
				<ModalTitle>Período de inscrição</ModalTitle>
				<InputFloat
					label="Inicio"
					value={registrationPeriodBegins}
					onChange={(e) => onChangeNoticeData(e, 'registrationPeriodBegins')}
				/>
				<InputFloat
					label="Fim"
					value={registrationPeriodEnds}
					onChange={(e) => onChangeNoticeData(e, 'registrationPeriodEnds')}
				/>

				<ModalButtonArea>
					<ButtonPrimary small onClick={createNotice}>
						Criar
					</ButtonPrimary>
					<ButtonPrimary small onClick={onChangeModalStatus}>
						Sair
					</ButtonPrimary>
				</ModalButtonArea>
			</ModalContainer>
		</Modal>
	);
};
export default ModalView;
