import React from "react";
import {
  CardMenu,
  ButtonSpace,
  DataTableFamiliarGroup,
  DataTableContainer,
  SelectionRow200px,
} from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { StyledColumn } from "../../Styles";
import Dropdown from "../../../../components/dropdown/Dropdown";

export default function familiarGroup(props) {
  let {
    rowData,
    onChangeRowData,
    familiarGroup,
    addFamilar,
    kinshipDropdown,
    onChangeKinship,
    maritalStatusDropdown,
    onChangeMaritalStatus,
    onChangeSchooling,
    schoolingDropdown,
  } = props;

  return (
    <CardMenu>
      <>
        <SelectionRow200px>
          <InputFloat
            disabled={rowData.kinship.value === "-"}
            label="Nome (apenas o primeiro)"
            name="name"
            value={rowData.name.value}
            invalid={rowData.name.isEmpty}
            onChange={onChangeRowData}
          />
          <InputFloat
            disabled={rowData.kinship.value === "-"}
            label="Idade"
            name="age"
            type="number"
            value={rowData.age.value}
            invalid={rowData.age.isEmpty}
            onChange={onChangeRowData}
          />
          <InputFloat
            disabled={rowData.kinship.value === "-"}
            label="CPF"
            name="cpf"
            id="cpf"
            value={rowData.cpf.value}
            invalid={rowData.cpf.isEmpty}
            onChange={onChangeRowData}
          />
          <Dropdown
            disabled={rowData.kinship.value === "-"}
            placeholder="Parentesco"
            value={rowData.kinship.value}
            options={kinshipDropdown}
            onChange={onChangeKinship}
          />
        </SelectionRow200px>
        <SelectionRow200px>
          <Dropdown
            disabled={rowData.kinship.value === "-"}
            placeholder="Estado civil"
            value={rowData.maritalStatus.value}
            options={maritalStatusDropdown}
            onChange={onChangeMaritalStatus}
          />
          <Dropdown
            disabled={rowData.kinship.value === "-"}
            placeholder="Escolaridade"
            value={rowData.schooling.value}
            options={schoolingDropdown}
            onChange={onChangeSchooling}
          />
          <InputFloat
            disabled={rowData.kinship.value === "-"}
            label="Ocupação"
            name="profession"
            value={rowData.profession.value}
            invalid={rowData.profession.isEmpty}
            onChange={onChangeRowData}
          />
          <InputFloat
            label="Renda Mensal Bruta"
            isMoney
            name="grossMonthlyIncome"
            value={rowData.grossMonthlyIncome.value}
            invalid={rowData.grossMonthlyIncome.isEmpty}
            onChange={onChangeRowData}
          />
        </SelectionRow200px>
        <ButtonSpace>
          <Button onClick={addFamilar} label="adicionar"></Button>
        </ButtonSpace>
      </>

      <DataTableContainer length={familiarGroup.length}>
        <DataTableFamiliarGroup>
          <DataTable
            value={familiarGroup}
            emptyMessage="Sem familiares para mostrar"
          >
            <Column field="name" header="Nome" style={StyledColumn} />
            <Column field="cpf" header="CPF" style={StyledColumn} />
            <Column field="age" header="Idade" style={StyledColumn} />
            <Column header="Parentesco" field="kinship" style={StyledColumn} />
            <Column
              header="Estado civil"
              field="maritalStatus"
              style={StyledColumn}
            />
            <Column
              header="Escolaridade"
              field="schooling"
              style={StyledColumn}
            />
            <Column header="Ocupação" field="profession" style={StyledColumn} />
            <Column
              header="Renda mensal bruta"
              field="grossMonthlyIncome"
              style={StyledColumn}
            />
            <Column style={StyledColumn} body={props.deleteButton.bind(this)} />
          </DataTable>
        </DataTableFamiliarGroup>
      </DataTableContainer>
    </CardMenu>
  );
}
