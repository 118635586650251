import React, { Component } from "react";
import UserNavbar from "../../view/navbar/UserNavbar";
import AdminNavbar from "../../view/navbar/AdminNavbar";
import { getAuthenticate, isAdmin } from "../../services/LocalAuth";

class UserNavbarController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isAuthenticate: false,
      showMobileNavbar: false,
      clearNavbar: false,
    };
  }

  componentDidMount() {
    this.setState({ isAdmin: isAdmin(), isAuthenticate: getAuthenticate() });
  }

  onChangeMobileNavbar = () => {
    this.setState({ showMobileNavbar: !this.state.showMobileNavbar });
  };

  goTo = (path) => {
    this.props.history.push(path);
  };
  onChangeClearNavbar = (bool) => this.setState({ clearNavbar: bool });

  render() {
    const {
      isAdmin,
      isAuthenticate,
      showMobileNavbar,
      clearNavbar,
    } = this.state;

    return (
      <>
        {isAuthenticate && !isAdmin && (
          <UserNavbar
            goTo={this.goTo}
            showMobileNavbar={showMobileNavbar}
            onChangeMobileNavbar={this.onChangeMobileNavbar}
            onChangeClearNavbar={this.onChangeClearNavbar}
            clearNavbar={clearNavbar}
          />
        )}
        {isAuthenticate && isAdmin && (
          <AdminNavbar
            goTo={this.goTo}
            showMobileNavbar={showMobileNavbar}
            onChangeMobileNavbar={this.onChangeMobileNavbar}
          />
        )}
      </>
    );
  }
}

export default UserNavbarController;
