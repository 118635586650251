import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	StyledColumn,
	DataTableFamiliarGroup,
	DataTableContainer,
	UltraContainer,
	CardMenu,
	TitleSelectionRow,
	ButtonContainer
} from './Styles';
import { ButtonPrimary } from '../../components/buttonDefault/Button'
import NoticeModal from './NoticeModal/NoticeModal';

const HomePageView = (props) => {
	const { notices, noticeModalData, isSuperADM,
		onChangeNoticeData,
		modalStatus,
		onChangeModalStatus,
		createNotice,
		isPatternNotice, formatRowDate, buttonContainer } = props;

	const currentNotices = notices
		.filter((notice) => notice.patternNotice)
		.sort(
			(a, b) =>
				new Date(b.registrationPeriodBegins) -
				new Date(a.registrationPeriodBegins)
		);
	const pastNotices = notices
		.filter((notice) => !notice.patternNotice)
		.sort(
			(a, b) =>
				new Date(b.registrationPeriodBegins) -
				new Date(a.registrationPeriodBegins)
		);

	return (
		<UltraContainer>
			<NoticeModal
				modalStatus={modalStatus}
				noticeModalData={noticeModalData}
				onChangeNoticeData={onChangeNoticeData}
				createNotice={createNotice}
				onChangeModalStatus={onChangeModalStatus}
			/>
			<TitleSelectionRow middleTitle>
				<p>Editais Atuais</p>
			</TitleSelectionRow>
			<CardMenu>
				{isSuperADM &&
					<ButtonContainer>
						<ButtonPrimary fixedWidth width={150} onClick={onChangeModalStatus}>Criar edital</ButtonPrimary>
					</ButtonContainer>
				}
				<DataTableContainer length={currentNotices.length}>
					<DataTableFamiliarGroup>
						<DataTable value={currentNotices} emptyMessage="Não há editais">
							<Column field="_id" header={'Código'} style={StyledColumn} />
							<Column field="name" header={'Nome'} style={StyledColumn} />
							<Column
								field="patternNotice"
								header={'Atual'}
								style={StyledColumn}
								body={isPatternNotice.bind(this)}
							/>
							<Column
								field="registrationPeriodBegins"
								header={'Período de inscrição inicio'}
								style={StyledColumn}
								body={formatRowDate.bind(this)}
							/>
							<Column
								field="registrationPeriodEnds"
								header={'Período de inscrição fim'}
								style={StyledColumn}
								body={formatRowDate.bind(this)}
							/>
							<Column style={StyledColumn} body={buttonContainer.bind(this)} />
						</DataTable>
					</DataTableFamiliarGroup>
				</DataTableContainer>
			</CardMenu>

			<TitleSelectionRow middleTitle>
				<p>Editais Passados</p>
			</TitleSelectionRow>
			<CardMenu>

				<DataTableContainer length={pastNotices.length}>
					<DataTableFamiliarGroup>
						<DataTable value={pastNotices} emptyMessage="Não há editais">
							<Column field="_id" header={'Código'} style={StyledColumn} />
							<Column field="name" header={'Nome'} style={StyledColumn} />
							<Column
								field="patternNotice"
								header={'Atual'}
								style={StyledColumn}
								body={isPatternNotice.bind(this)}
							/>
							<Column
								field="registrationPeriodBegins"
								header={'Período de inscrição inicio'}
								style={StyledColumn}
								body={formatRowDate.bind(this)}
							/>
							<Column
								field="registrationPeriodEnds"
								header={'Período de inscrição fim'}
								style={StyledColumn}
								body={formatRowDate.bind(this)}
							/>
							<Column style={StyledColumn} body={buttonContainer.bind(this)} />
						</DataTable>
					</DataTableFamiliarGroup>
				</DataTableContainer>
			</CardMenu>
		</UltraContainer>
	);
};

export default HomePageView;
