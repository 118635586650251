import React from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TransparentButton from "../../../../components/transparentButton/TransparentButton";
import {
  DataTableFamiliarGroup,
  DataTableContainer,
  StyledColumn,
  TableSpaceButton,
  ButtonStyledColumn,
} from "../../Styles";

const View = ({ selections, goTo }) => {
  const rankTemplate = ({ _id, notice, final, partial, typeSelection }) => (
    <div style={TableSpaceButton}>
      {partial &&
        <TransparentButton
          onClick={() =>
            goTo(`/usuario/selecoes/ver/resultados?s=${_id}&n=${notice}&t=partial&s_n=${typeSelection}`)
          }
        >
          Resultado parcial
        </TransparentButton>
      }
      {final &&
        <TransparentButton
          onClick={() =>
            goTo(`/usuario/selecoes/ver/resultados?s=${_id}&n=${notice}&t=final&s_n=${typeSelection}`)
          }
        >
          Resultado final
        </TransparentButton>
      }
    </div>
  );

  return (
    <div style={{ padding: '1em' }}>
      <DataTableContainer length={selections.length}>
        <DataTableFamiliarGroup>
          <DataTable
            paginator={selections.length > 5}
            rows={5}
            value={selections}
            emptyMessage="Não há seleções abertas"
          >
            <Column
              field="_id"
              header="Código"
              style={StyledColumn}
            />
            <Column
              field="typeSelection"
              header="Tipo de seleção"
              style={StyledColumn}
            />
            <Column
              body={rankTemplate.bind(this)}
              style={ButtonStyledColumn}
            />
          </DataTable>
        </DataTableFamiliarGroup>
      </DataTableContainer>
    </div >
  );
}

export default View;