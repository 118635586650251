import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  StyledColumn,
  DataTableFamiliarGroup,
  DataTableContainer,
  TitleSelectionRow,
  UltraContainer,
  CardMenu,
} from "./styles/Styles";
const HomePageView = (props) => {
  const {
    participating,
    notParticipating,
    actionTemplate,
    getOutStudent,
  } = props;

  const getOutSpace = (rowData) => (
    <Button
      onClick={() => getOutStudent(rowData)}
      className="buttonSelection"
      label="Apagar inscrição"
    ></Button>
  );

  return (
    <UltraContainer>
      <TitleSelectionRow>
        <p>Seleções</p>
      </TitleSelectionRow>

      <CardMenu>
        <TitleSelectionRow>
          <p> Não inscrito</p>
        </TitleSelectionRow>
        <DataTableContainer length={notParticipating.length}>
          <DataTableFamiliarGroup>
            <DataTable
              id=""
              selectionMode="single"
              value={notParticipating}
              emptyMessage="Não há inscrições"
            >
              <Column
                field="typeSelection"
                header="Tipo de seleção"
                style={StyledColumn}
              />
              <Column
                header="Abre em"
                field="registrationPeriodBegins"
                style={{ ...StyledColumn, textAlign: "center" }}
              />
              <Column
                header="Fecha em"
                field="registrationPeriodEnds"
                style={{ ...StyledColumn, textAlign: "center" }}
              />
              <Column
                body={actionTemplate.bind(this)}
                style={{ ...StyledColumn, textAlign: "center" }}
              />
            </DataTable>
          </DataTableFamiliarGroup>
        </DataTableContainer>
        <TitleSelectionRow middleTitle>
          <p>Inscrito</p>
        </TitleSelectionRow>
        <DataTableContainer length={participating.length}>
          <DataTableFamiliarGroup>
            <DataTable
              id=""
              selectionMode="single"
              value={participating}
              emptyMessage="Não há inscrições"
            >
              <Column
                field="typeSelection"
                header="Tipo de seleção"
                style={StyledColumn}
              />
              <Column
                header="Abre em"
                field="registrationPeriodBegins"
                style={{ ...StyledColumn, textAlign: "center" }}
              />
              <Column
                header="Fecha em"
                field="registrationPeriodEnds"
                style={{ ...StyledColumn, textAlign: "center" }}
              />
              <Column
                style={{ ...StyledColumn, textAlign: "center" }}
                body={getOutSpace.bind(this)}
              />
            </DataTable>
          </DataTableFamiliarGroup>
        </DataTableContainer>

        {/*participating.length > 0 && (
          <ButtonSpace>
            <Button
              onClick={downloadPdf}
              className="buttonSelection"
              label="Gerar PDF"
            ></Button>
          </ButtonSpace>
        )*/}
      </CardMenu>
    </UltraContainer>
  );
};
export default HomePageView;
