import React from "react";
import { DataTable } from "primereact/datatable";
import StudentsTable from "../StudentsTable";
import { Column } from "primereact/column";
import {
  DataTableFamiliarGroup,
  DataTableContainer,
  StyledColumn,
  TableSpaceButton,
  AdminSelectionsButtonContainer,
  AdminSelectionsInputContainer,
  ButtonStyledColumn,
  TitleSelectionRow,
  CardMenu,
} from "../../Styles";
import { ModalButtonArea, ModalContainer, ModalTitle } from "./styles/Styles";
import Modal from "../../../../components/modal/Modal";
import { ButtonPrimary } from "../../../../components/buttonDefault/Button";
import { UltraContainer } from "./styles/Styles";
import TransparentButton from "../../../../components/transparentButton/TransparentButton";
import InputFloat from "../../../../components/InputFloat";
import Dropdown from "../../../../components/dropdown/Dropdown";
const HomePageView = ({
  data,
  goTo,
  registrationPeriodBegins,
  registrationPeriodEnds,
  parcialDeadline,
  finalDeadline,
  notice,
  studentsList,
  _idNotice,
  modalStatus,
  closeModal,
  onChangeTypeSelection,
  modalTypeSelection,
  typeSelectionList,
  createSelection,
  onChangeNoticeData,
  noticeModalData,
  updateNotice,
  onChangeEditModalStatus,
  editModalStatus,
  isSuperAdm,
  deleteSelection,
  openModal
}) => {
  const actionTemplate = (rowData) => {
    return (
      <div style={TableSpaceButton}>
        <TransparentButton
          onClick={() =>
            goTo(`/usuario/admin/selecoes/ver?id=${rowData._id}`)
          }
        >
          Ver
        </TransparentButton>
        {rowData.status === "OPEN" && (
          <TransparentButton id={rowData._id} onClick={deleteSelection}>
            Deletar
          </TransparentButton>
        )}
      </div>
    );
  };
  const rankTemplate = (rowData) => {
    return (
      <div style={TableSpaceButton}>
        {rowData.partial &&
          <TransparentButton
            onClick={() =>
              goTo(`/usuario/selecoes/ver/rankeamento?s=${rowData._id}&n=${_idNotice}&t=partial&s_n=${rowData.typeSelection}`)
            }
          >
            Resultado parcial
          </TransparentButton>
        }
        {rowData.final &&
          <TransparentButton
            onClick={() =>
              goTo(`/usuario/selecoes/ver/rankeamento?s=${rowData._id}&n=${_idNotice}&t=final&s_n=${rowData.typeSelection}`)
            }
          >
            Resultado final
          </TransparentButton>
        }
      </div>
    );
  };
  const studentActionTemplate = (rowData) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TransparentButton
          onClick={() =>
            goTo(
              `/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${_idNotice}&deferred=false&openInForm=true`
            )
          }
        >
          Ver inscrição
        </TransparentButton>
        <TransparentButton
          onClick={() =>
            goTo(
              `/usuario/admin/selecoes/ver/estudanteEntrevista?applicationForm_id=${rowData.applicationForm}&student_id=${rowData._id}&_idNotice=${_idNotice}&deferred=false&openInForm=false`
            )
          }
        >
          {rowData.enterview !== ""
            ? "Editar ficha de avaliação"
            : "Ficha de avaliação"}
        </TransparentButton>
      </div>
    );
  };

  return (
    <UltraContainer>
      <Modal isOpen={modalStatus}>
        <ModalContainer>
          <ModalTitle>Criar seleção</ModalTitle>
          <InputFloat label="Edital" disabled={true} value={notice} />
          <Dropdown
            placeholder="Tipo de seleção"
            name={"typeSelection"}
            options={typeSelectionList}
            value={modalTypeSelection}
            onChange={onChangeTypeSelection}
          />
          <ModalButtonArea>
            <ButtonPrimary small onClick={createSelection}>
              Criar
            </ButtonPrimary>
            <ButtonPrimary small onClick={closeModal}>
              Cancelar
            </ButtonPrimary>
          </ModalButtonArea>
        </ModalContainer>
      </Modal>
      <Modal isOpen={editModalStatus}>
        <ModalContainer>
          <ModalTitle>Atualizar Edital</ModalTitle>

          <ModalTitle>Período de inscrição</ModalTitle>
          <InputFloat
            label="Inicio"
            value={noticeModalData.registrationPeriodBegins}
            onChange={(e) => onChangeNoticeData(e, "registrationPeriodBegins")}
          />
          <InputFloat
            label="Fim"
            value={noticeModalData.registrationPeriodEnds}
            onChange={(e) => onChangeNoticeData(e, "registrationPeriodEnds")}
          />
          <InputFloat
            label="Prazo parcial"
            value={noticeModalData.parcialDeadline}
            onChange={(e) => onChangeNoticeData(e, "parcialDeadline")}
          />
          <InputFloat
            label="Prazo final"
            value={noticeModalData.finalDeadline}
            onChange={(e) => onChangeNoticeData(e, "finalDeadline")}
          />

          <ModalButtonArea>
            <ButtonPrimary small onClick={updateNotice}>
              Atualizar
            </ButtonPrimary>
            <ButtonPrimary small onClick={onChangeEditModalStatus}>
              Sair
            </ButtonPrimary>
          </ModalButtonArea>
        </ModalContainer>
      </Modal>
      <div>
        <TitleSelectionRow>
          <p>Seleções</p>
        </TitleSelectionRow>
        <CardMenu>
          <AdminSelectionsButtonContainer>
            <ButtonPrimary
              fixedWidth={150}
              onClick={openModal}
              disabled={typeSelectionList.length === 0}
            >
              Criar Seleção
            </ButtonPrimary>
            {isSuperAdm && (
              <ButtonPrimary fixedWidth={150} onClick={onChangeEditModalStatus}>
                Editar Edital
              </ButtonPrimary>
            )}
          </AdminSelectionsButtonContainer>
          <AdminSelectionsInputContainer>
            <InputFloat label="Nome do edital" value={notice} disabled={true} />
            <InputFloat
              label="Período de inscrição inicio"
              value={registrationPeriodBegins}
              disabled={true}
            />
            <InputFloat
              label="Período de inscrição fim"
              value={registrationPeriodEnds}
              disabled={true}
            />
            <InputFloat
              label="Prazo parcial"
              value={parcialDeadline}
              disabled={true}
            />
            <InputFloat
              label="Prazo final"
              value={finalDeadline}
              disabled={true}
            />
          </AdminSelectionsInputContainer>

          <DataTableContainer length={data.length}>
            <DataTableFamiliarGroup>
              <DataTable
                paginator={data.length > 5}
                rows={5}
                value={data}
                emptyMessage="Não há seleções abertas"
              >
                <Column field="_id" header="Código" style={StyledColumn} />
                <Column
                  field="typeSelection"
                  header="Tipo de seleção"
                  style={StyledColumn}
                />
                <Column
                  body={actionTemplate.bind(this)}
                  style={ButtonStyledColumn}
                />
                <Column
                  body={rankTemplate.bind(this)}
                  style={ButtonStyledColumn}
                />
              </DataTable>
            </DataTableFamiliarGroup>
          </DataTableContainer>
        </CardMenu>
      </div>
      <div>
        <TitleSelectionRow>
          <p>Estudantes no Campus</p>
        </TitleSelectionRow>
        <StudentsTable
          studentsList={studentsList}
          actionTemplate={studentActionTemplate}
          thisView={this}
        />
      </div>
    </UltraContainer>
  );
};
export default HomePageView;
