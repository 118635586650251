import React from "react";
import InputFloat from "../../../../components/InputFloat";
import { CardMenu, SelectionRow200px } from "../../Styles";

export default function bankData({ bankData, onChangeBankData }) {

  return (
    <CardMenu>
      <SelectionRow200px>
        <InputFloat label="BANCO" value={bankData.bank} disabled={true} />
        <InputFloat label="AGÊNCIA" value={bankData.agency} disabled={true} />
        <InputFloat label="CONTA" value={bankData.account} disabled={true} />
        <InputFloat
          label="OPERAÇÃO"
          name="operation"
          value={bankData.operation ? bankData.operation : ''}
          onChange={onChangeBankData}
        />
      </SelectionRow200px>

      <p>
        {" "}
        OBS: Os dados bancários somente serão necessários para os Auxílios
        Financeiros. Serão aceitas contas de qualquer instituição bancária,
        entretanto não serão aceitas contas do tipo salário, conjuntas ou
        poupança do Banco do Brasil.
      </p>
    </CardMenu>
  );
}
