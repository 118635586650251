import styled from "styled-components";

export const ButtonAreaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
`;
export const UltraContainer = styled.div`
  display: grid;
  padding: 16px;
  ${(props) => props.paddingTopBottom && `padding:0px 16px; `}
  ${(props) => props.paddingBottom && `padding:16px 16px 0px; `}
`;
export const UltraContainerItem = styled.div`
  width: 100%;
`;
export const SelectionRow200px = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 10px;
  justify-items:center;
`;
export const CardMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  width: 100%;
  padding: 10px;
  justify-content: center;
  text-align: center;
  background: #fff;
  flex-wrap: wrap;
  ${(props) => props.middleCard && `border-radius: 0px;`}
  @media(max-width:900px) {
    margin: 15px auto;
  }
`;

export const TitleSelectionRow = styled.div`
  width: 100%;
  position: relative;
  background: #2e82c8;
  height: 2px;
  margin-bottom: 15px;
  p {
    position: absolute;
    font-size: 20px;
    top: -11px;
    background: #ffff;
    padding: 0px 5px 0px 0px;
    left: 0px;
    color: #2e82c8;
  }
  ${(props) => props.middleTitle && `margin-top: 25px;`}
`;
