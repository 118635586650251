import React from 'react';
import { ButtonsSpace, ModalButtonArea, ModalContainer, ModalTitle, ModalMessage } from '../Styles';
import TransportCard from './formViews/Transport';
import ParentsAddress from './formViews/ParentsAddress';
import StudentAdress from './formViews/StudentAdress';
import Identification from './formViews/Identification';
import TicInformation from './formViews/TicInformation';
import FamilyIncomeInfo from './formViews/FamilyIncomeInfo';
import GroupFamiliar from './formViews/FamiliarGroup';
import StudentHousing from './formViews/Housing';
import ParentsHousing from './formViews/ParentsHousing';
import OtherIncomeReceived from './formViews/OtherIncomeReceived';
import FamilyAssets from './formViews/FamilyAssets';
import FamilyHealthSituation from './formViews/FamilyHealthSituation';
import Bank from './formViews/BankData';
import { ButtonPrimary } from '../../../components/buttonDefault/Button';
import Modal from '../../../components/modal/Modal';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import {
	identificationDropdowns, housingDropdowns,
	typeLocomotionDropdown, housingParentsDropdowns,
	kinshipDropdown, maritalStatusDropdown,
	schoolingDropdown, otherIncomeReceivedDropdowns,
	familyHealthSituationDropdowns, booleanDropDown,
	ticsDropdowns, familyIncomeInfoDropdowns
} from '../../../utils/AplicationFormDropdowns';

const HomePageView = (props) => {
	const {
		onChangeTypeAlimony,
		identification,
		onChangePracUser,
		onChangeElementarySchoolType,
		onChangeEthnicRacialBelongingOptions,
		onChangeSexualOrientationOptions,
		onChangeGenderIdentityOptions,
		onChangeRg,
		onChangeSocialName,
		onChangeSomeoneFamilyGraduate,
		address,
		onChangeAddress,
		onchangeOtherGraduateCourse,
		parentsAddress,
		onChangeParentAddress,
		housing,
		onChangeSituationDividends,
		onChangeWhoWithReside,
		onChangeSituationTypeHousing,
		onChangeWhoWithResideOther,
		onChangeSituationTypeHousingOther,
		onChangeSituationHowManyPersons,
		onChangeSituationOther,
		onChangeSituationValue,
		housingParents,
		onChangeHousingParents,
		isTrueTxt,
		onchangeParentsHousingConditions,
		onchangeParentsHousingTypeHousingOther,
		onchangeParentsHousingValue,
		familiarGroup,
		addFamilar,
		onChangeRowData,
		rowData,
		deleteButton,
		onChangeTypeLocomotion,
		onChangeMonthlyExpenseLocomotion,
		monthlyExpenseLocomotion,
		otherIncomeReceived,
		onChangeOtherIncomeReceivedAlimony,
		onChangeOtherIncomeReceivedTypeIncome,
		onChangeOtherIncomeReceivedTypes,
		OnchangeFromWhoInput,
		fromWhoInput,
		addOtherIncomeReceivedFromWho,
		deleteButtonOtherIncomeReceived,
		familyAssets,
		familyAssetsRowData,
		onChangeFamilyAssetsRowData,
		deleteButtonFamilyAssets,
		addFamilyAssets,
		familyHealthSituation,
		addMembersChronicIllness,
		onChangeFamilyHealthSituationServicesValue,
		onChangeFamilyHealthSituationServicesTypeService,
		onChangeFamilyHealthSituationRowDataTypes,
		familyHealthSituationRowData,
		bankData,
		onChangeBankData,
		parentsCepError,
		StudentCepError,
		onKeyPressed,
		edit,
		page,
		nextPage,
		handleSubmit,
		parentHaveSameCep,
		onChangeParentHaveSameCep,
		onChangeKinship,
		onChangeMaritalStatus,
		onChangeSchooling,
		modalStatus,
		sucessMessage,
		goTo,
		onChangeTypePrac,
		firstTimeStatus,
		closeFirstTimeStatusModal,
		onChangeOtherGraduateOption,
		familiarGroupListNameFiltered,
		onChangeScholarship,
		onChangeDisabilities,
		onChangeHighSchoolType,
		onChangeDisabilitiesOptions,
		onChangeFinancialIndependence,
		onClickToChangeTab,
		deleteButtonDeleteMembersChronicIllness,
		onChangeEadMode,
		admMode,
		onchangeModalStatus,
		ticInformation,
		ticCallbacks,
		familyIncomeInfo,
		familyIncomeInfoCallbacks
	} = props;
	function getSteps() {
		return [
			{
				label: 'Identificação do estudante',
				value: <Identification
					onChangeEadMode={onChangeEadMode}
					onChangeFinancialIndependence={onChangeFinancialIndependence}
					identification={identification}
					isTrueTxt={props.isTrueTxt}
					onChangePracUser={onChangePracUser}
					onChangeElementarySchoolType={onChangeElementarySchoolType}
					onChangeEthnicRacialBelongingOptions={onChangeEthnicRacialBelongingOptions}
					onChangeSexualOrientationOptions={onChangeSexualOrientationOptions}
					onChangeGenderIdentityOptions={onChangeGenderIdentityOptions}
					onChangeOtherGraduateOption={onChangeOtherGraduateOption}
					onChangeRg={onChangeRg}
					onChangeSocialName={onChangeSocialName}
					onChangeSomeoneFamilyGraduate={onChangeSomeoneFamilyGraduate}
					onchangeOtherGraduateCourse={onchangeOtherGraduateCourse}
					identificationDropdowns={identificationDropdowns}
					booleanDropDown={booleanDropDown}
					onChangeTypePrac={onChangeTypePrac}
					onChangeScholarship={onChangeScholarship}
					onChangeDisabilities={onChangeDisabilities}
					onChangeHighSchoolType={onChangeHighSchoolType}
					onChangeDisabilitiesOptions={onChangeDisabilitiesOptions}
					admMode={admMode}
				/>
			},
			{
				label: 'Uso de Tecnologias da Informação (TICS)',
				value: <TicInformation
					ticInformation={ticInformation}
					ticsDropdowns={ticsDropdowns}
					booleanDropDown={booleanDropDown}
					isTrueTxt={isTrueTxt}
					ticCallbacks={ticCallbacks}
				/>
			},
			{
				label: 'Endereço do estudante',
				value: <StudentAdress
					address={address}
					cepError={StudentCepError}
					onChangeAddress={onChangeAddress}
				/>
			},
			{
				label: 'Endereço dos seus pais ou responsáveis',
				value: <ParentsAddress
					financialIndependence={identification.financialIndependence}
					parentsAddress={parentsAddress}
					onChangeParentAddress={onChangeParentAddress}
					cepError={parentsCepError}
					parentHaveSameCep={parentHaveSameCep}
					onChangeParentHaveSameCep={onChangeParentHaveSameCep}
					booleanDropDown={booleanDropDown}
					isTrueTxt={isTrueTxt}
				/>
			},
			{
				label: 'Situação habitacional do estudante',
				value: < StudentHousing
					housingDropdowns={housingDropdowns(identification.financialIndependence, parentHaveSameCep)}
					housing={housing}
					onChangeSituationDividends={onChangeSituationDividends}
					booleanDropDown={booleanDropDown}
					onChangeWhoWithReside={onChangeWhoWithReside}
					onChangeSituationTypeHousing={onChangeSituationTypeHousing}
					onChangeWhoWithResideOther={onChangeWhoWithResideOther}
					onChangeSituationTypeHousingOther={onChangeSituationTypeHousingOther}
					onChangeSituationHowManyPersons={onChangeSituationHowManyPersons}
					onChangeSituationOther={onChangeSituationOther}
					onChangeSituationValue={onChangeSituationValue}
					isTrueTxt={isTrueTxt}
				/>
			},
			{
				label: 'Situação habitacional de seus pais ou responsáveis',
				value: <ParentsHousing
					housingParentsDropdowns={housingParentsDropdowns}
					housingParents={housingParents}
					onChangeHousingParents={onChangeHousingParents}
					onchangeParentsHousingConditions={onchangeParentsHousingConditions}
					onchangeParentsHousingTypeHousingOther={onchangeParentsHousingTypeHousingOther}
					onchangeParentsHousingValue={onchangeParentsHousingValue}
				/>
			},
			{
				label: 'Condições de Deslocamento Diário para a Universidade',
				value: <TransportCard
					typeLocomotionDropdown={typeLocomotionDropdown}
					monthlyExpenseLocomotion={monthlyExpenseLocomotion}
					onChangeTypeLocomotion={onChangeTypeLocomotion}
					onChangeMonthlyExpenseLocomotion={onChangeMonthlyExpenseLocomotion}
				/>
			},
			{
				label: 'Composição do grupo familiar (incluindo o estudante)',
				value: <GroupFamiliar
					haveFamily={!identification.financialIndependence}
					rowData={rowData}
					onChangeRowData={onChangeRowData}
					familiarGroup={familiarGroup}
					addFamilar={addFamilar}
					deleteButton={deleteButton}
					kinshipDropdown={kinshipDropdown}
					onKeyPressed={onKeyPressed}
					onChangeKinship={onChangeKinship}
					maritalStatusDropdown={maritalStatusDropdown}
					onChangeMaritalStatus={onChangeMaritalStatus}
					onChangeSchooling={onChangeSchooling}
					schoolingDropdown={schoolingDropdown}
				/>
			},
			{
				label: 'Especificações da Renda Familiar',
				value: <FamilyIncomeInfo
				familyIncomeInfo={familyIncomeInfo}
				familyIncomeInfoDropdowns={familyIncomeInfoDropdowns}
				familyIncomeInfoCallbacks={familyIncomeInfoCallbacks}
				/>
			},
			{
				label: 'Outro rendimentos recebidos pelos membros do grupo familiar',
				value: <OtherIncomeReceived
					onChangeTypeAlimony={onChangeTypeAlimony}
					onChangeOtherIncomeReceivedTypes={onChangeOtherIncomeReceivedTypes}
					otherIncomeReceivedDropdowns={otherIncomeReceivedDropdowns}
					otherIncomeReceived={otherIncomeReceived}
					onChangeOtherIncomeReceivedAlimony={onChangeOtherIncomeReceivedAlimony}
					onChangeOtherIncomeReceivedTypeIncome={onChangeOtherIncomeReceivedTypeIncome}
					isTrueTxt={isTrueTxt}
					familyData={familiarGroup.map((row) => row.name)}
					booleanDropDown={booleanDropDown}
					OnchangeFromWhoInput={OnchangeFromWhoInput}
					fromWhoInput={fromWhoInput}
					addOtherIncomeReceivedFromWho={addOtherIncomeReceivedFromWho}
					deleteButtonOtherIncomeReceived={deleteButtonOtherIncomeReceived}
				/>
			},
			{
				label: 'Bens patrimoniais do grupo familiar',
				value: <FamilyAssets
					familyAssetsRowData={familyAssetsRowData}
					onChangeFamilyAssetsRowData={onChangeFamilyAssetsRowData}
					familyAssets={familyAssets}
					deleteButtonFamilyAssets={deleteButtonFamilyAssets}
					addFamilyAssets={addFamilyAssets}
				/>
			},
			{
				label: 'Situação de saúde que envolve o grupo familiar',
				value: <FamilyHealthSituation
					familyHealthSituation={familyHealthSituation}
					familyHealthSituationDropdowns={familyHealthSituationDropdowns}
					addMembersChronicIllness={addMembersChronicIllness}
					deleteButtonDeleteMembersChronicIllness={deleteButtonDeleteMembersChronicIllness}
					onChangeFamilyHealthSituationServicesValue={onChangeFamilyHealthSituationServicesValue}
					onChangeFamilyHealthSituationServicesTypeService={
						onChangeFamilyHealthSituationServicesTypeService
					}
					onChangeFamilyHealthSituationRowDataTypes={onChangeFamilyHealthSituationRowDataTypes}
					familyHealthSituationRowData={familyHealthSituationRowData}
					familiarGroupListNameFiltered={familiarGroupListNameFiltered}
				/>
			},
			{
				label: 'Dados bancários do estudante',
				value: <Bank bankData={bankData} onChangeBankData={onChangeBankData} />
			},
		];
	}

	const steps = getSteps();


	return (
		<>
			<Modal isOpen={modalStatus}>
				<ModalContainer height='200px'>
					{!admMode && <ModalTitle>Parabéns!</ModalTitle>}
					<ModalMessage>{sucessMessage}</ModalMessage>
					<ModalButtonArea justifyCenter>
						<ButtonPrimary small onClick={() => admMode ? onchangeModalStatus() : goTo('/usuario/estudante/selecoes')}>
							Ok
						</ButtonPrimary>
					</ModalButtonArea>
				</ModalContainer>
			</Modal>
			<Modal isOpen={!admMode && firstTimeStatus}>
				<ModalContainer height='auto'>
					<ModalTitle>Bem vindo!</ModalTitle>
					<ModalMessage>Preencha o formulário para poder usar o sistema</ModalMessage>
					<ModalButtonArea justifyCenter>
						<ButtonPrimary small onClick={() => closeFirstTimeStatusModal()}>
							Ok
						</ButtonPrimary>
					</ModalButtonArea>
				</ModalContainer>
			</Modal>
			<div style={{ width: '100%', scrollBehavior: 'smooth', }}>
				<Stepper activeStep={page} orientation="vertical">
					{
						steps.map(({ label, value }, index) => (
							<Step
								key={index}
								id={index}
								onClick={admMode ? () => onClickToChangeTab(index) : () => { }}
							>
								<StepLabel>{label}</StepLabel>
								<StepContent>
									{value}
									{!admMode &&
										<ButtonsSpace>
											{page > 0 ? (
												<ButtonPrimary small onClick={() => onClickToChangeTab(index - 1)}>
													Anterior
												</ButtonPrimary>
											) : (
												<br />
											)}

											{page < 12 ? (

												<ButtonPrimary small onClick={nextPage}>
													Proximo
												</ButtonPrimary>
											) : (
												<ButtonPrimary medium onClick={nextPage}>
													{edit ? 'Atualizar Formulário' : 'Concluir Formulário'}
												</ButtonPrimary>
											)}
										</ButtonsSpace>
									}
								</StepContent>
							</Step>
						))}
					{admMode &&
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<ButtonPrimary medium onClick={handleSubmit}>
								Atualizar Formulário
							</ButtonPrimary>
						</div>
					}
				</Stepper>
			</div>
		</>
	);
};
export default HomePageView;
