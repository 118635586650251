import React from "react";

import { Container } from "./styles/style";
import Lottie from "react-lottie";
import LoaderAnimation from "../../assets/animations/loading.json";
export default function Loader(props) {
  return (
    <Container loading={props.start.toString()}>
      <Lottie
        onCick={() => {}}
        options={{
          loop: true,
          autoplay: true,
          animationData: LoaderAnimation,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height="200px"
        width="200px"
        isClickToPauseDisabled={true}
      />
    </Container>
  );
}
