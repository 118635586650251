import ApiBase from "./ApiBaseUrl";

const routes = {
  getRank: (_idNotice, _idSelection, type) =>
    ApiBase().get(
      `selection/rank?_idNotice=${_idNotice}&_idSelection=${_idSelection}&type=${type}`
    ),
  getSelections: (_idNotice) => {
    const request = _idNotice ? `/selection/availableRank?_id=${_idNotice}` : `/selection/availableRank`
    return ApiBase().get(request)
  }
};

export default routes;
