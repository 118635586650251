import React from "react";
import {
  NavbarContainer,
  Container,
  ButtonArea,
  NavbarMobile,
  NavbarListMobile,
} from "./Style";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { AdminRoutes } from "../../Router";
import { ButtonPrimary } from "../../components/buttonDefault/Button";
import { signout } from "../../services/LocalAuth";
import { Link } from "react-router-dom";

const AdminNavbarHomeView = (props) => {
  let { goTo, showMobileNavbar, onChangeMobileNavbar } = props;

  return (
    <Container>
      <NavbarContainer>
        <ButtonArea>
          <ButtonPrimary small background={"#ffff"} color={"#2E82C8"} onClick={() => goTo("/usuario/admin/editais")}>
            Editais
          </ButtonPrimary>

          <ButtonPrimary
            small
            background={"#ffff"} color={"#2E82C8"}
            onClick={() => {
              signout();
              goTo("/");
            }}
          >
            Sair
          </ButtonPrimary>
        </ButtonArea>
      </NavbarContainer>
      <NavbarMobile show={showMobileNavbar}>
        <CloseIcon fill="#ffffff" onClick={onChangeMobileNavbar} />
        <NavbarListMobile>
          <Link to="/usuario/admin/selecoes" onClick={onChangeMobileNavbar}>
            Seleções
          </Link>

          <Link
            to="/"
            onClick={(e) => {
              signout();
              onChangeMobileNavbar(e);
            }}
          >
            Sair
          </Link>
        </NavbarListMobile>
      </NavbarMobile>
      <AdminRoutes />
    </Container>
  );
};

export default AdminNavbarHomeView;
