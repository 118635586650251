import styled from 'styled-components';

export const Card = styled.div`
	border: 0.0625rem solid rgba(0, 0, 0, 0.125);
`;
export const UltraContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px 10px;
	margin: 15px 0px;
	width: 100%;
	justify-content: center;
	${(props) => props.paddingTopBottom && `padding:0px 16px; `}
	${(props) => props.paddingBottom && `padding:16px 16px 0px; `}
`;

export const Text = styled.p`
	color: #2e82c8;
	font-weight: 800;
`;

export const CardMenu = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	text-align: center;
	background: #fff;
	flex-wrap: wrap;
	padding:10px;
	${(props) => props.middleCard && `border-radius: 0px;`}
	@media(max-width:900px) {
		margin: 15px auto;
	}
`;
export const Test = styled.div`
	:hover {
		background: black;
	}
`;

export const TitleSelectionRow = styled.div`
	width: 100%;
	position: relative;
	background: #2e82c8;
	height: 2px;
	margin-bottom: 15px;
	p {
		position: absolute;
		font-size: 20px;
		top: -11px;
		background: #ffff;
		padding: 0px 5px 0px 0px;
		left: 0px;
		color: #2e82c8;
		@media (max-width: 500px) {
			font-size: 16px;
		}
	}
	${(props) => props.middleTitle && `margin-top: 25px;`}
`;
export const StyledColumn = {
	border: 'none',
	maxWidth: '100px',
	borderBottom: '1px solid gray',
	background: '#ffffff',
	textAlign: "center"
};
export const StyledColumnButtons = {
	border: 'none',
	width: '400px',
	borderBottom: '1px solid gray',
	background: '#ffffff',
	textAlign: "center"
};
export const DateStyledColumn = {
	border: 'none',
	borderBottom: '1px solid gray',
	width: '120px',
	background: '#ffffff',
};
export const ButtonStyledColumn = {
	border: 'none',
	borderBottom: '1px solid gray',
	width: '250px',
	background: '#ffffff',
};
export const DeleteColumn = {
	...StyledColumn,
	width: '100px',
};
export const ButtonsSpace = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px;
	padding-bottom: 0px;
	width: 100%;
`;
export const TableSpaceButton = {
	display: 'grid',
	border: 'none',
	background: '#ffffff',
	width: '100%',
	height: '42px',
	gridTemplateColumns: 'repeat(auto-fill,minmax(100px,1fr))',
};

export const FilterColumnStyle = styled.div`
	border: none;
	border-bottom: 1px solid black;
`;

export const ButtonSpace = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px;
`;
export const StudentBoxForm = styled.div`
	display: flex;
	align-items: center;
	width: 50%;
	margin: 10px;
`;
export const ParentsAdressTopBoxForm = styled.div`
	display: grid;
	gap:10px;
	grid-column-gap: 100px;
	width: 100%;
	max-width: 460px;
	justify-items: center;
	.cgNWdS {
		max-width: 500px;
		@media (max-width: 475px) {
			label {
				width: 316px;
				font-size: 12px;
				top: -11px;
				left: 6px;
			}
		}
	}
	.hZyTvK {
		max-width: 500px;
	}
`;
export const StudentBoxFormRow = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 10px;
	margin-top: 1em;
`;
export const SelectionViewContainer = styled.div`
	display: grid;
	margin: 0px 30px 0px;
	grid-template-rows: 100px 150px 100px;
`;

export const CreateSelectionContainer = styled.div`
	margin-top: 2em;
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const CreateSelectionRow = styled.div`
	display: grid;
	width: 100%;
	max-width: 800px;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	margin-top: 1em;
	gap: 10px;
	padding: 1em;
`;

export const AdminSelectionsContainer = styled.div`
	display: grid;
	width: 100%;
	grid-template-rows: 100px 4em;
	margin: 0px 10px 0px;
`;
export const AdminSelectionsButtonContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: row-reverse;
	width: 98%;
	padding: 10px 0px;
`;
export const AdminSelectionsInputContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 10px;
	width: 98%;
	justify-items: center;
`;
export const DataTableContainer = styled.div`
	display: flex;
	width: ${(props) => (props.width ? `calc(100vw - 100px) ` : `calc(100vw - 80px)`)};
	overflow: auto;
	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 2px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.5);
	}
	@media (max-width: 750px) {
		width: calc(100vw - 45px);
	}
`;
export const DataTableFamiliarGroup = styled.div`
	display: flex;
	width: 100%;
	min-width: 1200px;
	overflow: hidden;
`;

export const SelectionRow200px = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 10px;
	margin-top: 1em;
`;
export const SelectionRow300px = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	margin-top: 1em;
	gap: 10px;
	@media (max-width: 450px) {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 15px;
	}
`;
export const SelectionRow400px = styled.div`
	display: grid;
	width: 100%;
	margin-top: 1em;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	@media (max-width: 450px) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		gap: 15px;
	}
	@media (max-width: 350px) {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 15px;
	}
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #ffff;
	height: ${(props) => props.height ? props.height : `500px`};
	width: 100%;
	max-width:350px;
	padding: 15px;
	border-radius: 5px;
`;

export const ModalTitle = styled.p`
	font-size: 18px;
	margin: 10px 0;
	color: #2e82c8;
`;

export const ModalMessage = styled.p`
	font-size: 14px;
	font-weight: normal;
`;

export const ModalButtonArea = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	${(props) => props.justifyCenter && `  justify-content: center;`}
	margin-top: 30px;
`;
export const checkBoxColumn = {
	border: 'none',
	width: '30px',
	justifyContent: 'center',
	borderBottom: '1px solid gray',
	background: '#ffffff',
};
export const ProfileFormTabHeader = styled.div`
	display: flex;
	height: 40px;
	background: transparent;
	justify-content: center;
	border-bottom: none;
	margin-top: 10px;
`;

export const ProfileContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 15px;
	flex-direction: column;
`;

export const TabItem = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: 14px;
	border-bottom: 3px solid transparent;
	transition: all 0.3s;
	cursor: pointer;
	${(props) => props.selected && `background: #76c3f7;color: #ffff;`}
	:hover {
		background: #5c9fcc;
	}
`;
export const IconSpace = styled.div`
	justify-content: center;
	align-items: center;
	max-width: 100px;
	svg {
		height: 50px;
		width: 50px;
	}
`;
export const ReactQuillContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	textarea:focus, input:focus{ outline: none; }
`;

export const QuillBox = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 1em;
	border: 2px solid #2e82c8;
	p {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-items: center;
		align-items: flex-start;
		padding: 15px;
	}
`;
