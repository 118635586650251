import styled from 'styled-components';
export const Tab = styled.div`
	position: absolute;
	display: flex;
	opacity: 0;
	top: 0px;
	left: 90px;
	align-items: flex-start;
	justify-content: center;
	z-index: 2;
	gap: 10px;
	${(props) => !props.firstRender && `animation:  ${props.open ? `open` : `close`} 0.7s forwards;`}
	@media (max-width: 460px) {
		flex-wrap: wrap;
		${(props) => props.open && `opacity:1;max-width:325px;`}
	}
	button {
		margin-top: 10px;
	}
	@keyframes open {
		0% {
			max-width: 0px;
			opacity: 0;
		}
		25% {
			opacity: 0.1;
		}
		100% {
			max-width: 400px;
			opacity: 1;
		}
	}
	@keyframes close {
		100% {
			max-width: 0px;
			opacity: 0;
		}
		25% {
			opacity: 0.5;
		}
		0% {
			max-width: 400px;
			opacity: 1;
		}
	}
`;
