const SelectionTypeEnum = Object.freeze({
  PAEG: 'PAEG',
  AUXILIO_ATIVIDADES_EXTERNAS: "AUXILIO ATIVIDADES EXTERNAS",
  RESTAURANTE_UNIVERSITARIO: 'RESTAURANTE UNIVERSITARIO',
  RESIDENCIA_UNIVERSITARIA: 'RESIDENCIA UNIVERSITARIA',
  AUXILIO_MORADIA: 'AUXILIO MORADIA',
  AETICS_EQUIPAMENTO: 'AETICS-EQUIPAMENTO',
  AETICS_DADOS: 'AETICS-DADOS',
  AUXILIO_CRECHE: 'AUXILIO CRECHE',
  AUXILIO_INCLUSAO_DIGITAL: 'AUXILIO INCLUSAO DIGITAL',
  AUXILIO_TRANSPORTE: 'AUXILIO TRANSPORTE',

  values() {
    return Object.values(this);
  }
});

export default SelectionTypeEnum;