import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const Table = (data, propsData, emptyMessage) => {
  return (
    <DataTable
      value={data}
      paginator={data.length > 10}
      rows={10}
      emptyMessage={emptyMessage}
    >
      {propsData.map((props) => (
        <Column
          key={Math.random()}
          {...props}
          filterHeaderStyle={{
            border: "none",
          }}
        />
      ))}
    </DataTable>
  );
};

export default Table;
