import ApiBase from "./ApiBaseUrl";

const Selection = {
  getSelections: () => ApiBase().get("/selection/"),
  submitToSelection: (data) =>
    ApiBase().post("/selection/pushStudent", { _id: data }),
  getOutSelection: (id) => ApiBase().delete(`/selection/pushStudent?_id=${id}`),
  getAplicationForm: (selectionId) =>
    ApiBase().get(`/selection/getPdfApplicationForm?_id=${selectionId}`, {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf",
      },
    }),
};

export default Selection;
