import styled from "styled-components";

export const ProfileFormTabHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background: transparent;
  justify-content: flex-start;
  position: relative;
  ::after {
    content: "";
    display: flex;
    width: 100px;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: #76c3f7;
    transition: all 0.4s;
  }
  ${(props) =>
    props.currentTab &&
    `
  ::after {
    left: ${100 * props.currentTab}px;
  }
    `}
`;

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: column;
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-size: 14px;
  border-bottom: 3px solid transparent;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #6dbdf3;
  }
`;

export const ButtonAreaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
`;
export const ButtonAreaContainerTop = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
`;
export const topButtonMiniContainer = styled.div`
  display: flex;
  ${(props) =>
    props.start &&
    `
    align-items: flex-start;
  `}
  ${(props) =>
    props.end &&
    `
    align-items: flex-end;
  `}
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid black;
  margin-top: 1em;
  flex-direction: column;
`;
export const SelectionRow300px = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
`;
export const TitleSelectionRow = styled.div`
  width: 100%;
  position: relative;
  background: #2e82c8;
  height: 2px;
  margin-bottom: 15px;
  p {
    position: absolute;
    font-size: 20px;
    top: -11px;
    background: #ffff;
    padding: 0px 5px 0px 0px;
    left: 0px;
    color: #2e82c8;
  }
  ${(props) => props.middleTitle && `margin-top: 25px;`}
`;
export const CardMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
  justify-content: center;
  text-align: center;
  background: #fff;
  flex-wrap: wrap;
  ${(props) => props.middleCard && `border-radius: 0px;`}
  @media(max-width:900px) {
    margin: 15px auto;
  }
`;
export const UltraContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 10px;
  margin: 15px 0px;
  width: 100%;
  justify-content: center;
  ${(props) => props.paddingTopBottom && `padding:0px 16px; `}
  ${(props) => props.paddingBottom && `padding:16px 16px 0px; `}
`;
