import React from "react";
import {
  CardMenu,
  DataTableFamiliarGroup,
  DataTableContainer,
  StyledColumn,
  DeleteColumn,
  SelectionRow200px,
} from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import Dropdown from "../../../../components/dropdown/Dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export default function OtherIncomeReceivedView(props) {
  const {
    otherIncomeReceived,
    otherIncomeReceivedDropdowns,
    booleanDropDown,
    onChangeOtherIncomeReceivedAlimony,
    onChangeOtherIncomeReceivedTypeIncome,
    isTrueTxt,
    onChangeOtherIncomeReceivedTypes,
    OnchangeFromWhoInput,
    fromWhoInput,
    addOtherIncomeReceivedFromWho,
    deleteButtonOtherIncomeReceived,
    familyData,
    onChangeTypeAlimony,
  } = props;
  const fromWho =
    otherIncomeReceived.alimony.fromWho &&
    otherIncomeReceived.alimony.fromWho.map((value) => ({
      name: value,
    }));

  return (
    <CardMenu>
      <SelectionRow200px>
        <Dropdown
          placeholder="Tipo do Rendimento"
          options={otherIncomeReceivedDropdowns.typeIncome}
          value={otherIncomeReceived.typeIncome.option}
          onChange={onChangeOtherIncomeReceivedTypeIncome}
        />
        {otherIncomeReceived.typeIncome.option !== "" ? (
          <>
            {otherIncomeReceived.typeIncome.option !== "Não recebo" ? (
              <>
                {otherIncomeReceived.typeIncome.option === "Outro" && (
                  <InputFloat
                    label="Especifique"
                    name="other"
                    value={otherIncomeReceived.typeIncome.other}
                    onChange={onChangeOtherIncomeReceivedTypes}
                  />
                )}
                <InputFloat
                  isMoney
                  label="Valor"
                  name="value"
                  value={otherIncomeReceived.value}
                  onChange={onChangeOtherIncomeReceivedTypes}
                />
              </>
            ) : (
              <br />
            )}
          </>
        ) : (
          <br />
        )}
      </SelectionRow200px>

      <SelectionRow200px>
        <Dropdown
          placeholder="Alguém recebe Pensão Alimentícia"
          options={booleanDropDown}
          value={isTrueTxt(otherIncomeReceived.alimony.option)}
          onChange={onChangeOtherIncomeReceivedAlimony}
        />
        {otherIncomeReceived.alimony.option && (
          <InputFloat
            label="Informe o tipo de rendimento"
            value={otherIncomeReceived.alimony.typeAlimony}
            onChange={onChangeTypeAlimony}
          />
        )}
        {otherIncomeReceived.alimony.option &&
          otherIncomeReceived.alimony.typeAlimony &&
          otherIncomeReceived.alimony.typeAlimony.trim() !== "" && (
            <Dropdown
              placeholder="Informe o membro"
              options={familyData}
              value={fromWhoInput}
              onChange={OnchangeFromWhoInput}
            />
          )}
      </SelectionRow200px>
      {otherIncomeReceived.alimony.option &&
        otherIncomeReceived.alimony.typeAlimony.trim() !== "" && (
          <>
            <Button
              onClick={addOtherIncomeReceivedFromWho}
              label="adicionar"
            ></Button>
            <>
              <DataTableContainer length={fromWho.length}>
                <DataTableFamiliarGroup minWidth={500}>
                  <DataTable id="" value={fromWho}>
                    <Column
                      style={{ ...StyledColumn, width: "100px" }}
                      field="name"
                      header="Nome"
                    />
                    <Column
                      style={{ ...DeleteColumn, width: "50px" }}
                      body={deleteButtonOtherIncomeReceived.bind(this)}
                    />
                  </DataTable>
                </DataTableFamiliarGroup>
              </DataTableContainer>
            </>
          </>
        )}
      <br />
    </CardMenu>
  );
}
