import React from "react";
import InputFloat from "../../components/InputFloat";
import "./styles.css";
import {
  SelectionRow300px,
  TitleSelectionRow,
  CardMenu,
  UltraContainer,
} from "./Style";
import { zipCodeFormatMask } from "../../utils/MaskFunctions";
import { numberRepresentation } from "../../utils/NumericalConversion";

const StudentPageView = (props) => {
  const {
    name,
    register,
    fatherName,
    motherName,
    course,
    campus,
    round,
    entryYear,
    cra,
    highSchoolType,
    cellphoneNumber,
    color,
    disapprovalsNumber,
    maritalStatus,
    sex,
    disabilities,
    originCity,
    originCountry,
    cep,
    entryForm,
    neighborhood,
    street,
    scholarship,
    familySize,
    perCapitaIncome,
    grossIncome,
  } = props;

  return (
    <UltraContainer>
      <TitleSelectionRow>
        <p>Identificação</p>
      </TitleSelectionRow>
      <CardMenu middleCard>
        <SelectionRow300px>
          <InputFloat label="Nome completo" disabled={true} value={name} />
          <InputFloat label="Matrícula" disabled={true} value={register} />
          <InputFloat label="Curso" disabled={true} value={course} />
          <InputFloat
            label="CRA"
            disabled={true}
            value={numberRepresentation(cra)}
          />
          <InputFloat label="Campus" disabled={true} value={campus} />
          <InputFloat label="Turno" disabled={true} value={round} />
          <InputFloat
            label="Ano de entrada"
            disabled={true}
            value={String(entryYear)}
          />
          <InputFloat label="Nome do pai" disabled={true} value={fatherName} />
          <InputFloat label="Nome da mãe" disabled={true} value={motherName} />
          <InputFloat
            label="Ensino médio"
            disabled={true}
            value={highSchoolType}
          />
          <InputFloat
            label="Telefone"
            disabled={true}
            value={cellphoneNumber}
          />
          <InputFloat label="Cor" disabled={true} value={color} />
          <InputFloat
            label="Reprovações"
            disabled={true}
            value={String(disapprovalsNumber)}
          />
          <InputFloat
            label="Estado civil"
            disabled={true}
            value={maritalStatus}
          />
          <InputFloat label="Sexo" disabled={true} value={sex} />
          <InputFloat
            label="Forma de ingresso"
            disabled={true}
            value={entryForm}
          />
          <InputFloat
            label="Deficiências"
            disabled={true}
            value={disabilities === "" ? "Nenhuma" : disabilities}
          />
        </SelectionRow300px>
      </CardMenu>
      <TitleSelectionRow middleTitle>
        <p>Endereço</p>
      </TitleSelectionRow>
      <CardMenu middleCard>
        <SelectionRow300px>
          <InputFloat
            label="Cidade de origem"
            disabled={true}
            value={originCity}
          />
          <InputFloat label="Rua" disabled={true} value={street} />
          <InputFloat label="Bairro" disabled={true} value={neighborhood} />
          <InputFloat
            label="CEP"
            disabled={true}
            value={zipCodeFormatMask(cep)}
          />
          <InputFloat
            label="País de origem"
            disabled={true}
            value={originCountry}
          />
        </SelectionRow300px>
      </CardMenu>
      <TitleSelectionRow middleTitle>
        <p>Renda</p>
      </TitleSelectionRow>
      <CardMenu>
        <SelectionRow300px>
          <InputFloat
            label="Integrantes da família"
            disabled={true}
            value={String(familySize)}
          />
          <InputFloat
            label="Renda per capita"
            disabled={true}
            value={numberRepresentation(perCapitaIncome)}
          />
          <InputFloat
            label="Renda bruta familiar"
            disabled={true}
            value={numberRepresentation(grossIncome)}
          />
          {scholarship.value !== undefined && (
            <>
              <InputFloat
                label="Tipo da bolsa acadêmica"
                disabled={true}
                value={scholarship.typeScholarship}
              />
              <InputFloat
                isMoney
                label="Valor da bolsa acadêmica"
                disabled={true}
                value={numberRepresentation(scholarship.value)}
              />
            </>
          )}
        </SelectionRow300px>
      </CardMenu>
    </UltraContainer>
  );
};
export default StudentPageView;
