import React from "react";
import {
  NavbarContainer,
  Container,
  ButtonArea,
  NavbarMobile,
  NavbarListMobile,
  NavbarList,
} from "./Style";
import { Link } from "react-router-dom";
import { UserRoutes } from "../../Router";
import { ButtonPrimary } from "../../components/buttonDefault/Button";
import { signout } from "../../services/LocalAuth";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/navbar/menu.svg";

const UserNavbarHomeView = (props) => {
  let {
    goTo,
    showMobileNavbar,
    onChangeMobileNavbar,
    onChangeClearNavbar,
    clearNavbar,
  } = props;

  return (
    <Container>
      <NavbarContainer>
        <MenuIcon fill="#ffffff" onClick={onChangeMobileNavbar} />
        {!clearNavbar && (
          <NavbarList>
            <Link to="/usuario/estudante/formulario">Formulário</Link>
            <Link to="/usuario/selecoes/edital">Resultados</Link>
            <Link to="/usuario/estudante/selecoes">Seleções</Link>
            <Link to="/usuario/estudante/perfil">Perfil</Link>
            <Link to="/usuario/faq">Perguntas frequentes</Link>
          </NavbarList>
        )}
        <ButtonArea className="ButtonArea">
          <ButtonPrimary
            small
            background={"#ffff"}
            color={"#2E82C8"}
            onClick={() => {
              signout();
              goTo("/");
            }}
          >
            Sair
          </ButtonPrimary>
        </ButtonArea>
      </NavbarContainer>
      <NavbarMobile show={showMobileNavbar}>
        <CloseIcon fill="#ffffff" onClick={onChangeMobileNavbar} />
        <NavbarListMobile>
          <br/>
          <Link to="/usuario/estudante/formulario">Formulário</Link>
          <Link to="/usuario/selecoes/edital">Resultados</Link>
          <Link to="/usuario/estudante/selecoes" onClick={onChangeMobileNavbar}>
          Seleções
          </Link>
          <Link to="/usuario/faq">Perguntas frequentes</Link>
          <Link
            to="/"
            onClick={(e) => {
              signout();
              onChangeMobileNavbar(e);
            }}
          >
          Sair
          </Link>
        </NavbarListMobile>
      </NavbarMobile>
      <UserRoutes onChangeClearNavbar={onChangeClearNavbar} />
    </Container >
  );
};

export default UserNavbarHomeView;
