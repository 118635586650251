import React from "react";
import { CardMenu, SelectionRow200px } from "../../Styles";
import Dropdown from "../../../../components/dropdown/Dropdown";
import InputFloat from "../../../../components/InputFloat";

export default function transportCard(props) {
  const {
    typeLocomotionDropdown,
    monthlyExpenseLocomotion: { typeLocomotion, value, other },
    onChangeTypeLocomotion,
    onChangeMonthlyExpenseLocomotion
  } = props;

  return (
    <CardMenu>
      <SelectionRow200px>
        <Dropdown
          placeholder="Tipo do transporte"
          options={typeLocomotionDropdown}
          value={String(typeLocomotion)}
          onChange={onChangeTypeLocomotion}
        />
        {typeLocomotion === "Outro" ? (
          <InputFloat
            label="Especifique"
            value={other}
            onChange={onChangeMonthlyExpenseLocomotion}
          />
        ) : typeLocomotion === "Transporte coletivo" || typeLocomotion === "Transporte locado (Van, ônibus)" ? (
          <InputFloat
           isMoney
            label="Valor"
            value={value ? String(value) : ''}
            onChange={onChangeMonthlyExpenseLocomotion}
          />
        ) :
          <br />
        }
      </SelectionRow200px>
    </CardMenu>
  );
}
