import React, { useState } from 'react';
import { faq } from './questions.json';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        scrollBehavior: 'smooth'

    }
}));
const Controller = ({ dontShowNavBar }) => {
    const [page, setPage] = useState(0);
    return (
        <div style={{ width: '100%' }}>
            <div id='Faq' style={{ padding: '1em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p style={{ fontWeight: 500, fontSize: '2em' }}>Perguntas frequentes</p>
                <div className={useStyles().root}>
                    <Stepper activeStep={page} orientation="vertical">
                        {faq.map(({ question, answer }, index) => (
                            <Step
                                key={index}
                                id={index}
                                onClick={() => { setPage(index) }}
                            >
                                <StepLabel style={{cursor:'pointer'}}>{question}</StepLabel>
                                <StepContent>
                                    <div style={{ margin: '10px 0px 0px 10px' }}>  {answer} </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
        </div>
    )
}

export default Controller;