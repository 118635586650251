import React, { Component } from "react";
import LoginView from "../../view/login/Login";
import apiBase from "../../services/UserApi";
import { authenticate } from "../../services/LocalAuth";
import { Growl } from "primereact/growl";
import Loader from "../../components/loader/Loder";
import { cpfFormatMask } from '../../utils/MaskFunctions';
export default class RegisterController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registration: "",
      password: "",
      response: "",
      loading: false,
      closedEye: true,
    };
  }

  componentDidMount() {
    document.title = `Login | JUS2`;
  }

  onChangeRegistration = (e) => {
    this.setState({ registration: cpfFormatMask(e.target.value) });
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleSubmit = () => {
    const { registration, password } = this.state;

    this.setState({ loading: true }, () =>
      apiBase.login({ _id: registration.replace('.', '').replace('.', '').replace('-', ''), password: password }).then(
        (response) => {
          const {
            token,
            isAdmin,
            name,
            applicationForm,
          } = response.data.message;

          this.growl.show({ severity: "success", summary: "top " + name });

          authenticate(token, isAdmin, name);
          if (isAdmin) this.props.history.push("/usuario/admin/editais/");
          else if (!applicationForm)
            this.props.history.push("/usuario/estudante/formulario");
          else this.props.history.push("/usuario/estudante/selecoes");
        },
        (err) => {
          const { message, success } = err.response.data;

          this.setState({ success: success, loading: false }, () =>
            this.growl.show({ life: 10000, severity: "error", summary: typeof message === 'string' ? message : 'Atualize o seu cadastro no controle acadêmico'})
          );
        }
      )
    );
  };
  goTo = (path) => this.props.history.push(path);
  changeEye = () => this.setState({ closedEye: !this.state.closedEye });
  render() {
    let { registration, password, loading, closedEye } = this.state;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />

        <Loader start={loading} />

        <LoginView
          registration={registration}
          password={password}
          onChangePassword={this.onChangePassword}
          onChangeRegistration={this.onChangeRegistration}
          handleSubmit={this.handleSubmit}
          goTo={this.goTo}
          closedEye={closedEye}
          changeEye={this.changeEye}
        />
      </>
    );
  }
}
