import React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { Container } from './Style';

export default function DropdownPrimary({ placeholder, options, value, onChange, disabled }) {
	value = value === '' ? 'Selecione' : value;

	return (
		<Container disabled={disabled}>
			<InputLabel
				style={{
					textAlign: 'left',
					fontSize: '14px',
					marginLeft: '10px',
					marginTop: '10px',
					color: 'white',
					fontWeight: 'bold'
				}
				}
				disabled={disabled}
			>

				{placeholder}
			</InputLabel>
			<Select
				autoWidth
				value={value}
				onChange={(e) => {
					if (e.target.value !== 'Selecione')
						onChange({ label: e.target.value, value: e.target.value })
				}}
				disabled={disabled}
				disableUnderline
			>
				{value === 'Selecione' &&
					<MenuItem value='Selecione'>
						<em>Selecione</em>
					</MenuItem>
				}
				{value === '-' &&
					<MenuItem value='-'>
						<em>-</em>
					</MenuItem>
				}
				{options.map((option) => <MenuItem key={Math.random()} value={option}>{option}</MenuItem>)}
			</Select>
		</Container>
	);
}
