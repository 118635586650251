import React from "react";
import { CardMenu, SelectionRow200px, ReactQuillContainer } from "../../Styles";
import Dropdown from "../../../../components/dropdown/Dropdown";
import InputFloat from "../../../../components/InputFloat";
import { TextareaAutosize } from "@material-ui/core";

export default function HousingCard(props) {
  const {
    housingParentsDropdowns,
    housingParents,
    onChangeHousingParents,
    onchangeParentsHousingConditions,
    onchangeParentsHousingTypeHousingOther,
    onchangeParentsHousingValue,
  } = props;
  const typeHousingOption = housingParents.typeHousing.option;

  return (
    <CardMenu>
      <SelectionRow200px>
        <Dropdown
          placeholder="Qual o tipo de situação? "
          options={housingParentsDropdowns.typeHousing}
          value={typeHousingOption}
          onChange={onChangeHousingParents}
        />

        {typeHousingOption !== "" &&
        typeHousingOption !== "Própria" &&
        typeHousingOption !== "Cedido" ? (
          <>
            {typeHousingOption === "Outro" && (
              <InputFloat
                label="Especifique"
                value={housingParents.typeHousing.other}
                onChange={onchangeParentsHousingTypeHousingOther}
              />
            )}
            {typeHousingOption === "Financiado" && (
              <InputFloat
                isMoney
                label="Valor da parcela R$"
                value={housingParents.value}
                onChange={onchangeParentsHousingValue}
              />
            )}
            {typeHousingOption === "Alugada" && (
              <InputFloat
                isMoney
                label="Valor mensal de R$"
                value={housingParents.value}
                onChange={onchangeParentsHousingValue}
              />
            )}
          </>
        ) : (
          <br />
        )}
      </SelectionRow200px>
      <p style={{ color: "#2E82C8", fontWeight: 800 }}>
        Indique as condições de moradia
      </p>
      <p style={{ color: "#2E82C8", fontWeight: 800, marginBottom: "5px" }}>
        (Condições de infraestrutura; Localização geográfica – área de risco de
        enchente, deslizamento;Condições de saneamento básico, etc.)
      </p>
      <ReactQuillContainer>
        <TextareaAutosize
          style={{
            width: "100%",
            border: " 2px solid rgb(46, 130, 200)",
            padding: "3px",
            borderRadius: "10px",
            minHeight: "50px",
          }}
          placeholder="Especifique"
          onChange={onchangeParentsHousingConditions}
          value={housingParents.conditions}
        />
      </ReactQuillContainer>
    </CardMenu>
  );
}
