import React from "react";
import { CardMenu, SelectionRow300px, TitleSelectionRow } from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import Dropdown from "../../../../components/dropdown/Dropdown";
import { booleanDropDown } from "../../../../utils/AplicationFormDropdowns";

export default function identification(props) {
  const {
    identification,
    isTrueTxt,
    studentData,
    distance,
    distanceFromHomeToCampus,
    onChangeDistance,
    onChangeDistanceToCampus,
    handleCalculateDistance,
    handleCalculateDistanceToCampus,
    isCalculatingDistance,
    onChangeStudentData,
    displacementConditions,
    onChangeDisplacementConditions,
  } = props;

  return (
    <>
      <TitleSelectionRow middleTitle>
        <p>Identificação do Estudante</p>
      </TitleSelectionRow>
      <CardMenu middleCard>
        <SelectionRow300px>
          <InputFloat
            label="Nome civil"
            disabled={true}
            value={identification.name}
          />
          {identification.socialName && (
            <InputFloat
              label="Nome social"
              disabled={true}
              value={identification.socialName}
              hide={identification.socialName}
            />
          )}
          <InputFloat label="CPF" disabled={true} value={identification.cpf} />
          <InputFloat
            label="Matrícula"
            disabled={true}
            value={studentData.student_id}
          />
          <InputFloat
            label="Curso"
            disabled={true}
            value={identification.course}
          />
          <InputFloat label="Turno" disabled={true} value={studentData.round} />

          <InputFloat
            label="Cidade de Origem"
            disabled={true}
            value={studentData.originCity}
          />

          <InputFloat
            label="Forma de ingresso"
            disabled={true}
            value={identification.entryForm.option}
          />
          {!identification.scholarship.option && (
            <>
              <InputFloat
                label="Tem bolsa acadêmica"
                disabled={true}
                value={isTrueTxt(identification.scholarship.option)}
              />
            </>
          )}
          {identification.scholarship.option && (
            <>
              <InputFloat
                label="Tipo da bolsa acadêmica"
                disabled={true}
                value={identification.scholarship.typeScholarship}
              />
              <InputFloat
                label="Valor da bolsa acadêmica"
                isMoney
                disabled={true}
                value={
                  identification.scholarship.value
                    ? String(identification.scholarship.value)
                    : ""
                }
              />
            </>
          )}
          <InputFloat
            label="Outra graduação"
            name={"otherGraduate"}
            disabled={true}
            value={isTrueTxt(identification.otherGraduate.option)}
          />

          {identification.otherGraduate.option && (
            <InputFloat
              label="Qual a graduação?"
              disabled={true}
              value={identification.otherGraduate.course}
            />
          )}

          <InputFloat
            label="Usuário da prac"
            disabled={true}
            value={isTrueTxt(identification.pracUser.option)}
          />

          <InputFloat
            label="PcD ♿"
            disabled={true}
            value={isTrueTxt(identification.disabilities.option)}
          />

          <InputFloat
            label="Concluiu Ensino Médio"
            disabled={true}
            value={identification.highSchoolType}
          />
        </SelectionRow300px>
      </CardMenu>

      <TitleSelectionRow middleTitle>
        <p>Informações Adicionais</p>
      </TitleSelectionRow>
      <CardMenu middleCard>
        <SelectionRow300px>
          <InputFloat
            label="N° de pessoas da família"
            value={String(studentData.familySize)}
            onChange={(event) =>
              onChangeStudentData(event.target.value, "familySize")
            }
          />

          <Dropdown
            placeholder="Possui condições de deslocamento diário até o campus?"
            value={isTrueTxt(displacementConditions)}
            onChange={onChangeDisplacementConditions}
            options={booleanDropDown}
          />
        </SelectionRow300px>

        <SelectionRow300px>
          <InputFloat
            label="Renda bruta familiar"
            isMoney
            value={String(studentData.grossIncome)}
            onChange={(event) =>
              onChangeStudentData(event.target.value, "grossIncome")
            }
          />
          <InputFloat
            label="Renda per capita"
            isMoney
            value={String(studentData.perCapitaIncome)}
            onChange={(event) =>
              onChangeStudentData(event.target.value, "perCapitaIncome")
            }
          />

          <InputFloat
            isNumber
            name="distance"
            label="Distância de Origem dos Responsáveis (Km)"
            value={distance}
            disabled={isCalculatingDistance}
            onChange={onChangeDistance}
            helperText="Clique para recalcular uma vez."
            inputProps={{ onClick: handleCalculateDistance }}
          />

          <InputFloat
            isNumber
            name="distanceFromHomeToCampus"
            label="Distância de Moradia do Estudante (Km)"
            value={distanceFromHomeToCampus}
            disabled={isCalculatingDistance}
            onChange={onChangeDistanceToCampus}
            helperText="Distância do endereço no Controle Acadêmico até o Campus. Clique para recalcular uma vez."
            inputProps={{ onClick: handleCalculateDistanceToCampus }}
          />
        </SelectionRow300px>

      </CardMenu >
    </>
  );
}
