import DisabilityEnum from '../models/enums/DisabilityTypes';
import EntryFormEnum from '../models/enums/EntryFormEnum';

export const identificationDropdowns = {
  sexOptions: ['MASCULINO', 'FEMININO'],
  genderIdentityOptions: ['Homem CIS', 'Mulher CIS', 'Homem TRANS', 'Mulher TRANS', 'Não desejo declarar'],
  sexualOrientationOptions: ['Assexual', 'Bissexual', 'Gay', 'Heterossexual', 'Lésbica', 'Panssexual', 'Outra', 'Não desejo declarar'],
  ethnicRacialBelongingOptions: ['Quilombola', 'Indígena', 'Nenhuma das opções'],
  maritalStatusOptions: ['Solteiro', 'Casado', 'União estável', 'Separado judicialmente', 'Viúvo', 'Divorciado'],
  colorOptions: ['Não declarada', 'Branca', 'Preta', 'Parda', 'Amarela', 'Indígena'],
  elementarySchoolType: ['Pública', 'Privada', 'Privada com Bolsa'],
  highSchoolType: [
    'Somente escola privada',
    'Somente escola pública',
    'Pública e privada, tendo ficado mais tempo em escola pública',
    'Pública e privada, tendo ficado mais tempo em escola privada',
  ],
  disabilitiesType: DisabilityEnum.values(),
  entryFormOption: ['Ampla concorrência', 'Cotas', 'Mobilidade Acadêmica', 'PEC-G', 'Transferência', 'Vagas livres'],
  entryFormTypeQuota: EntryFormEnum.values(),
  eadMode: {
    equipmentType: ['Notebook', 'Tablet', 'Celular'],
    internetType: ['Banda larga', 'Rede 4/3G', 'Internet à Rádio'],
  }
};
export const ticsDropdowns = {
    ticEquipmentOptions: ['Desktop/Computador de mesa', 'Notebook', 'Tablet', 'Celular'],
    internetType: ['Banda larga/fibra ótica', 'Internet móvel/4-3G', 'Internet via rádio', 'Internet via satélite'],
};

export const familyIncomeInfoDropdowns = {
  employmentRelationshipOptions: ['CLT - Carteira de Trabalho Assinada', 'Estatutário', 'Contrato', 'Não possui vinculo mas contribui para o INSS', 'Não possui vinculo e não contribui para o INSS'],
};

export const housingDropdowns = (haveFinancialIndepedence, parentHaveSameCep) => {
  return {
    whoWithReside: ['Sozinho', 'Pais/responsáveis', 'Parentes', 'Cônjuge', 'Amigos', 'Outro'],
    situation: ['Própria', 'Cedida', 'Alugada', 'Pensionato', 'Financiada', 'Outro'],
  }
};
export const typeLocomotionDropdown = [
  'Transporte coletivo',
  'Transporte locado (Van, ônibus)',
  'Carro/moto próprio',
  'Carona',
  'A pé',
  'Outro',
];
export const housingParentsDropdowns = {
  typeHousing: ['Própria', 'Financiado', 'Alugada', 'Cedido', 'Outro'],
};
export const kinshipDropdown = [
  'Pai',
  'Mãe',
  'Irmão(ã)',
  'Tio',
  'Tia',
  'Padrasto',
  'Madrasta',
  'Avó',
  'Avô',
  'Enteado(a)',
  'Esposa',
  'Marido',
  'Filho(a)',
  'Outro'
];
export const maritalStatusDropdown = ['Solteiro(a)', 'Casado(a)', 'União Estável', 'Divorciado(a)', 'Viuvo(a)'];
export const schoolingDropdown = [
  'Ensino Fundamental incompleto',
  'Ensino Fundamental completo',
  'Ensino Médio incompleto',
  'Ensino Médio completo',
  'Nível superior incompleto',
  'Nível superior completo',
  'Pós-Graduação - Especialização incompleto',
  'Pós-Graduação - Especialização completo',
  'Pós-Graduação - Mestrado incompleto',
  'Pós-Graduação - Mestrado completo',
  'Pós-Graduação - Doutorado incompleto',
  'Pós-Graduação - Doutorado completo',
];
export const otherIncomeReceivedDropdowns = {
  typeIncome: [
    'Mesada',
    'Auxílio de parentes ou amigos',
    'Aluguel ou arrendamento',
    'Programas de transferência de renda',
    'Não recebo',
    'Outro',
  ],
};
export const familyHealthSituationDropdowns = {
  typeIncome: ['Público/SUS', 'Particular/Planos de saúde', 'Misto (SUS/Convênios de saúde)'],
};
export const booleanDropDown = ['Sim', 'Não'];
