import styled from 'styled-components';

const buttonDefault = styled.button`
	background: transparent;
	width: 100%;
	border: none;
	height: 100%;
	text-align: center;
	color: #2e82c8;
	cursor: pointer;
	font-weight: 600;
	outline:none;
	:-moz-focus-inner {
		border: 0;
	}
`;
export default buttonDefault;
