import ApiBase from "./ApiBaseUrl";

const UserApi = {
  verification: (data) => ApiBase().post("/user/verification", data),
  getCep: (email, cpf, _id, birthDayDate, firstMotherName) =>
    ApiBase().get(`/user/verification?_id=${_id}&email=${email}&cpf=${cpf}&birthDayDate=${birthDayDate}
   &firstMotherName=${firstMotherName}`),
  create: (data) => ApiBase().post("/user/create", data),
  login: (data) => ApiBase(true).post("/user/login", data),
};

export default UserApi;
