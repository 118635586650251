import React from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./controllers/navbars/NavbarController";
import "./App.css";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import LoginController from "./controllers/user/LoginController";
import FaqController from "./controllers/faq/controller";

const App = () => (
  <div className="App">
    <ToastContainer />
    <Switch>
      <Route exact path="/" component={LoginController} />;
      <Route exact path="/faq" component={FaqController} />;
      <Route path="/usuario" component={Navbar} />
    </Switch>
  </div>
);

export default App;
