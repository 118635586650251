import React from "react";
import ReactExport from "react-export-excel";
import { ButtonPrimary } from "../buttonDefault/Button";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Component = ({ data, fileName, labelMessage }) =>
  <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
    {data.length > 0 ? (

      <ExcelFile filename={fileName} element={<ButtonPrimary small>{labelMessage}</ButtonPrimary>}>
        <ExcelSheet data={data} name='Estudantes'>
          {Object.keys(data[0]).map((prop) => (<ExcelColumn label={prop} value={prop} />))}
        </ExcelSheet>
      </ExcelFile>
    ) : (
      <ButtonPrimary disabled small>{labelMessage}</ButtonPrimary>
    )}
  </div>

export default Component;
