import React from "react";
import { CardMenu, SelectionRow200px } from "../../Styles";
import InputFloat from "../../../../components/InputFloat";

export default function address(props) {
  const { address, onChangeAddress } = props;

  return (
    <CardMenu>
      <SelectionRow200px>
        <InputFloat
          disabled={true}
          label="Rua"
          name="street"
          value={address.street}
          onChange={onChangeAddress}
        />
        <InputFloat
          disabled={true}
          label="Bairro"
          name="neighborhood"
          value={address.neighborhood}
          onChange={onChangeAddress}
        />
        <InputFloat
          disabled={true}
          label="Número"
          name="number"
          value={address.number}
          onChange={onChangeAddress}
        />
        <InputFloat
          disabled={true}
          label="Cep"
          name="cep"
          value={address.cep}
          onChange={onChangeAddress}
        />
      </SelectionRow200px>
      <SelectionRow200px>
        <InputFloat
          disabled={true}
          label="Cidade"
          name="city"
          value={address.city}
          onChange={onChangeAddress}
        />
        <InputFloat
          disabled={true}
          label="Estado"
          name="state"
          value={address.state}
          onChange={onChangeAddress}
        />
        <InputFloat
          label="Ponto de referência"
          name="referencePoint"
          value={address.referencePoint}
          onChange={onChangeAddress}
        />
        <br />
      </SelectionRow200px>
    </CardMenu>
  );
}
