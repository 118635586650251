import React from 'react';
import { CardMenu, StudentBoxFormRow } from '../../Styles';
import InputFloat from '../../../../components/InputFloat';
import Dropdown from '../../../../components/dropdown/Dropdown';

export default function HousingCard(props) {
	const {
		housingDropdowns,
		booleanDropDown,
		housing,
		onChangeSituationDividends,
		onChangeWhoWithReside,
		onChangeSituationTypeHousing,
		onChangeWhoWithResideOther,
		onChangeSituationTypeHousingOther,
		onChangeSituationHowManyPersons,
		onChangeSituationValue,
		isTrueTxt,
	} = props;

	return (
		<CardMenu>
			<StudentBoxFormRow>
				<Dropdown
					placeholder="Com quem você mora?"
					options={housingDropdowns.whoWithReside}
					value={housing.whoWithReside.option}
					onChange={onChangeWhoWithReside}
				/>

				{housing.whoWithReside.option === 'Outro' ? (
					<InputFloat
						label="Especifique"
						value={housing.whoWithReside.other}
						onChange={onChangeWhoWithResideOther}
					/>
				) : (
					<br />
				)}

				<Dropdown
					placeholder="Divide com alguem?"
					options={booleanDropDown}
					disabled={housing.whoWithReside.option !== 'Outro'}
					value={isTrueTxt(housing.situation.dividends)}
					onChange={onChangeSituationDividends}
				/>
				{housing.situation.dividends ? (
					<InputFloat
						label="Divide com quantas pessoas?"
						value={String(housing.situation.howManyPersons)}
						onChange={onChangeSituationHowManyPersons}
					/>
				) : (
					<br />
				)}
			</StudentBoxFormRow>

			<StudentBoxFormRow>
				<Dropdown
					placeholder="Em que situação de moradia, abaixo, você se encontra?"
					options={housingDropdowns.situation}
					value={housing.situation.typeHousing.option}
					onChange={onChangeSituationTypeHousing}
				/>
				{housing.situation.typeHousing.option === 'Outro' ? (
					<InputFloat
						label="Especifique"
						value={housing.situation.typeHousing.other}
						onChange={onChangeSituationTypeHousingOther}
					/>
				) : housing.situation.typeHousing.option === 'Alugada' ||
					housing.situation.typeHousing.option === 'Financiada' ||
					housing.situation.typeHousing.option === 'Pensionato' ? (
					<InputFloat
						isNumber
						label="Pago o valor de R$"
						value={housing.situation.value ? housing.situation.value : ''}
						onChange={onChangeSituationValue}
					/>
				) : (
					<br />
				)}
			</StudentBoxFormRow>
		</CardMenu>
	);
}
