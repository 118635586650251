import React from 'react';
import './style.css';
import { Tab } from './Styles';
const Burger = (props) => {
	const { open, setOpen, itens, firstRender } = props;
	return (
		<div className="sandwichContainer">
			<div className={open ? 'menu-btn open' : 'menu-btn'} onClick={setOpen}>
				<Tab firstRender={firstRender} open={open}>{itens.map((item) => <div key={Math.random()} > {item}</div>)}</Tab>

				<div className="menu-btn__burger"></div>
			</div>
		</div >
	);
};

export default Burger;
