import React from "react";
import { CardMenu, SelectionRow200px } from "../../Styles";
import Dropdown from "../../../../components/dropdown/Dropdown";

export default function ticInformationCard(props) {
  const {
    ticInformation,
    ticsDropdowns,
    booleanDropDown,
    isTrueTxt,
    ticCallbacks,
  } = props;

  return (
    <CardMenu>
      <SelectionRow200px>
        <Dropdown
          placeholder="Você utiliza algum equipamento para realização das atividades acadêmicas?"
          options={booleanDropDown}
          value={isTrueTxt(ticInformation.equipmentUsing)}
          onChange={ticCallbacks.onChangeEquipmentUsing}
        />
      </SelectionRow200px>

      {ticInformation.equipmentUsing && (
        <SelectionRow200px>
          <Dropdown
            placeholder="Se utiliza, qual tipo de equipamento?"
            options={ticsDropdowns.ticEquipmentOptions}
            value={ticInformation.ticEquipment}
            onChange={ticCallbacks.onChangeTicEquipment}
          />
          <Dropdown
            placeholder="O equipamento utilizado é próprio?"
            options={booleanDropDown}
            value={isTrueTxt(ticInformation.ownEquipment)}
            onChange={ticCallbacks.onChangeOwnEquipment}
          />
          <Dropdown
            placeholder="O equipamento encontra-se em boas condições para uso?"
            options={booleanDropDown}
            value={isTrueTxt(ticInformation.equipmentConditions)}
            onChange={ticCallbacks.onChangeEquipmentConditions}
          />
        </SelectionRow200px>
      )}

      <SelectionRow200px>
        <Dropdown
          placeholder="Você possui acesso à rede de internet?"
          options={booleanDropDown}
          value={isTrueTxt(ticInformation.internetAccess)}
          onChange={ticCallbacks.onChangeInternetAccess}
        />
        {ticInformation.internetAccess && (
          <Dropdown
            placeholder="Caso possua, qual tipo de rede utiliza?"
            options={ticsDropdowns.internetType}
            value={ticInformation.internetType}
            onChange={ticCallbacks.onChangeInternetType}
          />
        )}
      </SelectionRow200px>
    </CardMenu>
  );
}
