import axios from "axios";
import { toast } from "react-toastify";
import { getToken, getAuthenticate } from "./LocalAuth";

import { LOCAL_SERVER_URL, SERVER_URL, SERVER_IS_LOCAL } from "../config/Base";

const api = (isLogin) => {
  const isLocal = SERVER_IS_LOCAL;
  const [localhost, server] = [
    LOCAL_SERVER_URL,
    SERVER_URL,
  ];
  const apiBase = axios.create({
    baseURL: isLocal ? localhost : server,
  });
  apiBase.interceptors.request.use(async (config) => {
    if (getAuthenticate()) {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  if (!isLogin) {
    apiBase.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const list = [
          "/usuario/selecoes/ver/rankeamento",
          // "/usuario/admin/selecoes",
        ];
        const includesUrl = list.reduce((acc, current) => {
          if (window.location.href.includes(current)) {
            acc = true;
          }
          return acc;
        }, false);
        if (error.response === undefined) {
          error = {
            response: { data: { message: "Servidor não disponivel" } },
          };
        } else if (includesUrl) {
          toast.error(error.response.data.message);
        } else {
          if (error.response.status === 401) {
            toast.error("Servidor não disponivel");
            window.location.href = "/";
          }
        }
        return Promise.reject(error);
      }
    );
  }
  return apiBase;
};
export default api;
