const EntryFormEnum = Object.freeze({
    ESCOLA_RENDA_COR: "Escola Renda Cor",
    ESCOLA_RENDA: "Escola Renda",
    ESCOLA_COR: "Escola Cor",
    ESCOLA: "Escola",
    ESCOLA_RENDA_COR_DEFICIENCIA: "Escola Renda Cor Deficiencia",
    ESCOLA_RENDA_DEFICIENCIA: "Escola Renda Deficiencia",
    ESCOLA_COR_DEFICIENCIA: "Escola Cor Deficiencia",
    ESCOLA_DEFICIENCIA: "Escola Deficiencia",
    ESCOLA_RENDA_COR_2024: "Escola Renda Cor 2024",
    ESCOLA_RENDA_QUILOMBOLA_2024: "Escola Renda Quilombola 2024",
    ESCOLA_RENDA_DEFICIENCIA_2024: "Escola Renda Deficiencia 2024",
    ESCOLA_RENDA_2024: "Escola Renda 2024",
    ESCOLA_COR_2024: "Escola Cor 2024",
    ESCOLA_QUILOMBOLA_2024: "Escola Quilombola 2024",
    ESCOLA_DEFICIENCIA_2024: "Escola Deficiencia 2024",
    ESCOLA_2024: "Escola 2024",
    BONIFICACAO_ESTADUAL: "Bonificação estadual",

    values: function () {
        return Object.values(this);
    }
});

module.exports = EntryFormEnum;