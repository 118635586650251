import React, { Component } from "react";
import NoticeApi from "../../services/NoticeApi";
import RankApi from "../../services/rankApi";
import moment from "moment";
import { toast } from "react-toastify";
import NoticeView from "../../view/selections/admin/notice/NoticeView";
import AdminSelectionApi from "../../services/SelectionAdminApi";
import SelectionApi from "../../services/SelectionAdminApi";
import NoticeAdminApi from "../../services/NoticeApi";
import { Growl } from "primereact/growl";
import Loader from "../../components/loader/Loder";

export default class NoticeController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      selections: [],
      delete: false,
      loading: true,
      registrationPeriodBegins: "",
      registrationPeriodEnds: "",
      parcialDeadline: "",
      finalDeadline: "",
      notice: "",
      studentsList: [],
      _idNotice: "",
      modalTypeSelection: "",
      typeSelectionList: [],
      editModalStatus: false,
      noticeModalData: {
        registrationPeriodBegins: "",
        registrationPeriodEnds: "",
        parcialDeadline: "",
        finalDeadline: "",
      },
      isSuperAdm: false,
    };
  }
  editModeOn = () => {
    this.setState({ onEdit: true });
  };
  cancelUpdate = () => {
    AdminSelectionApi.getData().then((res) => {
      let message = res.data.message;
      message.onEdit = false;
      this.setState(message);
    });
  };
  componentDidMount() {
    const idList = this.props.location.search
      .substring(1)
      .split("&")
      .map((element) => element.split("="));

    const noticeId = idList[0][1];
    this.loadPage(noticeId);
  }
  loadPage = (noticeId) =>
    Promise.all([
      AdminSelectionApi.getSelections(noticeId),
      NoticeAdminApi.getNoticeStudentsList(noticeId),
      NoticeAdminApi.getAvailableSelections(noticeId),
      NoticeAdminApi.isSuperADM(),
    ]).then(async ([res, res1, res2, res3]) => {
      const {
        registrationPeriodBegins,
        registrationPeriodEnds,
        parcialDeadline,
        finalDeadline,
        notice,
        message,
        _idNotice,
      } = res.data;

      const isSuperAdm = res3.data.success;
      const message1 = res1.data.message;
      const selectionsAvaliableList = res2.data.availableSelections;

      this.setState(
        {
          registrationPeriodBegins: moment(registrationPeriodBegins).format(
            "DD/MM/YYYY"
          ),
          registrationPeriodEnds: moment(registrationPeriodEnds).format(
            "DD/MM/YYYY"
          ),
          parcialDeadline: moment(parcialDeadline).format("DD/MM/YYYY"),
          finalDeadline: moment(finalDeadline).format("DD/MM/YYYY"),
          notice,
          _idNotice,
          selections: message,
          studentsList: message1,
          typeSelectionList: selectionsAvaliableList,
          modalTypeSelection: "",
          modalStatus: false,
          isSuperAdm,
        },
        () => {
          RankApi.getSelections(this.state.isSuperAdm ? noticeId : undefined).then(({ data }) => {
            const rankSelectionsData = data.message;
            const selections = this.state.selections.map((noticeSelection) => {
              const rankSelection = rankSelectionsData.find(
                (selection) => selection._id === noticeSelection._id
              );
              return rankSelection ? rankSelection : noticeSelection;
            });
            this.setState({ selections, loading: false });
          }
          ).catch((error) => this.setState({ loading: false }))
        }
      );
    });

  deleteSelection = (e) => {
    const selectionId = e.target.id;
    AdminSelectionApi.delete(selectionId).then(
      () => {
        this.growl.show({ severity: "success", summary: "Seleção apagada." });

        const { selections } = this.state;
        const newSelections = selections.filter(
          (selection) => selection._id !== selectionId
        );

        this.setState({ selections: newSelections });
      },
      (err) => {
        const responseError = err.response.data.message;

        this.growl.show({ severity: "error", summary: responseError });
      }
    );
  };
  goTo = (path) => this.props.history.push(path);

  onChangeTypeSelection = (e) => this.setState({ modalTypeSelection: e.value });
  changeOpenCreateSelection = () =>
    this.setState({ openCreateSelection: !this.state.openCreateSelection });
  openModal = () => this.setState({ modalStatus: true });
  closeModal = () => this.setState({ modalStatus: false });
  createSelection = () => {
    const { modalTypeSelection, _idNotice } = this.state;

    this.setState({ loading: true }, () =>
      SelectionApi.createSelection({
        typeSelection: modalTypeSelection,
        notice: _idNotice,
      }).then((res) => {
        const { message } = res.data;

        this.loadPage(_idNotice).then(() =>
          this.growl.show({ severity: "success", summary: message })
        );
      })
    );
  };
  onChangeNoticeData = (e, type) =>
    this.setState({
      noticeModalData: {
        ...this.state.noticeModalData,
        [type]: e.target.value,
      },
    });
  onChangeModalStatus = () =>
    this.setState({ modalStatus: !this.state.modalStatus });
  onChangeEditModalStatus = () => {
    let noticeModalData = !this.state.editModalStatus
      ? {
        registrationPeriodBegins: this.state.registrationPeriodBegins,
        registrationPeriodEnds: this.state.registrationPeriodEnds,
        parcialDeadline: this.state.parcialDeadline,
        finalDeadline: this.state.finalDeadline,
      }
      : this.state.noticeModalData;

    this.setState({
      noticeModalData,
      editModalStatus: !this.state.editModalStatus,
    });
  };
  updateNotice = () =>
    this.setState({ loading: true }, () =>
      NoticeApi.updateNotice(this.state._idNotice, {
        _id: this.state._idNotice,
        name: this.state.notice,
        ...this.state.noticeModalData,
      }).then(
        () =>
          this.setState(
            {
              modalStatus: false,
              editModalStatus: false,
              loading: false,
              ...this.state.noticeModalData,
              noticeModalData: {
                registrationPeriodBegins: "",
                registrationPeriodEnds: "",
                parcialDeadline: "",
                finalDeadline: "",
              },
            },
            () => {
              toast.success("Edital atualizado");
            }
          ),
        (err) => {
          this.setState({ loading: false }, () =>
            toast.error(err.response.data.message)
          );
        }
      )
    );
  render() {
    const {
      loading,
      modalStatus,
      noticeModalData,
      registrationPeriodBegins,
      registrationPeriodEnds,
      parcialDeadline,
      finalDeadline,
      notice,
      studentsList,
      _idNotice,
      modalTypeSelection,
      typeSelectionList,
      editModalStatus,
      isSuperAdm,
    } = this.state;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        {loading ? (
          <Loader start={loading} />
        ) : (
          <NoticeView
            isSuperAdm={isSuperAdm}
            typeSelectionList={typeSelectionList}
            modalTypeSelection={modalTypeSelection}
            _idNotice={_idNotice}
            studentsList={studentsList}
            data={this.state.selections}
            noticeModalData={noticeModalData}
            deleteSelection={this.deleteSelection}
            goTo={this.goTo}
            registrationPeriodBegins={registrationPeriodBegins}
            registrationPeriodEnds={registrationPeriodEnds}
            parcialDeadline={parcialDeadline}
            finalDeadline={finalDeadline}
            notice={notice}
            modalStatus={modalStatus}
            createNotice={this.createNotice}
            onChangeModalStatus={this.onChangeModalStatus}
            onChangeNoticeData={this.onChangeNoticeData}
            changeOpenCreateSelection={this.changeOpenCreateSelection}
            openModal={this.openModal}
            closeModal={this.closeModal}
            onChangeTypeSelection={this.onChangeTypeSelection}
            createSelection={this.createSelection}
            updateNotice={this.updateNotice}
            onChangeEditModalStatus={this.onChangeEditModalStatus}
            editModalStatus={editModalStatus}
          />
        )}
      </>
    );
  }
}
