import React, { Component } from "react";
import { Growl } from "primereact/growl";
import { RadioButton } from "primereact/radiobutton";
import Interview from "../../view/selections/admin/interview/Interview";
import ApplicationStudentFormController from "../student/ApplicationFormController";
import SelectionApi from "../../services/SelectionAdminApi";
import Loader from "../../components/loader/Loder";
import Notice from "../../services/NoticeApi";
import { lists } from "../../utils/InterviewDropdows";
import Sandwich from "../../components/testSandwich/Sandwich";
import { ButtonPrimary } from "../../components/buttonDefault/Button";
import { convert } from "../../utils/MaskFunctions";
import { OTHER_AGGRAVANTING_LIMIT } from "../../utils/constants";
import DistanceTypeEnum from "../../models/enums/DistanceEnum";

export default class InterviewController extends Component {

  LIMIT_CLICKS_TO_RECALCULATE = 1;

  constructor(props) {
    super(props);

    this.state = {
      firstRender: true,
      showNavigationContainer: false,
      InterviewScroll: 0,
      applicationFormScroll: 0,
      distance: "",
      distanceFromHomeToCampus: "",
      isCalculatingDistance: false,
      typeSelection: [],
      notice_id: "",
      loading: true,
      edit: false,
      interviewActiveView: true,
      modalStatus: false,
      student_id: "",
      applicationForm: {},
      deferment: [],
      comments: [""],
      aggravating: {
        income: {
          value: 0,
          option: "Não se aplica",
        },
        family: {
          value: 0,
          option: "Não se aplica",
        },
        health: {
          value: 0,
          option: "Não se aplica",
        },
        displacement: {
          value: 0,
          option: "Não se aplica",
        },
        homeFamily: {
          value: 0,
          option: "Não se aplica",
        },
        homeStudent: {
          value: 0,
          option: "Não se aplica",
        },
        //schoolingParents: {
        //  value: 0,
        //  option: "",
        //},
        relationLink: {
          value: 0,
          option: "Não se aplica",
        },
        localizationOrigin: {
          value: 0,
          option: "Não se aplica",
        },
        aggravatingGeration: {
          value: 0,
          option: "Não se aplica",
        },
        aggravatingLinkedTics: {
          value: 0,
          option: "Não se aplica",
        },
        aggravatingLinkedLifeAcademic: {
          value: 0,
          option: "Não se aplica",
        },
        otherAggravating: {
          value: 0,
          option: "Não se aplica",
        },
        mainProviderEmployment: {
          value: 0,
          option: "Não se aplica",
        },
        schoolingMainProvider: {
          value: 0,
          option: "Não se aplica",
        },
      },
      activeOtherAggravating: false,
      studentData: {
        student_id: "",
        originCity: "",
        distance: "",
        distanceFromHomeToCampus: "",
        round: "",
      },
      familiar_group_inputs: {
        name: "",
        cpf: "",
        age: "",
        kinship: "",
        maritalStatus: "",
        schooling: "",
        profession: "",
        grossMonthlyIncome: "",
      },
      fromWhoInput: "",
      familyAssetsRowData: {
        description: "",
        city: "",
        value: "",
      },
      familyHealthSituationRowData: {
        name: "",
        typeIllness: "",
      },
      displacementConditions: false,
    };
  }
  concatTypeSelection = (enterviewSelections, typeSelection) => {
    const enterviewSelectionsIds = enterviewSelections.map(
      (select) => select._id
    );
    typeSelection.forEach((selection) => {
      if (!enterviewSelectionsIds.includes(selection._id)) {
        enterviewSelections.push({ _id: selection._id, deferred: false });
      }
    });

    return enterviewSelections;
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    const idList = this.props.location.search
      .substring(1)
      .split("&")
      .map((element) => element.split("="));

    const applicationForm_id = idList[0][1];
    const student_id = idList[1][1];
    const notice_id = idList[2][1];
    const deferred = idList[3][1] === "true";
    const openInForm = idList[4][1] === "false";

    this.getStudentSelections(notice_id, student_id).then((typeSelection) => {
      SelectionApi.getInterview(notice_id, student_id, applicationForm_id).then(
        async (res) => {
          const { applicationForm, enterview, deferment } = res.data.message;
          const { studentData: { originalData } } = await this.getStudentData(notice_id, student_id, applicationForm_id);

          const studentData = {
            originalData,
            student_id: student_id,
            round: applicationForm.identification.round,
            grossIncome: convert(
              enterview.familiarGrossIncome,
              { toMask: true },
              {
                unit: "R$",
              }
            ),
            familySize: enterview.familySize,
            perCapitaIncome: convert(
              enterview.perCapitaIncome,
              { toMask: true },
              {
                unit: "R$",
              }
            ),
            originCity: this.getOriginCity(applicationForm),
          };
          enterview.deferment = this.concatTypeSelection(
            enterview.deferment,
            typeSelection
          );

          enterview.aggravating.otherAggravating =
            enterview.aggravating.otherAggravating &&
              !!enterview.aggravating.otherAggravating &&
              enterview.aggravating.otherAggravating.value &&
              enterview.aggravating.otherAggravating.option
              ? enterview.aggravating.otherAggravating
              : { value: "", option: "" };
          if (!enterview.aggravating.mainProviderEmployment)
            enterview.aggravating.mainProviderEmployment = {
              value: 0,
              option: "Não se aplica",
            };

          if (!enterview.aggravating.schoolingMainProvider)
            enterview.aggravating.schoolingMainProvider = {
              value: 0,
              option: "Não se aplica",
            };

          if (!enterview.distanceFromHomeToCampus) {
            try {
              const { data } = await SelectionApi.studentDistance(student_id, notice_id, DistanceTypeEnum.HOME_TO_CAMPUS);
              enterview.distanceFromHomeToCampus = data.message;

            } catch (error) {
              enterview.distanceFromHomeToCampus = 0;
            }
          }
          this.setState({
            deferment: deferment,
            typeSelection: typeSelection,
            loading: false,
            edit: true,
            deferred,
            notice_id: notice_id,
            applicationForm: applicationForm,
            studentData: studentData,
            ...enterview,
            activeOtherAggravating:
              enterview.aggravating.otherAggravating.value !== "",
            // firstInterview:false,
            comments:
              enterview.comments.length === 0 ? [""] : enterview.comments,
            interviewActiveView: openInForm,
          });
        },
        () =>
          Promise.all([
            this.getStudentData(notice_id, student_id, applicationForm_id),
            SelectionApi.studentDistance(student_id, notice_id).then(
              (res) => res.data.message,
              () => 0
            ),
            SelectionApi.studentDistance(student_id, notice_id, DistanceTypeEnum.HOME_TO_CAMPUS).then(
              (res) => res.data.message,
              () => 0
            )
          ]).then(
            (res) => {
              this.setState({
                typeSelection: typeSelection,
                deferment: typeSelection.map(({ _id }) => ({
                  _id,
                  deferred: false,
                })),
                notice_id: notice_id,
                applicationForm: res[0],
                interviewActiveView: openInForm,
                loading: false,
                distance: res[1],
                distanceFromHomeToCampus: res[2],
                studentData: res[0].studentData,
              });
            },
            (err) => {
              console.log(err);
            }
          )
      );
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCalculateDistance = () => {
    if (this.state.isCalculatingDistance) return;

    const student_id = this.state.studentData.student_id;
    const notice_id = this.state.notice_id;

    let clicksToRecalculateDistance = this.state.clicksToRecalculateDistance === undefined ? 0 : this.state.clicksToRecalculateDistance;

    if (clicksToRecalculateDistance >= this.LIMIT_CLICKS_TO_RECALCULATE) return;

    this.setState({
      isCalculatingDistance: true,
    });

    SelectionApi.studentDistance(student_id, notice_id)
      .then(
        (res) => res.data.message,
        () => 0
      )
      .then((recalculatedDistance) => {
        const maskedDistance = convert(recalculatedDistance, {
          toMask: true,
        });

        this.setState({
          distance: maskedDistance,
          clicksToRecalculateDistance: clicksToRecalculateDistance + 1,
          isCalculatingDistance: false
        });
      });
  };

  handleCalculateDistanceToCampus = () => {
    if (this.state.isCalculatingDistance) return;

    const student_id = this.state.studentData.student_id;
    const notice_id = this.state.notice_id;

    let clicksToRecalculateDistanceToCampus = this.state.clicksToRecalculateDistanceToCampus === undefined ? 0 : this.state.clicksToRecalculateDistanceToCampus;

    if (clicksToRecalculateDistanceToCampus >= this.LIMIT_CLICKS_TO_RECALCULATE) return;

    this.setState({
      isCalculatingDistance: true,
    });

    SelectionApi.studentDistance(student_id, notice_id, DistanceTypeEnum.HOME_TO_CAMPUS)
      .then(
        (res) => res.data.message,
        () => 0
      )
      .then((recalculatedDistance) => {
        const maskedDistance = convert(recalculatedDistance, {
          toMask: true,
        });

        this.setState({
          distanceFromHomeToCampus: maskedDistance,
          clicksToRecalculateDistanceToCampus: clicksToRecalculateDistanceToCampus + 1,
          isCalculatingDistance: false
        });
      });
  };

  getMoneyData = (familiarGroup, otherIncomeReceived) => {
    const familySize = familiarGroup.length;
    const grossIncome = familiarGroup
      .map((row) => row.grossMonthlyIncome)
      .reduce((acc, grossMonthlyIncome) => {
        acc += grossMonthlyIncome;
        return acc;
      }, 0);
    const perCapitaIncome = grossIncome / familySize;
    return {
      perCapitaIncome,
      familySize,
      grossIncome,
    };
  };
  getStudentData = (noticeId, student_id, applicationForm_id) => {
    return SelectionApi.getStudentData(noticeId, student_id).then((res) => {
      const { message: response, originalData } = res.data;
      const studentData = {
        originalData,
        originCity: this.getOriginCity(response),
        student_id: student_id,
        round: response.identification.round,
        ...this.getMoneyData(
          response.familiarGroup,
          response.otherIncomeReceived
        ),
      };

      return {
        ...response,
        student_id: student_id,
        applicationForm_id: applicationForm_id,
        studentData: studentData,
        familiarGrossIncome: studentData.grossIncome,
        familySize: studentData.familySize,
        perCapitaIncome: studentData.perCapitaIncome,
      };
    });
  };
  getStudentSelections = (notice_id, student_id) => {
    return Notice.getTypeSelectionStudent(student_id, notice_id).then(
      (res) => res.data.message
    );
  };
  isTrueTxt = (element) => {
    if (typeof element === "undefined") return "";
    return element ? "Sim" : "Não";
  };
  isChecked = (type, option, value) => {
    const aggravatingType = this.state.aggravating[type];

    return aggravatingType
      ? aggravatingType.option === option && aggravatingType.value === value
      : false;
  };
  onChangeAggravating = (e) => {
    const value = e.target.value;
    const aggravatingType = e.target.name;
    let aggravating = this.state.aggravating;
    aggravating[aggravatingType] = value;

    this.setState({ aggravating });
  };
  onChangeComents = (e) => {
    this.setState({ comments: [e.target.value] });
  };
  actionTemplate = (rowData, column) => {
    return (
      <RadioButton
        name={column.columnKey}
        value={rowData}
        onChange={this.onChangeAggravating}
        checked={this.isChecked(
          column.columnKey,
          rowData.option,
          rowData.value
        )}
      ></RadioButton>
    );
  };
  onChangeShowNavigationContainer = () =>
    this.setState({
      showNavigationContainer: !this.state.showNavigationContainer,
      firstRender: false,
    });

  handlerSubmit = () => {
    const { pass, message } = this.verifyAggravatings();
    if (pass) {
      this.setState({ loading: true }, () => {
        const {
          edit,
          applicationForm,
          selection_id,
          deferment,
          comments,
          aggravating,
          notice_id,
          distance,
          distanceFromHomeToCampus,
          studentData,
          displacementConditions,
        } = this.state;

        const InterviewFormData = {
          student_id: studentData.student_id,
          applicationForm_id: applicationForm._id,
          selection_id,
          familiarGrossIncome: convert(studentData.grossIncome),
          familySize: studentData.familySize,
          perCapitaIncome: convert(studentData.perCapitaIncome),
          comments,
          aggravating: {
            ...aggravating,
          },
          distance: convert(distance),
          distanceFromHomeToCampus: convert(distanceFromHomeToCampus),
          displacementConditions: displacementConditions,
        };
        const data = {
          _idStudent: studentData.student_id,
          _idNotice: notice_id,
          _idApplicationForm: applicationForm._id,
          enterviewFormData: InterviewFormData,
          deferment: deferment,
        };
        if (edit) {
          SelectionApi.updateInterview(data)
            .then((res) => this.setState({ modalStatus: true, loading: false }))
            .catch((err) => {
              this.setState({ loading: false }, () =>
                this.growl.show({
                  severity: "error",
                  summary: err.response.data.message,
                })
              );
            });
        } else {
          SelectionApi.makeInterview(data)
            .then((_res) =>
              this.setState({ modalStatus: true, loading: false })
            )
            .catch((err) => {
              this.setState({ loading: false }, () =>
                this.growl.show({
                  severity: "error",
                  summary: err.response.data.message,
                })
              );
            });
        }
      });
    } else {
      this.growl.show({ severity: "error", summary: message });
    }
  };
  onChangeDeferred = () => {
    const deferred = this.state.deferred;

    this.setState({ deferred: !deferred });
  };
  verifyAggravatings = () => {
    let out = {
      pass: true,
      message: "",
    };

    const aggravatingsKeys = Object.keys(this.state.aggravating);
    const aggravatings = Object.values(this.state.aggravating);
    let indexOfOtherAggravanting = aggravatingsKeys.indexOf("otherAggravating");

    aggravatings.forEach((element, index) => {
      const isOtherAggravanting = index === indexOfOtherAggravanting;

      if (
        element.value === 0 &&
        element.option !== "Não se aplica" &&
        !isOtherAggravanting
      ) {
        out = {
          pass: false,
          message: "Faltam agravantes",
        };
      } else if (isOtherAggravanting) {
        if (Number(element.value) > OTHER_AGGRAVANTING_LIMIT) {
          out = {
            pass: false,
            message: `O valor de "Outro Agravante" não pode ser superior a ${OTHER_AGGRAVANTING_LIMIT}.`,
          };
        } else if (
          this.state.activeOtherAggravating &&
          (element.option === "" || element.value === "")
        ) {
          out = {
            pass: false,
            message: `Insira a descrição do agravante, caso não for enviar os campos VD e agravantes desmarque a opção`,
          };
        }
      }
    });

    return out;
  };
  onChangeInterviewActiveView = (value) => {
    const { InterviewScroll, applicationFormScroll, interviewActiveView } =
      this.state;

    this.setState(
      {
        interviewActiveView: value,
        [interviewActiveView ? "InterviewScroll" : "applicationFormScroll"]:
          window.pageYOffset,
      },
      () => {
        const goTo = value ? InterviewScroll : applicationFormScroll;

        window.scrollTo(0, goTo);
      }
    );
  };
  totalAggravating = () =>
    Object.values(this.state.aggravating).reduce(
      (count, element) => count + Number(element.value),
      0
    );

  goBack = () => this.props.history.goBack();
  closeModal = () => this.setState({ modalStatus: false });
  onChangeStudentData = (value, type) =>
    this.setState({
      studentData: {
        ...this.state.studentData,
        [type]: value,
      },
    });

  onChangeDeffered = (id, value) => {
    let deferment = [...this.state.deferment];

    const newDeferment = deferment.map(({ _id, deferred }) =>
      _id === id
        ? { _id: _id, deferred: value }
        : { _id: _id, deferred: deferred }
    );

    this.setState({ deferment: newDeferment });
  };
  /**
   * on change from other aggravating
   */
  onChangeOtherAggravatingRadio = (e) => {
    let { name, value } = e.target;
    let { otherAggravating } = Object.assign({}, this.state.aggravating);
    let activeOtherAggravating = Object.assign(
      {},
      this.state.activeOtherAggravating
    );
    if (name === "radio-button") {
      otherAggravating = { value: "", option: "" };
      activeOtherAggravating = e.checked;
    } else {
      if (name === "value" && isNaN(value)) {
        return;
      }
      otherAggravating = { ...otherAggravating, [name]: value };
    }

    this.setState({
      activeOtherAggravating,
      aggravating: { ...this.state.aggravating, otherAggravating },
    });
  };

  onChangeDisplacementConditions = (e) => {
    this.setState({ displacementConditions: e.value === "Sim" ? true : false });
  };

  getOriginCity(applicationForm) {
    return !applicationForm.parentsAddress.city
      ? `${applicationForm.address.city} - ${applicationForm.address.state}`
      : `${applicationForm.parentsAddress.city} - ${applicationForm.parentsAddress.state}`;
  }

  render() {
    const {
      typeSelection,
      studentData,
      comments,
      deferred,
      loading,
      modalStatus,
      edit,
      distance,
      distanceFromHomeToCampus,
      isCalculatingDistance,
      applicationForm,
      firstTimeStatus,
      interviewActiveView,
      showNavigationContainer,
      firstRender,
      deferment,
      aggravating,
      activeOtherAggravating,
      displacementConditions,
    } = this.state;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        <Loader start={loading} />
        {!loading && (
          <>
            <Sandwich
              open={showNavigationContainer}
              setOpen={this.onChangeShowNavigationContainer}
              firstRender={firstRender}
              itens={[
                <ButtonPrimary
                  fixedWidth={true}
                  disabled={!showNavigationContainer}
                  width={159}
                  onClick={() => this.onChangeInterviewActiveView(false)}
                >
                  Formulário
                </ButtonPrimary>,
                <ButtonPrimary
                  fixedWidth={true}
                  disabled={!showNavigationContainer}
                  width={180}
                  onClick={() => this.onChangeInterviewActiveView(true)}
                >
                  Ficha de avaliação
                </ButtonPrimary>,
              ]}
            />
            {interviewActiveView ? (
              <Interview
                onChangeOtherAggravatingRadio={
                  this.onChangeOtherAggravatingRadio
                }
                activeOtherAggravating={activeOtherAggravating}
                aggravating={aggravating}
                deferment={deferment}
                onChangeDeffered={this.onChangeDeffered}
                closeModal={this.closeModal}
                distance={distance}
                distanceFromHomeToCampus={distanceFromHomeToCampus}
                isCalculatingDistance={isCalculatingDistance}
                edit={edit}
                onChangeStudentData={this.onChangeStudentData}
                typeSelection={typeSelection}
                studentData={studentData}
                applicationForm={applicationForm}
                actionTemplate={this.actionTemplate}
                deferred={deferred}
                isTrueTxt={this.isTrueTxt}
                lists={lists}
                totalAggravating={this.totalAggravating()}
                showNavigationContainer={showNavigationContainer}
                comments={comments}
                onChangeComents={this.onChangeComents}
                onChangeDeferred={this.onChangeDeferred}
                handlerSubmit={this.handlerSubmit}
                goBack={this.goBack}
                modalStatus={modalStatus}
                handleInputChange={this.handleInputChange}
                handleCalculateDistance={this.handleCalculateDistance}
                handleCalculateDistanceToCampus={this.handleCalculateDistanceToCampus}
                firstTimeStatus={firstTimeStatus}
                displacementConditions={displacementConditions}
                onChangeDisplacementConditions={
                  this.onChangeDisplacementConditions
                }
              />
            ) : (
              <ApplicationStudentFormController
                applicationForm={applicationForm}
                onChangeClearNavbar={() => { }}
                originalData={studentData.originalData}
              />
            )}
          </>
        )}
      </>
    );
  }
}
