import React, { Component } from "react";
import SelectionsView from "../../view/selections/student/selection/SelectionsView";
import SelectionApi from "../../services/SelectionApi";
import { Growl } from "primereact/growl";
import { Button } from "primereact/button";
import FileSaver from "file-saver";
import Loader from "../../components/loader/Loder";
import moment from "moment";

export default class SelectionController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {
        participating: [],
        notParticipating: [],
      },
      haveSelectionOpen: true,
      currentTab: 0,
      page: 0,
      modalStatus: false,
      sucessMessage: "",
    };
  }

  componentDidMount() {
    this.getSelections().then((res) => {
      this.setState(
        {
          loading: false,
          data: res.data,
          haveSelectionOpen: res.haveSelectionOpen,
        }
      );
    });
  }
  clearData = () => {
    const clearData = {
      data: [],
      page: 0,

      modalStatus: false,
      sucessMessage: "",
    };
    this.getSelections().then((response) => {
      const hasOpenSelections = response.notParticipating.reduce(
        (accumulate, selection) => {
          if (selection.status !== "NOTYETOPEN") accumulate += 1;
          return accumulate;
        },
        0
      );

      this.setState(
        {
          ...clearData,
          haveSelectionOpen: hasOpenSelections > 0,
          data: response,
        },
        () =>
          this.growl.show({
            severity: "error",
            summary: "Aviso não há seleções abertas no momento",
          })
      );
    });
  };
  getSelections = () => {
    return SelectionApi.getSelections().then((res) => {
      const { participating, notParticipating } = res.data.message;

      const newDataNotParticipating = notParticipating.map((selection) => {
        selection.checked = false;
        selection.registrationPeriodBegins = moment(
          selection.registrationPeriodBegins
        )
          .format("DD-MM-YYYY")
          .replace(/-/g, "/");
        selection.registrationPeriodEnds = moment(
          selection.registrationPeriodEnds
        )
          .format("DD-MM-YYYY")
          .replace(/-/g, "/");
        return selection;
      });
      const newDataParticipating = participating.map((selection) => {
        selection.checked = false;
        selection.registrationPeriodBegins = moment(
          selection.registrationPeriodBegins
        )
          .format("DD-MM-YYYY")
          .replace(/-/g, "/");
        selection.registrationPeriodEnds = moment(
          selection.registrationPeriodEnds
        )
          .format("DD-MM-YYYY")
          .replace(/-/g, "/");
        return selection;
      });
      const hasOpenSelections = newDataNotParticipating.reduce(
        (accumulate, selection) => {
          if (selection.status !== "NOTYETOPEN") accumulate += 1;
          return accumulate;
        },
        0
      );
      return {
        haveSelectionOpen: hasOpenSelections > 0,
        data: {
          participating: newDataParticipating,
          notParticipating: newDataNotParticipating,
        },
      };
    });
  };

  downloadPdf = () => {
    const { participating } = this.state.data;

    const selection = participating[0];

    return SelectionApi.getAplicationForm(selection._id).then(
      (response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        FileSaver.saveAs(blob, "FORMULÁRIO DE INSCRIÇÃO.pdf");
      },
      () =>
        this.growl.show({
          life: 5000,
          severity: "error",
          summary: "Formulário de inscrição não encontrado.",
        })
    );
  };
  onChangeSelecteds = (selection) => {
    let { notParticipating, participating } = this.state.data;

    const selectionData = selection.target;
    notParticipating.forEach((element) => {
      if (element._id === selectionData.id) {
        element.checked = selectionData.checked;
      }
    });

    this.setState({ data: { notParticipating, participating } });
  };

  actionTemplate = (rowData) => {
    return rowData.status === "NOTYETOPEN" ? (
      <div></div>
    ) : (
      <Button
        onClick={() => this.handleSubmit(rowData._id)}
        className="buttonSelection"
        label="Inscrever-se"
      ></Button>
    );
  };
  getOutStudent = (rowData) =>
    this.setState({ loading: true }, () =>
      SelectionApi.getOutSelection(rowData._id).then(
        () => {
          this.getSelections().then((res) => {
            this.setState(
              {
                loading: false,
                data: res.data,
                haveSelectionOpen: res.haveSelectionOpen,
              },
              () => {
                if (!res.haveSelectionOpen)
                  this.growl.show({
                    life: 5000,
                    severity: "error",
                    summary: "Aviso não há seleções abertas no momento",
                  });
                this.growl.show({
                  life: 5000,
                  severity: "success",
                  summary: "Inscrição cancelada com sucesso",
                });
              }
            );
          });
        },
        (err) =>
          this.setState({ loading: false }, () =>
            this.growl.show({
              life: 5000,
              severity: "error",
              summary: err.response.data.message,
            })
          )
      )
    );

  handleSubmit = (_id) => {
    this.setState({ loading: true }, () =>
      SelectionApi.submitToSelection({ _id }).then(
        (res) =>
          this.getSelections().then((res1) =>
            this.setState(
              {
                data: res1.data,
                haveSelectionOpen: res1.haveSelectionOpen,
                sucessMessage: res.data.message,
                modalStatus: true,
                loading: false,
              },
              () =>
                this.growl.show({
                  life: 2500,
                  severity: "success",
                  summary: this.state.sucessMessage,
                })
            )
          ),
        (err) => {
          this.setState({ loading: false }, () => {
            this.growl.show({
              life: 2500,
              severity: "error",
              summary: err.response.data.message
            })
          })
        }
      )
    );
  };

  goTo = (path) => this.props.history.push(path);

  onchangeModalStatus = () => this.setState({ modalStatus: true });

  onChangeTab = (value) => this.setState({ currentTab: value });

  render() {
    const { haveSelectionOpen, data, currentTab, loading } = this.state;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        {loading ? (
          <Loader start={loading} />
        ) : (
          <SelectionsView
            participating={data.participating}
            notParticipating={data.notParticipating}
            actionTemplate={this.actionTemplate}
            handleSubmit={this.handleSubmit}
            onChangeTab={this.onChangeTab}
            currentTab={currentTab}
            downloadPdf={this.downloadPdf}
            haveSelectionOpen={haveSelectionOpen}
            getOutStudent={this.getOutStudent}
          />
        )}
      </>
    );
  }
}
