import { conformToMask } from "react-text-mask";
import VMasker from "vanilla-masker";

export const dateFormatMask = (value) => {
  if (value.endsWith("/")) return value.substring(0, value.length - 1);

  const dateMask = [
    /[0-3]/,
    /[0-9]/,
    "/",
    /[0-1]/,
    /[0-9]/,
    "/",
    /[1-2]/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const date = conformToMask(value, dateMask, { guide: false });
  return date.conformedValue;
};

export const timeFormatMask = (value) => {
  const timeMask = [/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/];
  const time = conformToMask(value, timeMask, { guide: false });
  return time.conformedValue;
};

export const zipCodeFormatMask = (value) => {
  const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
  if (value.endsWith("-")) {
    return value.substring(0, value.length - 1);
  }
  const zipCode = conformToMask(value, zipCodeMask, { guide: false });
  return zipCode.conformedValue;
};

export const phoneFormatMask = (value) => {
  const phoneMask = [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const phone = conformToMask(value, phoneMask, { guide: false });
  return phone.conformedValue;
};

export const cpfFormatMask = (value) => {
  if (value.endsWith("-") || value.endsWith(".")) {
    return value.substring(0, value.length - 1);
  }
  const cpfCodeMask = [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
  const cpf = conformToMask(value, cpfCodeMask, { guide: false });
  return cpf.conformedValue;
};
export const convertNumber = (value) => {
  let aux = value;
  if (
    typeof aux !== "number" &&
    aux !== null &&
    aux !== undefined &&
    aux.includes(",")
  ) {
    aux = aux.replace("R$", "");
    aux = aux.replace(".", "").replace(",", ".");
  }

  return parseFloat(aux);
};

export const isNumber = (value) => {
  const re = /^\d+$/;
  return re.test(value);
}
export const mask = (value, fixValue = true, { unit, suffixUnit }) => {
  if (fixValue) {
    value = parseFloat(value).toFixed(2);
  }
  return VMasker.toMoney(value, {
    precision: 2,
    separator: ",",
    delimiter: ".",
    unit,
    suffixUnit,
  });
};

export const convert = (value, { toMask, fixValue } = {}, units = {}) =>
  toMask ? mask(value, fixValue, units) : convertNumber(value);
