import styled from "styled-components";

export const TitleSelectionRow = styled.div`
  width: 100%;
  position: relative;
  background: #2e82c8;
  height: 2px;
  margin-bottom: 15px;
  p {
    position: absolute;
    font-size: 20px;
    top: -11px;
    background: #ffff;
    padding: 0px 5px 0px 0px;
    left: 0px;
    color: #2e82c8;

    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  ${(props) => props.middleTitle && `margin-top: 25px;`}
`;
export const Container = styled.div`
  border: 1px solid #5f90fa;
  border-radius: 0px;
`;

export const UltraContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin-top: 10px;
`;
export const UltraContainerItem = styled.div`
  width: 100%;
  border-radius: 0px;
`;
export const CardMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: stretch;
  padding: 16px;
  background: #fff;
  flex-wrap: wrap;
  margin: 10px;
  ${(props) => props.middleCard && `border-radius: 0px;`}
`;
export const StyledColumn = {
  border: "none",
  width: "100px",
  borderBottom: "1px solid gray",
  background: "#ffffff",
};
export const VDColumn = {
  ...StyledColumn,
  width: "10px",
  textAlign: "center",
};
export const DeleteColumn = {
  ...StyledColumn,
  width: "50px",
};
export const ButtonsSpace = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  flex-direction: row;
`;

export const ButtonsSpaceDefferedContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  min-height: 77px;
  justify-items: center;
  border-radius: 0px;
`;
export const ButtonsSpaceSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  justify-items: center;
  padding: 15px;
  border-radius: 0px;
`;
export const ButtonsSpaceDeffered = styled.div`
  display: flex;
  margin-top: 2em;
  justify-content: space-around;
  width: 25%;
`;

export const ButtonSpace = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

export const DataTableContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  height: 100%;
`;
export const DataTableDiv = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SelectionRow200px = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 10px;
`;
export const GridDataTables = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  margin-top: 1em;
  margin-right: 2em;
  padding: 16px;
  gap: 10px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;
export const SelectionRow300px = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 1em;
  gap: 10px;
`;
export const AreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  textarea:focus,
  input:focus {
    outline: none;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  textarea {
    width: 100%;
    border: 2px solid rgb(46, 130, 200);
    padding: 3px;
    border-radius: 10px;
    min-height: 50px;
  }
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffff;
  height: 200px;
  width: 300px;
  padding: 15px;
  border-radius: 5px;
`;

export const ModalTitle = styled.p`
  font-size: 18px;
  margin: 10px 0;
`;

export const ModalMessage = styled.p`
  font-size: 14px;
  font-weight: normal;
`;
export const OtherAggravatingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
export const ModalButtonArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;
export const Tab = styled.div`
  display: flex;
  top: 67px;
  left: 91px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  ${(props) =>
    props.open
      ? `opacity:1;max-width:400px;z-index: 2;`
      : `opacity:0;max-width:0px;`}
  position: fixed;
  margin: 10px 0px;
  gap: 10px;
  transition: max-width 0.5s, opacity 0.2s;
  transition-timing-function: ease-in;
  @media (max-width: 412px) {
    flex-wrap: wrap;
    ${(props) => props.open && `opacity:1;max-width:325px;z-index: 2;`}
  }
`;
export const TabItem = styled.div`
  margin-top: 10px;
  width: 159px;
`;
