import ApiBase from "./ApiBaseUrl";

const Notice = {
  isSuperADM: () => ApiBase().get(`/noticeAdmin/isSuperAdmin`),
  getNotices: (quantity) => ApiBase().get(`/noticeAdmin/?_id=${quantity}`),
  getTypeSelectionStudent: (studentId, noticeId) =>
    ApiBase().get(
      `/noticeAdmin/typeSelectionsStudent?student=${studentId}&_id=${noticeId}`
    ),
  createNotice: (name) => ApiBase().post(`/noticeAdmin`, name),
  getPatternStudentsList: () =>
    ApiBase().get(`noticeAdmin/students?_id=pattern`),
  getNoticeStudentsList: (id) =>
    ApiBase().get(`noticeAdmin/students?_id=${id}`),
  deleteNotice: (id) => ApiBase().delete(`noticeAdmin/?_id=${id}`),
  getAvailableSelections: (id) =>
    ApiBase().get(`noticeAdmin/availableSelections?_id=${id}`),
  updateNotice: (id, data) => ApiBase().put(`/noticeAdmin?_id=${id}`, data),
};

export default Notice;
