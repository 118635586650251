export const list = (props) =>
  props.location.search
    .substring(1)
    .split("&")
    .map((element) => element.split("="))
    .reduce((acc, [type, value]) => {
      acc[type] = value;

      return acc;
    }, {});
