import React from "react";
import GenericTable from "../../../../components/genericTable";
import Loader from "../../../../components/loader/Loder";
import ExcelDownloadButton from "../../../../components/excelDownloadButton";
import { Container, TitleContainer, Line } from "./style";
const view = ({ selectionName, deferidos, indeferidos, studentProps, loading, isADM, type }) => {
  const portugueseType = type === 'partial' ? 'PARCIAL' : 'FINAL'
  return (
    <Container>
      <TitleContainer>
        <p>Seleção {selectionName}</p>
      </TitleContainer>
      <TitleContainer>
        <p>Estudantes Deferidos</p>
        <Line />
      </TitleContainer>
      <Loader start={loading} />
      {GenericTable(
        deferidos,
        studentProps.map((prop) => ({
          filter: true,
          field: prop,
          header: String(prop),
          filterPlaceholder: "filtro",
        })),
        "Não há estudantes"
      )}
      {isADM && < ExcelDownloadButton data={deferidos} fileName={`DEFERIDOS - ${portugueseType} - ${selectionName}`} labelMessage="Exportar" />}
      <TitleContainer>
        <p>Estudantes Indeferidos</p>
        <Line />
      </TitleContainer>
      {
        GenericTable(
          indeferidos,
          studentProps
            .filter((prop) => prop !== "fluxoLiquido" && prop !== "rank")
            .map((prop) => ({
              filter: true,
              field: prop,
              header: String(prop),
              filterPlaceholder: "filtro",
            })),
          "Não há estudantes"
        )
      }
      {isADM && < ExcelDownloadButton data={indeferidos} fileName={`INDEFERIDOS - ${portugueseType} - ${selectionName}`} labelMessage="Exportar" />}

    </Container >
  );
};
export default view;
