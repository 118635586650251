import React from 'react';
import InputFloat from '../../../../components/InputFloat';
import StudentsTable from '../StudentsTable';
import { UltraContainer, SelectionRow200px, TitleSelectionRow, CardMenu } from '../Style';
import ExcelDownloadButton from "../../../../components/excelDownloadButton";

const SelectionView = (props) => {
	const {
		typeSelection,
		registrationPeriodBegins,
		registrationPeriodEnds,
		studentsInterested,
		studentsRegistered,
		actionTemplate,
		actionTemplate1,
		studentsRejected,
		noticeName,
	} = props;

	const formatStudentCsvItem = (student) => {
		const isRegistered = studentsRegistered && studentsRegistered.some(registered => registered._id === student._id);
		const isRejected = studentsRejected && studentsRejected.some(rejected => rejected._id === student._id);
		const isInterested = !isRegistered && !isRejected && studentsInterested && studentsInterested.some(interested => interested._id === student._id);

		const situation = (isInterested ? 'INSCRITO' : '') + (isRegistered ? 'DEFERIDO' : '') + (isRejected ? 'INDEFERIDO' : '');

		return ({
			'Matrícula': student._id,
			'Nome': student.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()),
			'Email': student.email,
			'Situação': situation
		})
	}

	const interestedStudentCsv = studentsInterested.map(student => formatStudentCsvItem(student));

	return (
		<div style={{ padding: '15px 0px' }}>

			<UltraContainer paddingBottom>
				<TitleSelectionRow>
					<p>Informações gerais</p>
				</TitleSelectionRow>
				<CardMenu middleCard>
					<SelectionRow200px>
						<InputFloat big label="Edital" disabled={true} value={noticeName} />
						<InputFloat big label="Tipo de seleção" disabled={true} value={typeSelection} />
						<InputFloat big label="Abre em" disabled={true} value={registrationPeriodBegins} />
						<InputFloat big label="Fecha em" disabled={true} value={registrationPeriodEnds} />
					</SelectionRow200px>
				</CardMenu>
			</UltraContainer>
			<UltraContainer paddingTopBottom>
				<TitleSelectionRow middleTitle>
					<p>Estudantes inscritos</p>
				</TitleSelectionRow>
				<CardMenu middleCard>
					<StudentsTable studentsList={studentsInterested} actionTemplate={actionTemplate} thisView={this} />
				</CardMenu>
				<CardMenu>
					<ExcelDownloadButton data={interestedStudentCsv} fileName={`Inscritos - ${noticeName} - ${typeSelection}`} labelMessage="Exportar" />
				</CardMenu>
			</UltraContainer>
			<UltraContainer paddingTopBottom>
				<TitleSelectionRow middleTitle>
					<p>Estudantes deferidos</p>
				</TitleSelectionRow>
				<CardMenu middleCard>
					<StudentsTable studentsList={studentsRegistered} actionTemplate={actionTemplate1} thisView={this} />
				</CardMenu>
			</UltraContainer>
			<UltraContainer paddingTopBottom>
				<TitleSelectionRow middleTitle>
					<p>Estudantes indeferidos</p>
				</TitleSelectionRow>
				<CardMenu>
					<StudentsTable studentsList={studentsRejected} actionTemplate={actionTemplate1} thisView={this} />
				</CardMenu>
			</UltraContainer>
		</div>
	);
};
export default SelectionView;
