import styled from 'styled-components';

export const UltraContainer = styled.div`
	display: grid;
	gap:30px;
	padding: 25px 10px;
	margin: 15px 0px;
	width: 100%;
	justify-content: center;
`;
export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #ffff;
	width: 400px;
	padding: 15px;
	border-radius: 5px;
`;

export const ModalTitle = styled.p`
	font-size: 18px;
	margin: 10px 0;
	color: #2e82c8;
`;

export const ModalMessage = styled.p`
	font-size: 14px;
	font-weight: normal;
`;

export const ModalButtonArea = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	${(props) => props.justifyCenter && `  justify-content: center;`}
	margin-top: 30px;
`;