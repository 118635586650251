import React from "react";
import { getAuthenticate, isAdmin } from "./services/LocalAuth";
import { Route, Switch, Redirect } from "react-router-dom";
import SelectionsController from "./controllers/selections/SelectionsController";
import SelectionsAdminController from "./controllers/selections/SelectionController";
import NoticeController from "./controllers/notice/NoticeController";
import NoticesController from "./controllers/notice/NoticesController";
import InterviewController from "./controllers/interview/InterviewController";
import StudentController from "./controllers/student/StudentController";
import ApplicationFormController from "./controllers/student/ApplicationFormController";
import FaqController from "./controllers/faq/controller";
import RankController from "./controllers/rank/index";
import RankStudentController from "./controllers/student/rank";

const PrivateRoute = ({ component: Component, title, ...rest }) => {
  setDocumentTitle(title);
  const { onChangeClearNavbar } = rest;
  return (
    <Route
      {...rest}
      render={(props) =>
        getAuthenticate() ? (
          <Component {...props} onChangeClearNavbar={onChangeClearNavbar} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

const AdminRoute = ({ component: Component, title, ...rest }) => {
  setDocumentTitle(title);
  return (
    <Route
      {...rest}
      render={(props) =>
        getAuthenticate() && isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
const setDocumentTitle = (title) => {
  if (title === "") {
    document.title = "JUS2";
  } else {
    document.title = `${title} | JUS2`;
  }
};
export const UserRoutes = (props) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/usuario/estudante/selecoes"
        title={"Seleções"}
        component={SelectionsController}
      />
      <PrivateRoute
        exact
        path="/usuario/estudante/perfil"
        title={"Perfil"}
        component={StudentController}
      />
      <PrivateRoute
        exact
        onChangeClearNavbar={props.onChangeClearNavbar}
        title={"Formulário"}
        path="/usuario/estudante/formulario"
        component={ApplicationFormController}
      />
      <PrivateRoute
        exact
        path="/usuario/faq"
        title={"FAQ"}
        dontShowNavBar={true}
        component={FaqController}
      />
      <PrivateRoute
        exact
        path="/usuario/selecoes/edital"
        title={"Resultados"}
        component={RankStudentController}
      />
      <PrivateRoute
        exact
        path="/usuario/selecoes/ver/resultados"
        title={"Resultados"}
        component={RankController}
      />
      ;
    </Switch>
  );
};
export const AdminRoutes = () => (
  <Switch>
    <AdminRoute
      exact
      path="/usuario/admin/selecoes/"
      title={"Seleções"}
      component={NoticeController}
    />
    <AdminRoute
      exact
      path="/usuario/admin/editais/"
      title={"Editais"}
      component={NoticesController}
    />

    <AdminRoute
      exact
      path="/usuario/admin/selecoes/ver"
      title={"Seleção"}
      component={SelectionsAdminController}
    />
    <AdminRoute
      exact
      path="/usuario/admin/selecoes/ver/estudanteEntrevista"
      title={"Estudante Entrevista"}
      component={InterviewController}
    />
    <AdminRoute
      exact
      path="/usuario/selecoes/ver/rankeamento"
      title={"Resultados"}
      component={RankController}
    />

  </Switch>
);
