import React from "react";
import { CardMenu, SelectionRow200px } from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import { ParentsAdressTopBoxForm } from "../../Styles";
import Dropdown from "../../../../components/dropdown/Dropdown";

export default function parentsAddress(props) {
  const {
    parentsAddress,
    onChangeParentAddress,
    cepError,
    parentHaveSameCep,
    onChangeParentHaveSameCep,
    booleanDropDown,
    isTrueTxt,
  } = props;
  const cep = parentsAddress.cep;

  return (
    <CardMenu>
      <ParentsAdressTopBoxForm>
        <Dropdown
          placeholder="Seu endereço atual é o mesmo dos pais/responsáveis?"
          options={booleanDropDown}
          value={isTrueTxt(parentHaveSameCep)}
          onChange={onChangeParentHaveSameCep}
        />

        <InputFloat
          label="Cep"
          name="cep"
          value={cep}
          disabled={parentHaveSameCep}
          onChange={onChangeParentAddress}
        />
        <br />
      </ParentsAdressTopBoxForm>
      {!cepError && (
        <>
          <SelectionRow200px>
            <InputFloat
              label="Rua"
              name="street"
              value={parentsAddress.street}
              disabled={parentHaveSameCep}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Bairro"
              name="neighborhood"
              value={parentsAddress.neighborhood}
              disabled={parentHaveSameCep}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Cidade"
              name="city"
              value={parentsAddress.city}
              disabled={parentHaveSameCep}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Estado"
              name="state"
              value={parentsAddress.state}
              disabled={parentHaveSameCep}
              onChange={onChangeParentAddress}
            />
          </SelectionRow200px>
          <SelectionRow200px>
            <InputFloat
              label="Número"
              name="number"
              disabled={parentHaveSameCep}
              value={parentsAddress.number}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Ponto de referência"
              disabled={parentHaveSameCep}
              name="referencePoint"
              value={parentsAddress.referencePoint}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Nome do responsável"
              name="parent-name"
              value={parentsAddress.parent.name}
              onChange={onChangeParentAddress}
            />
            <InputFloat
              label="Telefone do responsável"
              name="parent-phone"
              value={parentsAddress.parent.phone}
              onChange={onChangeParentAddress}
            />
          </SelectionRow200px>
        </>
      )}
    </CardMenu>
  );
}
