const mainProviderEmploymentList = [
	{ value: 60, option: 'Desempregado' },
	{ value: 48, option: 'Trabalhador autônomo eventual' },
	{ value: 36, option: 'Trabalhador autônomo não-eventual' },
	{ value: 30, option: 'Trabalhador intermitente' },
	{ value: 24, option: 'Trabalhador temporário' },
	{ value: 12, option: 'Trabalhador por tempo determinado' },
	{ value: 6, option: 'Trabalhador parcial' },
	{ value: 0, option: 'Não se aplica' }
];

const incomeList = [
	{ value: 50, option: 'Doações' },
	{ value: 45, option: 'Aviso prévio ou seguro-desemprego' },
	{ value: 40, option: 'Participação em Programas de Transferência de Renda ( Federais, Estaduais e Municipais)' },
	{ value: 35, option: `Agricultura familiar/pesca artesanal ou trabalho eventual (“bicos”)` },
	{ value: 30, option: 'Renda de pensão alimentícia' },
	{ value: 25, option: 'Bolsas de estudo ou Jovem aprendiz' },
	{ value: 20, option: 'Comércio ambulante/Serviços autônomos mais vulneráveis' },
	{ value: 15, option: 'Pequenos comércios' },
	{ value: 0, option: 'Não se aplica' }
];
const displacementList = [
	{ value: 40, option: 'Sem possibilidade de deslocamento diário', },
	{ value: 36, option: 'Com possibilidade de deslocamento-dificuldade de acesso ao transporte', },
	{ value: 24, option: 'Com possibilidade de deslocamento-transporte pago',	},
	{ value: 0, option: 'Não se aplica'	}
];
const familyList = [
	{ value: 50, option: 'Família monoparental/Anaparental/Unipessoal/Uniparental sem pensão correlata' },
	{ value: 30, option: 'Família Monoparental/ Anaparental/Unipessoal/Uniparental com pensão' },
	{ value: 20, option: 'Família extensa' },
	{ value: 0, option: 'Não se aplica' }
];
const homeFamilyList = [
	{ value: 40, option: 'Ocupação Urbana/Rural-Aldeia/Quilombo' },
	{ value: 36, option: 'Moradia cedida (Favor)' },
	{ value: 32, option: 'Moradia alugada' },
	{ value: 24, option: 'Moradia financiada' },
	{ value: 0, option: 'Não se aplica' }
];
const homeStudentList = [
	{ value: 40, option: 'Situação de rua ou ocupação' },
	{ value: 36, option: 'Moradia cedida (Favor)' },
	{ value: 24, option: 'Moradia alugada' },
	{ value: 16, option: 'Pensionato/República' },
	{ value: 0, option: 'Não se aplica' }
];
const healthList = [
	{ value: 50, option: 'Sem plano de saúde e com doença ou PCD' },
	{ value: 30, option: 'Com plano privado de saúde e com doença ou PCD' },
	{ value: 15, option: 'SUS sem doença' },
	{ value: 0, option: 'Não se aplica' }
];

const schoolingMainProviderList = [
	{ value: 30, option: 'Não alfabetizado' },
	{ value: 21, option: 'Ensino fundamental incompleto' },
	{ value: 18, option: 'Fundamental completo' },
	{ value: 15, option: 'Ensino médio incompleto' },
	{ value: 12, option: 'Ensino médio completo' },
	{ value: 0, option: 'Não se aplica' },
];

const relationLinkList = [
	{ value: 60, option: 'Vínculos afetivos familiares rompidos' },
	{ value: 42, option: 'Vínculos afetivos familiares fragilizados' },
	{ value: 0, option: 'Não se aplica' },
];

const localizationOriginList = [
	{ value: 30, option: 'Zona Rural' },
	{ value: 24, option: 'Zona Urbana- Áreas de risco' },
	{ value: 0, option: 'Não se aplica' }
];
const aggravatingGerationList = [
	{ value: 30, option: 'Crianças (até 6 anos de idade)' },
	{ value: 24, option: 'Idosos (Acima 60 anos)' },
	{ value: 0, option: 'Não se aplica' }
];
const aggravatingLinkedTicsList = [
	{ value: 30, option: 'Não possui equipamento/rede de internet adequados para realizar às atividades remotas.' },
	{ value: 24, option: 'Possui rede de internet, mas sem equipamento adequado para realizar às atividades remotas.' },
	{ value: 21, option: 'Possui equipamento, mas sem acesso à rede de internet de qualidade para acesso às atividades remotas.' },
	{ value: 0, option: 'Não se aplica' }
];
const aggravatingLinkedLifeAcademicList = [
	{ value: 30, option: 'Estudante trabalhador-provedor financeiro do grupo familiar' },
	{ value: 24, option: 'Estudante com filhos ou gestante' },
	{ value: 18, option: 'Estudante trabalhador não provedor' },
	{ value: 15, option: 'Estudante com turno integral' },
	{ value: 12, option: 'Outro/a membro do grupo universitário/a' },
	{ value: 0, option: 'Não se aplica' }
];
export const lists = [
	{
		id: 'mainProviderEmployment',
		value: mainProviderEmploymentList,
		title: 'SITUAÇÃO DE OCUPAÇÃO/EMPREGO - RESPONSÁVEL PELO GRUPO FAMILIAR'
	},
	{ id: 'income', value: incomeList, title: 'ORIGEM DA RENDA - PRINCIPAL RENDA DO GRUPO' },
	{
		id: 'homeFamily',
		value: homeFamilyList,
		title: 'CONDIÇÕES DE MORADIA DA FAMÍLIA',
	},
	{ id: 'family', value: familyList, title: 'COMPOSIÇÃO FAMILIAR' },
	{
		id: 'homeStudent',
		value: homeStudentList,
		title: 'CONDIÇÕES DE MORADIA DO ESTUDANTE',
	},
	{ id: 'health', value: healthList, title: 'CONDIÇÕES DE ACESSO À SAÚDE' },
	{
		id: 'relationLink',
		value: relationLinkList,
		title: 'VÍNCULOS RELACIONAIS',
	},
	{
		id: 'localizationOrigin',
		value: localizationOriginList,
		title: 'LOCALIZAÇÃO DA MORADIA DE ORIGEM',
	},
	{
		id: 'displacement',
		value: displacementList,
		title: 'CONDIÇÕES DE MOBILIDADE DIÁRIA',
	},
	{
		id: 'aggravatingGeration',
		value: aggravatingGerationList,
		title: 'AGRAVANTES "GERACIONAIS"',
	},
	{
		id: 'aggravatingLinkedTics',
		value: aggravatingLinkedTicsList,
		title: 'AGRAVANTES VINCULADOS AO ACESSO ÀS TICS',
	},
	{
		id: 'schoolingMainProvider',
		value: schoolingMainProviderList,
		title: 'ESCOLARIDADE - PRINCIPAL PROVEDOR'
	},
	{
		id: 'aggravatingLinkedLifeAcademic',
		value: aggravatingLinkedLifeAcademicList,
		title: 'AGRAVANTES VINCULADOS À VIDA ACADÊMICA',
	},
];