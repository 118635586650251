import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2em;
  width: 100%;
  padding: 1em;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    color: #2e82c8;
    white-space: nowrap;
    padding-right: 0.5em;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #2e82c8;
`;
