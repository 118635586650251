export const numberRepresentation = (value, digits = 2) => {
  let aux = convertNumber(value);
  if (validateNumber(aux)) {
    const formato = {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    };
    aux = aux.toLocaleString("pt-BR", formato);
  } else {
    aux = "";
  }
  return aux;
};

export const convertSendNumber = (value) => {
  let aux = convertNumber(value);
  if (!validateNumber(aux)) {
    aux = 0;
  }
  return aux;
};

export const convertNumber = (value) => {
  let aux = value;
  if (
    typeof aux !== "number" &&
    aux !== null &&
    aux !== undefined &&
    aux.includes(",")
  ) {
    aux = aux.replace(".", "").replace(",", ".");
  }

  return parseFloat(aux);
};

export const validateNumber = (value) => {
  let auxValue = convertNumber(value);
  if (!isNaN(auxValue) && typeof auxValue === "number") {
    return true;
  } else {
    return false;
  }
};
