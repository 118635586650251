import React, { Component } from "react";
import StudentView from "../../view/student/StudentView";
import StudentApi from "../../services/StudentApi";
import { Growl } from "primereact/growl";
import Loader from "../../components/loader/Loder";

export default class StudentController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: "",
      _id: "",
      fatherName: "",
      motherName: "",
      course: "",
      campus: "",
      round: "",
      originCity: "",
      originCountry: "",
      highSchoolType: "",
      entryForm: "",
      otherGraduate: "",
      entryYear: "",
      entryFirstYear: "",
      cellphoneNumber: "",
      cra: 0,
      color: "",
      disapprovalsNumber: "",
      maritalStatus: "",
      sex: "",
      distanceToUniversity: "",
      montlyIncome: "",
      scholarship: {
        value: 0,
        typeScholarship: "",
        option: false,
      },
      cep: "",
      familySize: "",
      perCapitaIncome: "",
      grossIncome: "",
      currentTab: 0,
      street: "",
      neighborhood: "",
      disabilities: [],
    };
  }

  goBack = () => this.props.history.goBack();
  componentDidMount() {
    StudentApi.getData().then((res) => {
      const message = res.data.message;

      this.setState({
        ...message,
        montlyIncome: message.income.montlyIncome,
        scholarship: message.income.scholarship,
        perCapitaIncome: message.income.perCapitaIncome,
        grossIncome: message.income.grossIncome,
        familySize: message.income.familySize,
        ...message.address,
        currentTab: 0,
        loading: false,
      });
    });
  }

  onChange = (e) => {
    const name = e.target.name;

    if (name === undefined || name === null) this.setState({ [name]: "" });
    else this.setState({ [name]: e.target.value });
  };
  onChangeScholarshipType = (e) => {
    const { value } = this.state.scholarship;

    const typeScholarship = e.target.value;
    const option = typeScholarship !== "" && value !== "";

    this.setState({
      scholarship: {
        typeScholarship: typeScholarship,
        option,
        value,
      },
    });
  };

  onChangeScholarshipValue = (e) => {
    const { typeScholarship } = this.state.scholarship;
    const value = e.target.value;
    const option = typeScholarship !== "" && value !== "";
    if (option || value === "") {
      this.setState({
        scholarship: {
          typeScholarship,
          option: option,
          value: value,
        },
      });
    }
  };

  render() {
    const {
      name,
      _id,
      fatherName,
      motherName,
      course,
      campus,
      round,
      originCity,
      originCountry,
      highSchoolType,
      entryForm,
      entryYear,
      cellphoneNumber,
      cra,
      color,
      disapprovalsNumber,
      maritalStatus,
      sex,
      disabilities,
      distanceToUniversity,
      montlyIncome,
      scholarship,
      cep,
      familySize,
      perCapitaIncome,
      grossIncome,
      onEdit,
      currentTab,
      neighborhood,
      street,
      loading,
    } = this.state;

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        {loading ? (
          <Loader start={loading} />
        ) : (
          <StudentView
            name={name}
            register={_id}
            fatherName={fatherName}
            motherName={motherName}
            course={course}
            neighborhood={neighborhood}
            street={street}
            campus={campus}
            round={round}
            originCity={originCity}
            originCountry={originCountry}
            highSchoolType={highSchoolType}
            entryForm={entryForm}
            entryYear={entryYear}
            cellphoneNumber={cellphoneNumber}
            cra={cra}
            color={color}
            disapprovalsNumber={disapprovalsNumber}
            maritalStatus={maritalStatus}
            sex={sex}
            disabilities={disabilities.reduce((acc, cur) => {
              acc += cur;
              return acc;
            }, "")}
            distanceToUniversity={distanceToUniversity}
            montlyIncome={montlyIncome}
            scholarship={scholarship}
            cep={cep}
            familySize={familySize}
            perCapitaIncome={perCapitaIncome}
            grossIncome={grossIncome}
            onEdit={onEdit}
            changeTab={this.onChangeCurrentTab}
            currentTab={currentTab}
            editModeOn={this.editModeOn}
            handleSubmit={this.handleSubmit}
            cancelUpdate={this.cancelUpdate}
            onChange={this.onChange}
            onChangeScholarshipOption={this.onChangeScholarshipOption}
            onChangeScholarshipType={this.onChangeScholarshipType}
            onChangeScholarshipValue={this.onChangeScholarshipValue}
            goBack={this.goBack}
          />
        )}
      </>
    );
  }
}
