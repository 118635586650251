import { TextareaAutosize } from "@material-ui/core";
import { RadioButton } from "primereact/radiobutton";
import React from "react";
import InputFloat from "../../../../components/InputFloat";
import { ButtonPrimary } from "../../../../components/buttonDefault/Button";
import InterviewModal from "./InterviewModal";
import Identification from "./InterviewStudentIdentification";
import InterviewTable from "./InterviewTable";
import {
  AreaContainer,
  ButtonSpace,
  ButtonsSpaceDeffered,
  ButtonsSpaceSelectionContainer,
  CardContainer,
  GridDataTables,
  OtherAggravatingContainer,
  TitleSelectionRow,
  UltraContainer,
  UltraContainerItem,
} from "./Styles";

import { OTHER_AGGRAVANTING_LIMIT } from "../../../../utils/constants";

const InterviewView = (props) => {
  const {
    onChangeOtherAggravatingRadio,
    lists,
    typeSelection,
    applicationForm,
    studentData,
    totalAggravating,
    comments,
    deferment,
    actionTemplate,
    isTrueTxt,
    onChangeComents,
    handlerSubmit,
    modalStatus,
    closeModal,
    goBack,
    edit,
    distance,
    distanceFromHomeToCampus,
    handleInputChange,
    handleCalculateDistance,
    handleCalculateDistanceToCampus,
    isCalculatingDistance,
    onChangeStudentData,
    onChangeDeffered,
    aggravating,
    activeOtherAggravating,
    displacementConditions,
    onChangeDisplacementConditions,
  } = props;

  const totalAggravatingValue = totalAggravating >= 0 ? totalAggravating : "";

  const dd = deferment.reduce((acc, { _id, deferred }) => {
    acc[_id] = deferred;
    return acc;
  }, {});

  return (
    <>
      <InterviewModal
        modalStatus={modalStatus}
        edit={edit}
        closeModal={closeModal}
        goBack={goBack}
      />
      <UltraContainer>
        <UltraContainerItem>
          <Identification
            identification={applicationForm.identification}
            studentData={studentData}
            isTrueTxt={isTrueTxt}
            distance={distance}
            onChangeDistance={handleInputChange}
            handleCalculateDistance={handleCalculateDistance}
            distanceFromHomeToCampus={distanceFromHomeToCampus}
            onChangeDistanceToCampus={handleInputChange}
            handleCalculateDistanceToCampus={handleCalculateDistanceToCampus}
            isCalculatingDistance={isCalculatingDistance}
            onChangeStudentData={onChangeStudentData}
            displacementConditions={displacementConditions}
            onChangeDisplacementConditions={onChangeDisplacementConditions}
          />
        </UltraContainerItem>
        <UltraContainerItem>
          <TitleSelectionRow middleTitle>
            <p>Agravantes</p>
          </TitleSelectionRow>

          <GridDataTables>
            <InterviewTable
              actionTemplate={actionTemplate}
              lists={lists}
              thisView={this}
            />
          </GridDataTables>
          <TitleSelectionRow middleTitle>
            <p>Outros Agravantes - <small>Pontuar situações atípicas e relevantes - Máximo de {OTHER_AGGRAVANTING_LIMIT} pontos</small></p>
          </TitleSelectionRow>
          <OtherAggravatingContainer>
            <RadioButton
              checked={activeOtherAggravating}
              name="radio-button"
              onChange={onChangeOtherAggravatingRadio}
            ></RadioButton>
            <InputFloat
              label="VD"
              name="value"
              disabled={!activeOtherAggravating}
              value={!!aggravating.otherAggravating.value ? aggravating.otherAggravating.value : ""}
              onChange={onChangeOtherAggravatingRadio}
              helperText={parseInt(aggravating.otherAggravating.value) > OTHER_AGGRAVANTING_LIMIT ? `Não pode ser superior a ${OTHER_AGGRAVANTING_LIMIT}` : ""}
            />
            <InputFloat
              label="Agravantes"
              name="option"
              disabled={!activeOtherAggravating}
              value={!!aggravating.otherAggravating.option ? aggravating.otherAggravating.option : ""}
              onChange={onChangeOtherAggravatingRadio}
            />
          </OtherAggravatingContainer>
          <div style={{ padding: "15px" }}>
            <InputFloat
              fullWidth
              label="SOMA TOTAL DOS VALORES DE DESEMPENHO"
              disabled={true}
              value={String(totalAggravatingValue)}
              onChange={() => {}}
            />
          </div>
        </UltraContainerItem>
        <UltraContainerItem>
          <TitleSelectionRow middleTitle>
            <p>OBSERVAÇÕES</p>
          </TitleSelectionRow>

          <CardContainer>
            <AreaContainer>
              <TextareaAutosize
                placeholder="Especifique"
                onChange={onChangeComents}
                value={comments[0]}
              />
            </AreaContainer>
          </CardContainer>
        </UltraContainerItem>
        <br />
        <UltraContainerItem>
          <TitleSelectionRow>
            <p>DEFERIMENTO </p>
          </TitleSelectionRow>

          <ButtonsSpaceSelectionContainer>
            {typeSelection.map(({ _id, typeSelection }) => (
              <ButtonsSpaceDeffered key={Math.random()}>
                <p>{typeSelection}</p>
                <RadioButton
                  checked={dd[_id]}
                  onChange={(e) => onChangeDeffered(_id, e.checked)}
                ></RadioButton>
              </ButtonsSpaceDeffered>
            ))}
          </ButtonsSpaceSelectionContainer>
        </UltraContainerItem>
        <ButtonSpace>
          <ButtonPrimary small onClick={handlerSubmit}>
            {edit ? "Atualizar" : "Enviar"}
          </ButtonPrimary>
        </ButtonSpace>
      </UltraContainer>
    </>
  );
};
export default InterviewView;
