export const getAuthenticate = () => {
  let user = localStorage.getItem("tokenzao");

  if (user) {
    return true;
  } else {
    return false;
  }
};

export const signout = () => {
  localStorage.removeItem("tokenzao");
  localStorage.removeItem("5bef");
  localStorage.removeItem("nomezao");
};

export const isAdmin = () => {
  let admin = localStorage.getItem("5bef");
  return admin === "true";
};

export const authenticate = (token, isAdmin, name, applicationForm) => {
  localStorage.setItem("tokenzao", token);
  localStorage.setItem("5bef", isAdmin);
  localStorage.setItem("nomezao", name);
};
export const getToken = () => {
  return localStorage.getItem("tokenzao");
};
