export const STUDENT_ADM_RANK_COLUMNS = [
  "rank",
  "nome",
  "matricula",
  "sexo",
  "pcd",
  "fluxoLiquido",
  "observacoes",
];

export const STUDENT_RANK_COLUMNS = ['nome', 'matricula', 'sexo', 'observacoes']

export const OTHER_AGGRAVANTING_LIMIT = 40;
