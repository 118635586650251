import styled from "styled-components";

export const ImageContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  padding: 10px;
  grid-auto-flow: column;
  background: url(${(props) => props.backgroundImage});
  background-size: cover;
  align-items: center;
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const Title = styled.div`
  font-size: 2em;
  font-weight: 500;
  color: #2e83c6;
`;

export const Container = styled.div`
  display: flex;
  width:100%;
  flex-direction: column;
  background: rgb(255, 255, 255);
  justify-content: center;
  position: relative;
  align-items: center;
  @media (max-width:500px){
    margin-top:2em;
  }
  margin-top: 1em;
`;
export const CardContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit,3em);
  width: 100%;
  height: 100%;
  max-width: 30em;
  max-height: 20em;
  gap:1em;
  justify-items: center;
  align-items: center;
  padding: 30px;
  background: #F3F3F3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius:  2em;
  margin: 2em 0em;
  @media (max-width:500px){
    background: none;
    border:none;
    box-shadow: none;
  }
`;
export const RegisterCardContainer = styled.div`
  display: grid;
  align-items: center;
  padding: 50px;
  width: 100%;
  margin: auto auto auto;
  text-align: center;
  align-items: center;
  border: none;
  max-width: 1000px;
  min-height: 700px;
  @media (max-width: 720px) {
    padding: 7px;
  }
  height: 100%;
`;
export const CardLoginRow = styled.div`
  display: grid;
  padding: 10px 150px;
  justify-items: center;
`;
export const ButtonsSpace = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;
export const SpaceButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-height: 100px;
  p {
    font-size: 14px;
    color: gray;
    margin-bottom: 10px;
  }
`;

export const SelectionRow200px = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 200px));
  margin-top: 1em;
  gap: 16px;
  justify-items: center;
`;

export const CardMenu = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #fff;
  padding: 20px;
  border: 3px solid #2e82c8;
  border-radius: 30px;
  min-height: 250px;
  @media (max-width: 720px) {
    border-radius: 20px;
  }
  ${(props) => props.middleCard && `border-radius: 0px;`}
  &:after,:before {
    bottom: 100%;
    ${(props) =>
    props.currentTab === 0
      ? `left: 11.5%; `
      : props.currentTab === 50
        ? `left: 37%; `
        : props.currentTab === 75
          ? `left: 62%; `
          : `left: 87.5%; `}
    @media(max-width:720px) {
      ${(props) =>
    props.currentTab === 0
      ? `left:12%;`
      : props.currentTab === 50
        ? `left:37%;`
        : props.currentTab === 75
          ? `left:62%;`
          : `left:88%;`}
    }
    transition: all 1s;

    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 25px;
    margin-left: -25px;
  }
  &:before {
    border-color: rgba(46, 130, 200, 0);
    border-bottom-color: #2e82c8;
    border-width: 30px;
    margin-left: -30px;
  }
`;
export const UltraContainer = styled.div`
  display: grid;
  padding: 16px;
  ${(props) => props.paddingTopBottom && `padding:0px 16px; `}
  ${(props) => props.paddingBottom && `padding:16px 16px 0px; `}
`;
export const TriangleContainer = styled.div`
  display: flex;
`;
export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 25px solid #5386e4;
  background: white;
  ${(props) =>
    props.currentTab === 0
      ? `left:9%;`
      : props.currentTab === 50
        ? `left:34%;`
        : props.currentTab === 75
          ? `left:59%;`
          : `left:84%;`}
  @media(max-width:720px) {
    ${(props) =>
    props.currentTab === 0
      ? `left:8.5%;`
      : props.currentTab === 50
        ? `left:33.5%;`
        : props.currentTab === 75
          ? `left:58.5%;`
          : `left:83.5%;`}
  }
`;
export const RegisterButton = styled.div`
  display: flex;
  align-items: flex-start;
  p {
    color: #0070c9;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    padding: 0;
    margin-left: 2px;
    vertical-align: inherit;
    cursor: pointer;
  }
`;
export const LogosContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;

  p {
    color: #ffff;
  }
`;
export const LogosSpace = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 200px));
  grid-template-rows: 100px;
  margin-top: 1em;
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 10px;
  img {
    height: 100px;
  }
`;
export const SplashInside = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1200px) {
    display: none;
    pointer-events: none;
    height: 0px;
  }
`;
export const SplashContainer = styled.div`
  width: 200px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 100%;
  max-height: 165px;
  @media (max-width: 1200px) {
    display: none;
    pointer-events: none;
    height: 0px;
  }
`;
export const BlueLogoAbsContainer = styled.div`
  width: 200px;
  position: fixed;
  top: 30px;
  left: 20px;
  height: 100%;
  max-height: 165px;
  @media (max-width: 1200px) {
    display: none;
    pointer-events: none;
    height: 0px;
  }
`;

export const BlueLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 1200px) {
    display: none;
  }
`;
export const BlueLogosContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  p {
    color: #5386e4;
  }
`;
export const BlueLogosSpace = styled.div`
  display: grid;
  width: 100%;
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-bottom: 0px;
  padding: 10px;
  grid-template-columns: repeat(auto-fit, minmax(20px, 200px));
  img {
    height: 100px;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const Stage1FirstRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 20px;
  justify-items: center;

  @media (min-width: 1200px) {
    max-width: 40vw;
  }
`;
export const Stage1SecondRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 20px;
  justify-items: center;
`;
export const Stage2FirstRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 10px;
  justify-items: center;
  align-items: center;
`;
export const Stage2SecondRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 10px;
  justify-items: center;
`;
export const Stage4Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1em;
  gap: 40px;
  justify-items: center;
`;
export const TitleSelectionRow75 = styled.div`
  color: #3880bf;
  font-size: 20px;
  font-weight: 600;
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const LGPDContainer = styled.div`
  display:flex;
  max-width:330px;
  width:100%;
  padding:12px;
  background: #FFF1E1;
  border-radius:5px; 
  margin-top:30px;
  padding: 5px;
  label {
    font-size: 14px;
    color: #FB8C00;
  }
  svg {
    fill: #FB8C00;
    width: 50px;
    height: 30px;
    margin: 2px 6px 3px 6px;
    transition: all 0.4s;
  }
`
