import React from "react";
import { CardMenu, SelectionRow300px } from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import Dropdown from "../../../../components/dropdown/Dropdown";

export default function familyIncomeInfoCard(props) {
  const { familyIncomeInfo, familyIncomeInfoDropdowns, familyIncomeInfoCallbacks } = props;

  return (
    <CardMenu>
      <SelectionRow300px>
        <InputFloat
          label="Quem é o(a) principal  provedor(a) do Núcleo familiar?"
          value={familyIncomeInfo.mainProvider}
          onChange={familyIncomeInfoCallbacks.onChangeMainProvider}
        />
        <InputFloat
          label="Que tipo de trabalho realiza?"
          value={familyIncomeInfo.workType}
          onChange={familyIncomeInfoCallbacks.onChangeWorkType}
        />
        <Dropdown
          placeholder="Que tipo de vínculo empregatício possui?"
          value={familyIncomeInfo.employmentRelationship}
          options={familyIncomeInfoDropdowns.employmentRelationshipOptions}
          onChange={familyIncomeInfoCallbacks.onChangeEmploymentRelationship}
        />
      </SelectionRow300px>
    </CardMenu>
  );
}
