import moment from "moment";
import { Growl } from "primereact/growl";
import React, { Component } from "react";
import { ButtonPrimary } from "../../components/buttonDefault/Button";
import Loader from "../../components/loader/Loder";
import AplicationFormApi from "../../services/AplicationFormApi";
import ADMFormApi from "../../services/SelectionAdminApi";
import StudentApi from "../../services/StudentApi";
import {
  cpfFormatMask,
  phoneFormatMask,
  zipCodeFormatMask,
} from "../../utils/MaskFunctions";
import StudentFormView from "../../view/selections/student/StudentFormView";
import { convert } from "../../utils/MaskFunctions";

export default class SelectionController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admMode: !!this.props.applicationForm,
      originalData: this.props.originalData,
      checkpoint: 1,
      firstTimeStatus: true,
      edit: false,
      loading: true,
      page: 0,
      studentCepError: false,
      parentsCepError: true,
      parentHaveSameCep: false,
      modalStatus: false,
      sucessMessage: "",
      identification: {
        eadMode: {
          haveEquipment: false,
          haveInternetAccess: false,
        },
        financialIndependence: false,
        name: "",
        socialName: "",
        course: "",
        round: "",
        sex: "",
        genderIdentity: undefined,
        sexualOrientation: undefined,
        rg: "",
        cpf: "",
        birthday: "",
        age: 0,
        email: "",
        phone: "",
        maritalStatus: "",
        color: "",
        ethnicRacialBelonging: undefined,
        elementarySchoolType: "",
        highSchoolType: "",
        otherGraduate: {
          option: false,
        },
        someoneFamilyGraduate: "",
        disabilities: {
          option: false,
        },
        scholarship: {
          option: true,
          typeScholarship: "",
          value: "",
        },
        pracUser: {
          option: false,
          typePRAC: "",
        },
        entryForm: {
          option: "",
          typeQuota: "",
        },
      },
      ticInformation: {
        equipmentUsing: undefined,
        ticEquipment: undefined,
        ownEquipment: undefined,
        equipmentConditions: undefined,
        internetAccess: undefined,
        internetType: undefined,
      },
      address: {
        street: "",
        cep: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
        referencePoint: "",
      },
      parentsAddress: {
        street: "",
        cep: "",
        number: "",
        neighborhood: "",
        city: "",
        state: "",
        referencePoint: "",
        parent: {
          name: "",
          phone: "",
        },
      },
      housing: {
        whoWithReside: {
          option: "",
        },
        situation: {
          typeHousing: {
            option: "",
          },
        },
      },
      housingParents: {
        typeHousing: {
          option: "",
        },
        conditions: "",
      },
      monthlyExpenseLocomotion: {
        typeLocomotion: "",
        value: "",
        other: "",
      },
      familiarGroup: [],
      familyIncomeInfo: {
        mainProvider: "",
        workType: "",
        employmentRelationship: "",
      },
      otherIncomeReceived: {
        typeIncome: {
          option: "",
        },
        alimony: {
          option: false,
          fromWho: [],
          typeAlimony: "",
        },
      },
      familyAssets: [],
      familyHealthSituation: {
        services: {
          typeService: "",
          value: "",
        },
        membersChronicIllness: [],
      },

      bankData: {
        bank: "",
        account: "",
        agency: "",
        operation: "",
      },
      rowData: {
        name: { value: "", isEmpty: false },
        cpf: { value: "", isEmpty: false },
        age: { value: "", isEmpty: false },
        kinship: { value: "", isEmpty: false },
        maritalStatus: { value: "", isEmpty: false },
        schooling: { value: "", isEmpty: false },
        profession: { value: "", isEmpty: false },
        grossMonthlyIncome: { value: "", isEmpty: false },
      },
      familyAssetsRowData: {
        description: "",
        city: "",
        value: "",
      },
      fromWhoInput: "",
      familyHealthSituationRowData: {
        name: "",
        typeIllness: "",
      },
    };
  }

  componentDidMount() {
    if (this.state.admMode) {
      const { identification, address, parentsAddress, otherIncomeReceived } =
        this.props.applicationForm;
      //eslint-disable-next-line
      this.props.applicationForm.housingParents.conditions =
        this.props.applicationForm.housingParents.conditions
          .replace(/<[^>]+>/g, "\n")
          //eslint-disable-next-line
          .replace(/\&nbsp;/g, "")
          .replace(/<\/?[^>]+(>|$)/g, "\n");
      const { originalData } = this.state;

      this.formatForm({
        ...this.props.applicationForm,
        identification: {
          ...identification,
          originalData,
          disabilities: !identification.disabilities.option
            ? { option: false }
            : { option: true, types: identification.disabilities.types },
        },
        edit: true,
        loading: false,
        parentHaveSameCep: address.cep === parentsAddress.cep,
        parentsCepError: address.cep !== parentsAddress.cep,
        otherIncomeReceived: {
          ...otherIncomeReceived,
          alimony: otherIncomeReceived.alimony.option
            ? otherIncomeReceived.alimony
            : { option: false },
        },
      });
    } else {
      this.getData();
    }
  }
  formatForm = (form) => {
    const keys = Object.keys(form);

    this.setState({
      ...keys.reduce((acc, key) => {
        acc[key] = this.maskInput(form[key], key, true);
        return acc;
      }, {}),
    });
  };
  handleSubmit = () => {
    const {
      _id,
      identification,
      ticInformation,
      address,
      parentsAddress,
      housing,
      housingParents,
      monthlyExpenseLocomotion,
      familiarGroup,
      familyIncomeInfo,
      otherIncomeReceived,
      familyAssets,
      familyHealthSituation,
      bankData,
      student_id,
    } = this.state;

    let payload = {
      _id,
      identification,
      ticInformation,
      parentsAddress,
      address,
      housingParents,
      housing,
      monthlyExpenseLocomotion,
      familiarGroup,
      familyIncomeInfo,
      otherIncomeReceived,
      familyAssets,
      familyHealthSituation,
      bankData,
      student_id,
    };

    const keys = Object.keys(payload);
    payload = {
      ...keys.reduce((acc, key) => {
        acc[key] = this.maskInput(payload[key], key);
        return acc;
      }, {}),
    }

    this.setState({ loading: true }, () =>
      ADMFormApi.updateStudentAplForm({ applicationFormData: payload }).then(
        (res) =>
          this.setState(
            {
              sucessMessage: res.data.message,
              loading: false,
              modalStatus: true,
            },
            () => this.props.onChangeClearNavbar(false)
          ),
        (err) => {
          this.growl.show({
            severity: "error",
            summary: err.response.data.message,
          });
          this.setState({ loading: false });
        }
      )
    );
  };
  onClickToChangeTab = (index) => {
    this.setState({ page: index });
  };
  getStudentInfo = () => {
    return StudentApi.getData().then(
      (res) => {
        const user = res.data.message;
        const birthdayReverse = user.birthDayDate
          .split("/")
          .reverse()
          .join("/");
        const age = Number(moment().diff(birthdayReverse, "years", false));

        const newData = {
          name: user.name,
          socialName: "",
          course: user.course,
          round: user.round,
          sex: user.sex,
          phone: phoneFormatMask(user.cellphoneNumber),
          maritalStatus: user.maritalStatus,
          color: user.color,
          highSchoolType: user.highSchoolType,
          rg: user.rg,
          cpf: cpfFormatMask(user.cpf),
          birthday: user.birthDayDate,
          email: user.email,
          otherGraduate: { option: user.otherGraduate },
          disabilities:
            user.disabilities.length === 0
              ? { option: false }
              : { option: true, types: user.disabilities },
          age: age,
          elementarySchoolType: "",
          scholarship:
            typeof user.scholarship !== "undefined"
              ? user.scholarship
              : {
                option: false,
                typeScholarship: "",
                value: 0,
              },
          pracUser: {
            option: false,
            typePRAC: "",
          },
          entryForm: {
            option: user.entryForm ? "Cotas" : "Vagas livres",
            typeQuota: user.entryForm,
          },
          address: user.address,
          bankData: {
            ...user.bankData,
            operation: "",
          },
        };
        return newData;
      },
      (err) => {
        this.growl.show({
          severity: "error",
          summary: "Por favor tente mais tarde",
        });
      }
    );
  };
  searchCep = (cep) => {
    return fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then(
        (response) => {
          let { logradouro, bairro, localidade, uf, erro } = response;

          return {
            erro,
            street: logradouro,
            cep: cep,
            number: "",
            neighborhood: bairro,
            city: localidade,
            state: uf,
            referencePoint: "",
          };
        },
        (error) => {
          this.growl.show({
            severity: "error",
            summary: "Erro na busca do cep",
          });
        }
      );
  };
  convertMoney = (value, mask) =>
    convert(value, { toMask: mask }, { unit: "R$" });
  maskInput = (object, type, mask) => {
    if (type === "identification") {
      if (object.scholarship && object.scholarship.value) {
        object.scholarship = {
          ...object.scholarship,
          value: this.convertMoney(object.scholarship.value, mask),
        };
      }
    } else if (
      type === "otherIncomeReceived" ||
      type === "housingParents" ||
      type === "monthlyExpenseLocomotion"
    ) {
      if (object.value) object.value = this.convertMoney(object.value, mask);
    } else if (type === "housing") {
      if (object.situation && object.situation.value)
        object.situation.value = this.convertMoney(
          object.situation.value,
          mask
        );
    } else if (type === "familyHealthSituation") {
      if (
        object.services &&
        (object.services.value || object.services.value === 0)
      )
        object.services.value = this.convertMoney(object.services.value, mask);
    } else if (type === "familyAssets") {
      if (object) {
        object = object.map((data) => ({
          ...data,
          value: this.convertMoney(data.value, mask),
        }));
      }
    } else if (type === "familiarGroup") {
      if (object) {
        object = object.map((data) => ({
          ...data,
          grossMonthlyIncome: this.convertMoney(data.grossMonthlyIncome, mask),
        }));
      }
    }
    return object;
  };
  updatePartOfForm = (page, propToUpdate) => {
    let data =
      propToUpdate === "familiarGroup" || propToUpdate === "familyAssets"
        ? this.state[propToUpdate]
        : { ...this.state[propToUpdate] };

    if (propToUpdate === "familiarGroup") {
      if (
        this.state.familiarGroup.filter(
          (familiar) => familiar.cpf === this.state.identification.cpf
        ).length === 0
      ) {
        this.growl.show({
          severity: "error",
          summary: "Você deve enviar ao menos se incluir no grupo familiar.",
        });
        return;
      }
    } else if (propToUpdate === "housingParents") {
      const housingParentsConditions =
        this.state.housingParents.conditions.replace(/<(?:.|\n)*?>/gm, "");

      if (housingParentsConditions.trim() === "") {
        this.growl.show({
          severity: "error",
          summary: "Você deve indicar as condições de moradia.",
        });
        return;
      }
    }

    if (page === 12) {
      if (!this.verifyBankDataView()) return;
    }
    if (propToUpdate === "identification") {
      ["scholarship", "pracUser"].forEach((value) => {
        if (!data[value].option) {
          data[value] = { option: data[value].option };
        }
      });
    } else if (propToUpdate === "housing") {
      if (!data.situation.dividends) {
        data.situation = {
          dividends: false,
          typeHousing: data.situation.typeHousing,
          value: data.situation.value,
        };
      }
    } else if (propToUpdate === "otherIncomeReceived") {
      if (!data.alimony.option) {
        data.alimony = {
          option: false,
        };
      }
    }

    this.setState({ loading: true }, () =>
      AplicationFormApi.updatePartOfApplicationForm(
        this.maskInput(data, propToUpdate),
        propToUpdate
      ).then(
        () => {
          if (page === 12)
            this.setState(
              {
                sucessMessage: "Formulário concluido",
                loading: false,
                modalStatus: true,
              },
              () => this.props.onChangeClearNavbar(false)
            );
          else
            this.setState({ page: page + 1, loading: false }, () =>
              setTimeout(() => {
                window.scrollTo(
                  0,
                  document.getElementById(page + "").offsetTop - 20
                );
              }, 450)
            );
        },
        (error) => {
          this.setState({ loading: false }, () => {
            if (typeof error.response.data.message === "string")
              this.growl.show({
                severity: "error",
                summary: error.response.data.message,
              });
          });
        }
      )
    );
  };
  nextPage = () => {
    const { page } = this.state;

    const postPagePart = [
      "identification",
      "ticInformation",
      "address",
      "parentsAddress",
      "housing",
      "housingParents",
      "monthlyExpenseLocomotion",
      "familiarGroup",
      "familyIncomeInfo",
      "otherIncomeReceived",
      "familyAssets",
      "familyHealthSituation",
      "bankData",
    ];

    return this.updatePartOfForm(page, postPagePart[page]);
  };

  onChangeFinancialIndependence = (e) => {
    const { identification } = this.state;
    const financialIndependence = e.value === "Sim" ? true : false;
    this.setState({
      identification: { ...identification, financialIndependence },
    });
  };
  studentFamilarRowData = (identification) => ({
    name: {
      value: identification.name.split(" ")[0],
      isEmpty: false,
    },
    cpf: {
      value: cpfFormatMask(identification.cpf),
      isEmpty: false,
    },
    age: {
      value: identification.age,
      isEmpty: false,
    },
    kinship: {
      value: "-",
      isEmpty: false,
    },
    maritalStatus: {
      value: identification.maritalStatus,
      isEmpty: false,
    },
    schooling: {
      value: "-",
      isEmpty: false,
    },
    profession: {
      value: "Estudante",
      isEmpty: false,
    },
    grossMonthlyIncome: {
      value:
        identification.scholarship &&
          identification.scholarship.option &&
          identification.scholarship.value
          ? identification.scholarship.value
          : "0",
      isEmpty: false,
    },
  });
  getData = async () => {
    await this.getStudentInfo();
    this.setState({ loading: true }, () =>
      StudentApi.getLastApplicationForm().then(
        async (res) => {
          let data = [
            "identification",
            "ticInformation",
            "address",
            "parentsAddress",
            "housing",
            "housingParents",
            "monthlyExpenseLocomotion",
            "familiarGroup",
            "familyIncomeInfo",
            "otherIncomeReceived",
            "familyAssets",
            "familyHealthSituation",
            "bankData",
            "checkpoint",
          ].reduce((acc, current) => {
            const data = res.data.message[current];
            if (data !== undefined) {
              if (current === "identification") {
                data.disabilities = data.disabilities.option
                  ? { option: true, types: data.disabilities.types }
                  : { option: false };
                acc[current] = data;
              } else if (current === "monthlyExpenseLocomotion") {
                acc[current] = {
                  typeLocomotion: data.typeLocomotion,
                  value: data.value ? data.value : "",
                  other: data.other ? data.other : "",
                };
                if (
                  [
                    "Transporte coletivo",
                    "Transporte locado (Van, ônibus)",
                    "Carro/moto próprio",
                    "Carona",
                    "A pé"
                  ].includes(data.typeLocomotion)
                ) {
                  delete acc[current].other;
                }
              } else if (current === "otherIncomeReceived") {
                acc[current] = {
                  ...data,
                  typeIncome: data.typeIncome
                    ? data.typeIncome
                    : { option: "" },
                  value: data.value ? data.value : "",
                  alimony: data.alimony
                    ? {
                      ...data.alimony,
                      typeAlimony: data.alimony.typeAlimony
                        ? data.alimony.typeAlimony
                        : "",
                      option: data.alimony.option
                        ? data.alimony.option
                        : false,
                    }
                    : {
                      option: false,
                      fromWho: [],
                      typeAlimony: "",
                    },
                };
              } else {
                acc[current] = data;
              }
            }
            return acc;
          }, {});

          if (data.identification && data.address && data.parentsAddress) {
            data.parentHaveSameCep =
              data.address.cep === data.parentsAddress.cep;
            if (data.identification && data.parentsAddress) {
              data.parentsCepError =
                data.address.cep !== data.parentsAddress.cep;
            }
          }

          if (
            !data.bankData ||
            (data.bankData &&
              (data.bankData.bank === "" ||
                data.bankData.account === "" ||
                data.bankData.agency === ""))
          ) {
            const studentBankData = await StudentApi.getData().then((res) => {
              const user = res.data.message;
              return user.bankData;
            });
            data.bankData = studentBankData;
          }
          if (data.housingParents && data.housingParents.conditions) {
            //eslint-disable-next-line
            data.housingParents.conditions = data.housingParents.conditions
              .replace(/<[^>]+>/g, "\n")
              //eslint-disable-next-line
              .replace(/\&nbsp;/g, "")
              .replace(/<\/?[^>]+(>|$)/g, "\n");
          }

          this.formatForm({
            ...data,
            rowData:
              data.familiarGroup.length === 0
                ? this.studentFamilarRowData(data.identification)
                : this.state.rowData,
            loading: false,
            page: data.checkpoint - 1,
            edit: true,
            firstTimeStatus: false,
          });
        },
        () => {
          this.getStudentInfo()
            .then((identification) => {
              this.props.onChangeClearNavbar(true);

              this.formatForm({
                loading: false,
                identification: {
                  ...identification,
                  eadMode: {
                    haveEquipment: false,
                    haveInternetAccess: false,
                  },
                  otherGraduate: { option: false },
                },
                rowData: this.studentFamilarRowData(identification),
                address: identification.address,
                studentCepError: false,
                page: 0,
                bankData: identification.bankData,
              });
            })
            .catch((err) => {
              this.growl.show({
                severity: "error",
                summary:
                  "Servidor da UFCG apresentou instabilidade, tente mais tarde.",
              });
            });
        }
      )
    );
  };

  isTrueTxt = (element) => {
    if (typeof element === "undefined") return "";
    return element ? "Sim" : "Não";
  };
  onChangeTypeLocomotion = (e) => {
    let monthlyExpenseLocomotion = this.state.monthlyExpenseLocomotion;
    monthlyExpenseLocomotion.typeLocomotion = e.value;
    if (e.value === "Carro/moto próprio" || e.value === "Carona" || e.value === "A pé") {
      delete monthlyExpenseLocomotion.other;
      delete monthlyExpenseLocomotion.value;
    } else if (
      [
        "Transporte coletivo",
        "Transporte locado (Van, ônibus)",
        "Carro/moto próprio",
        "Carona",
      ].includes(e.value)
    ) {
      delete monthlyExpenseLocomotion.other;
    } else if (e.value !== "Outro") {
      monthlyExpenseLocomotion.other = "";
    } else if (e.value === "Outro") {
      delete monthlyExpenseLocomotion.value;
    }

    this.setState({ monthlyExpenseLocomotion: monthlyExpenseLocomotion });
  };
  onChangeMonthlyExpenseLocomotion = (e) => {
    let monthlyExpenseLocomotion = { ...this.state.monthlyExpenseLocomotion };
    if (monthlyExpenseLocomotion.typeLocomotion === "Outro") {
      monthlyExpenseLocomotion.other = e.target.value;
      delete monthlyExpenseLocomotion.value;
    } else if (
      monthlyExpenseLocomotion.typeLocomotion === "Transporte coletivo" ||
      monthlyExpenseLocomotion.typeLocomotion ===
      "Transporte locado (Van, ônibus)"
    ) {
      monthlyExpenseLocomotion.value = e.target.value;
      delete monthlyExpenseLocomotion.other;
    }
    this.setState({ monthlyExpenseLocomotion: monthlyExpenseLocomotion });
  };
  onChangeWhoWithReside = (e) => {
    this.setState({
      housing: this.changeHousingData(e.value, "whoWithReside"),
    });
  };
  onChangeWhoWithResideOther = (e) => {
    this.setState({
      housing: this.changeHousingData(e.target.value, "whoWithResideOther"),
    });
  };
  onChangeSituationTypeHousing = (e) => {
    this.setState({ housing: this.changeHousingData(e.value, "typeHousing") });
  };
  onChangeSituationTypeHousingOther = (e) => {
    this.setState({
      housing: this.changeHousingData(e.target.value, "typeHousingOther"),
    });
  };
  onChangeSituationDividends = (e) => {
    this.setState({ housing: this.changeHousingData(e.value, "dividends") });
  };
  onChangeSituationHowManyPersons = (e) => {
    const value = e.target.value;

    this.setState({
      housing: this.changeHousingData(value, "situationHowManyPersons"),
    });
  };

  onChangeSituationOther = (e) => {
    this.setState({
      housing: this.changeHousingData(e.value, "situationOther"),
    });
  };
  onChangeSituationValue = (e) => {
    this.setState({
      housing: this.changeHousingData(e.target.value, "situationValue"),
    });
  };
  onChangeOtherIncomeReceivedTypeIncome = (e) => {
    const value = e.value;
    const { otherIncomeReceived } = this.state;

    this.setState({
      otherIncomeReceived: {
        typeIncome: {
          option: value,
        },
        alimony: otherIncomeReceived.alimony,
      },
    });
  };
  onChangeOtherIncomeReceivedTypes = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    let otherIncomeReceived = this.state.otherIncomeReceived;

    if (type === "other") {
      otherIncomeReceived.typeIncome.other = value;
      delete otherIncomeReceived.value;
    } else {
      otherIncomeReceived.value = value;
    }

    this.setState({ otherIncomeReceived: otherIncomeReceived });
  };
  onChangeOtherIncomeReceivedAlimony = (e) => {
    const value = e.value;
    let otherIncomeReceived = this.state.otherIncomeReceived;
    otherIncomeReceived.alimony.option = value === "Sim" ? true : false;

    this.setState({ otherIncomeReceived: otherIncomeReceived });
  };
  changeHousingData = (value, typeData) => {
    let housingData = this.state.housing;

    if (typeData === "typeHousing") {
      if (value === "Alugada" || value === "Financiada") {
        delete housingData.situation.typeHousing.other;
      } else if (value === "Outro" || value === "Própria") {
        delete housingData.situation.value;
        if (value === "Própria" && housingData.situation.typeHousing.other) {
          delete housingData.situation.typeHousing.other;
        }
      }
      housingData.situation.typeHousing.option = value;
    } else if (typeData === "whoWithReside") {
      if (value === "Sozinho") {
        housingData.situation.dividends = false;
        housingData.situation.howManyPersons = "";
        delete housingData.whoWithReside.other;
      } else if (value !== "Outro") {
        housingData.situation.dividends = true;
        housingData.situation.howManyPersons = "";
        delete housingData.whoWithReside.other;
      }
      housingData.whoWithReside.option = value;
    } else if (typeData === "whoWithResideOther")
      housingData.whoWithReside.other = value;
    else if (typeData === "typeHousingOther")
      housingData.situation.typeHousing.other = value;
    else if (typeData === "situationValue") housingData.situation.value = value;
    else if (typeData === "situationHowManyPersons")
      housingData.situation.howManyPersons = value;
    else if (typeData === "dividends") {
      housingData.situation.dividends = value === "Sim" ? true : false;
      if (!housingData.situation.dividends) {
        delete housingData.situation.howManyPersons;
      }
    } else if (typeData === "situationOther")
      housingData.situation.other = value;

    return housingData;
  };

  onchangeParentsHousingValue = (e) => {
    let parentsHouse = this.state.housingParents;
    parentsHouse.value = e.target.value;
    delete parentsHouse.typeHousing.other;
    this.setState({ housingParents: parentsHouse });
  };
  onChangeParentHaveSameCep = (e) => {
    const dropDownValue = e.value;
    const parentHaveSameCep = dropDownValue === "Sim" ? true : false;

    if (parentHaveSameCep) {
      const { address, parentsAddress } = this.state;

      this.setState({
        parentsAddress: {
          ...address,
          parent: parentsAddress.parent,
        },
        parentHaveSameCep: parentHaveSameCep,
        parentsCepError: false,
      });
    } else {
      const parentsAddress = this.state.parentsAddress;
      const newParentsAdress = {
        ...parentsAddress,
        cep: "",
      };
      this.setState({
        parentsAddress: newParentsAdress,
        parentHaveSameCep: parentHaveSameCep,
        parentsCepError: true,
      });
    }
  };
  onchangeParentsHousingConditions = (e) => {
    let parentsHouse = this.state.housingParents;
    const value = e.target.value;

    parentsHouse.conditions = value;
    this.setState({ housingParents: parentsHouse });
  };
  onchangeParentsHousingTypeHousingOther = (e) => {
    let parentsHouse = this.state.housingParents;
    parentsHouse.typeHousing.other = e.target.value;
    delete parentsHouse.value;

    this.setState({ housingParents: parentsHouse });
  };

  onChangeRowData = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    let rowData = this.state.rowData;

    if (type === "cpf") {
      rowData["cpf"].value = cpfFormatMask(value);
    } else {
      rowData[type].value = value;
    }

    this.setState({ rowData: rowData });
  };
  onChangeKinship = (e) => {
    const value = e.value;
    const rowData = this.state.rowData;

    this.setState({
      rowData: { ...rowData, kinship: { value, isEmpty: false } },
    });
  };
  onChangeMaritalStatus = (e) => {
    const value = e.value;
    const rowData = this.state.rowData;

    this.setState({
      rowData: { ...rowData, maritalStatus: { value, isEmpty: false } },
    });
  };
  onChangeSchooling = (e) => {
    const value = e.value;
    const rowData = this.state.rowData;

    this.setState({
      rowData: { ...rowData, schooling: { value, isEmpty: false } },
    });
  };
  onChangeHousingParents = (e) => {
    let parentsHouse = this.state.housingParents;
    const dropDownValue = e.value;

    if (dropDownValue === "Própria") {
      delete parentsHouse.typeHousing.other;
      delete parentsHouse.value;
    } else if (dropDownValue === "Outro" || dropDownValue === "Cedido")
      delete parentsHouse.value;
    else delete parentsHouse.typeHousing.other;

    parentsHouse.typeHousing.option = dropDownValue;
    this.setState({ housingParents: parentsHouse });
  };

  onchangeOtherGraduateCourse = (e) => {
    let identification = this.state.identification;

    identification.otherGraduate.course = e.target.value;

    this.setState({ identification: identification });
  };
  onChangeIdentificationOption = (e, type, haveOption) => {
    const value = e.label;

    let identification = this.state.identification;

    if (haveOption)
      identification[type].option = value === "Sim" ? true : false;
    else if (
      [
        "elementarySchoolType",
        "ethnicRacialBelonging",
        "sexualOrientation",
        "genderIdentity",
        "highSchoolType",
      ].includes(type)
    ) {
      identification[type] = e.value;
    } else identification[type] = value === "Sim" ? true : false;

    this.setState({ identification: identification });
  };

  onChangeAddress = (e) => {
    const value = e.target.value;
    const type = e.target.name;
    let address = this.state.address;

    address[type] = value;

    this.setState({ address: address });
  };
  onChangeHouse = (e) => {
    const value = e.target.value;
    const type = e.target.name;

    let address = this.state.address;

    Object.keys(address).forEach((key) => {
      if (key === type) address[key] = value;
    });

    this.setState({ address: address });
  };
  onChangeParentAddress = (e) => {
    const value = e.target.value;
    const type = e.target.name;
    let address = this.state.parentsAddress;

    if (type === "cep" && value.length === 9) {
      this.searchCep(value).then((newAddress) => {
        if (newAddress.erro) {
          this.growl.show({
            severity: "error",
            summary: "Informe um cep válido",
          });

          this.setState({
            parentViaCepError: false,
            blockCityState: true,
            parentsAddress: {
              street: "",
              cep: value,
              number: "",
              neighborhood: "",
              city: "",
              state: "",
              referencePoint: "",
              parent: address.parent,
            },
            parentsCepError: true,
          });
        } else {
          this.setState({
            parentsAddress: {
              ...newAddress,
              parent: address.parent,
              cep: value,
            },
            parentsCepError: false,
          });
        }
      });
    } else {
      if (type === "cep") {
        if (value.length < 10) {
          address = {
            street: "",
            cep: zipCodeFormatMask(value),
            "": "",
            neighborhood: "",
            city: "",
            state: "",
            referencePoint: "",
            parent: address.parent,
          };
        }
        this.setState({ parentsAddress: address, parentsCepError: true });
      } else {
        if (type.includes("parent")) {
          const attribute = type.split("-")[1];
          if (attribute === "phone")
            address.parent[attribute] = phoneFormatMask(value);
          else address.parent[attribute] = value;
        } else {
          address[type] = value;
        }

        this.setState({ parentsAddress: address });
      }
    }
  };
  onChangeIdentificationProp = (value, type) => {
    let identification = this.state.identification;

    identification[type] = value;

    this.setState({ identification: identification });
  };
  verifyIdentificationView = () => {
    const identification = this.state.identification;

    if (
      typeof identification.pracUser.option === "undefined" &&
      identification.pracUser.typePRAC.trim() !== ""
    )
      return false;
    else if (!identification.elementarySchoolType) return false;
    else if (typeof identification.otherGraduate.option === "undefined")
      return false;
    else if (
      identification.otherGraduate.option &&
      (typeof identification.otherGraduate.course === "undefined" ||
        identification.otherGraduate.course === "")
    )
      return false;
    else if (identification.rg === "") return false;
    else if (typeof identification.someoneFamilyGraduate === "undefined")
      return false;
    return true;
  };

  verifyAddressView = () => {
    const address = this.state.address;

    return Object.keys(address).reduce((isValid, key) => {
      if (address[key] === "") isValid = false;

      return isValid;
    }, true);
  };
  verifyParentsAddressView = () => {
    const address = this.state.parentsAddress;

    return Object.keys(address).reduce((isValid, key) => {
      if (key === "parent") {
        if (address[key].name === "" || address[key].phone === "")
          isValid = false;
      }
      if (address[key] === "") isValid = false;

      return isValid;
    }, true);
  };
  verifyStudentHousingView = () => {
    const studentHousing = this.state.housing;

    const whoWithResideOption = studentHousing["whoWithReside"].option;
    const whoWithResideOther = studentHousing["whoWithReside"].other;
    const typeHousingOption = studentHousing.situation["typeHousing"].option;
    const situationValue = studentHousing.situation.value;
    const typeHousingOther = studentHousing.situation.typeHousing.other;
    const typeHousingDividends = studentHousing.situation.dividends;
    const typeHousingHowManyPersons = studentHousing.situation.howManyPersons;

    if (
      whoWithResideOption === "" ||
      typeof typeHousingOption === "undefined" ||
      typeof typeHousingDividends === "undefined"
    )
      return false;
    else if (
      whoWithResideOption === "Outro" &&
      (typeof whoWithResideOther === "undefined" || whoWithResideOther === "")
    )
      return false;
    else if (
      whoWithResideOption === "Outro" &&
      (typeof whoWithResideOther === "undefined" || whoWithResideOther === "")
    )
      return false;
    else if (
      typeHousingOption === "Outro" &&
      (typeof typeHousingOther === "undefined" || typeHousingOther === "")
    )
      return false;
    else if (
      (typeHousingOption === "Alugada" || typeHousingOption === "Financiada") &&
      (typeof situationValue === "undefined" || situationValue === "")
    )
      return false;
    else if (
      typeof typeHousingDividends === "undefined" ||
      (typeHousingDividends &&
        (typeof typeHousingHowManyPersons === "undefined" ||
          typeHousingHowManyPersons === ""))
    )
      return false;

    return true;
  };
  verifyParentsHousingView = () => {
    const studentHousing = this.state.housingParents;

    const typeHousingOption = studentHousing.typeHousing.option;
    const conditions = studentHousing.conditions;

    const typeHousingOther = studentHousing.typeHousing.other;
    const value = studentHousing.value;

    if (
      typeof typeHousingOption === "undefined" ||
      typeof conditions === "undefined"
    )
      return false;
    else if (
      typeHousingOption === "Outro" &&
      (typeof typeHousingOther === "undefined" || typeHousingOther === "")
    )
      return false;
    else if (
      (typeHousingOption === "Financiado" || typeHousingOption === "Alugada") &&
      (typeof value === "undefined" || value === "")
    )
      return false;
    else if (conditions === "") return false;

    return true;
  };
  verifyMonthlyExpenseLocomotionView = () => {
    const monthlyExpenseLocomotion = this.state.monthlyExpenseLocomotion;
    const typeLocomotion = monthlyExpenseLocomotion.typeLocomotion;
    const other = monthlyExpenseLocomotion.other;

    if (typeof typeLocomotion === "undefined") return false;
    else if (
      typeLocomotion === "Outro" &&
      (typeof other === "undefined" || other === "")
    )
      return false;

    return true;
  };
  verifyFamilyAssetsView = () => this.state.familyAssets.length > 0;

  verifyOtherIncomeReceivedTypes = () => {
    const otherIncomeReceived = this.state.otherIncomeReceived;
    const typeIncomeOption = otherIncomeReceived.typeIncome.option;
    const typeIncomeOther = otherIncomeReceived.typeIncome.other;
    const value = otherIncomeReceived.value;
    const alimonyOption = otherIncomeReceived.alimony.option;
    const fromWho = otherIncomeReceived.alimony.fromWho;

    if (typeIncomeOption === "" || typeof alimonyOption === "undefined")
      return false;
    else if (
      typeIncomeOption === "Outro" &&
      (typeof typeIncomeOther === "undefined" || typeIncomeOther === "")
    )
      return false;
    else if (
      typeIncomeOption !== "Outro" &&
      typeIncomeOption !== "Não recebo" &&
      (typeof value === "undefined" || value === "")
    )
      return false;
    else if (alimonyOption && fromWho.length === 0) return false;
    return true;
  };
  verifyFamilyHealthSituationView = () => {
    const familyHealthSituation = this.state.familyHealthSituation;

    if (
      familyHealthSituation.services.typeService === "" ||
      familyHealthSituation.services.value === ""
    )
      return false;

    return true;
  };
  verifyBankDataView = () => {
    const bankData = this.state.bankData;
    const isValid =
      Object.keys(bankData).reduce((accumulate, key) => {
        const currentValue = bankData[key];
        if (currentValue === "" && key !== "operation") accumulate += 1;
        return accumulate;
      }, 0) === 0;
    if (!isValid)
      this.growl.show({
        severity: "error",
        summary: "É necessário uma conta bancária para concluir o formulário",
      });
    return isValid;
  };
  onChangeRg = (e) => {
    this.onChangeIdentificationProp(e.target.value, "rg");
  };
  onChangeAge = (e) => {
    this.onChangeIdentificationProp(e.target.value, "age");
  };
  onChangeSocialName = (e) => {
    this.onChangeIdentificationProp(e.target.value, "socialName");
  };
  onChangeSomeoneFamilyGraduate = (e) => {
    this.onChangeIdentificationOption(e, "someoneFamilyGraduate", false);
  };
  onChangeElementarySchoolType = (e) => {
    this.onChangeIdentificationOption(e, "elementarySchoolType", false);
  };
  onChangeEthnicRacialBelongingOptions = (e) => {
    this.onChangeOption(e, "identification", "ethnicRacialBelonging");
  };

  onChangeDisabilities = (e) => {
    this.onChangeIdentificationOption(e, "disabilities", true);

    const { identification } = this.state;

    identification.disabilities = {
      ...identification.disabilities,
      types: []
    }

    this.setState({ identification });
  };

  onChangeDisabilitiesOptions = (e) => {
    const value = e.label;
    const { identification } = this.state;

    const types = [
      { disabilityType: value }
    ]

    identification.disabilities = {
      option: !!value,
      types
    }

    this.setState({ identification });
  }
  onChangeSexualOrientationOptions = (e) => {
    this.onChangeOption(e, "identification", "sexualOrientation");
  };
  onChangeGenderIdentityOptions = (e) => {
    this.onChangeOption(e, "identification", "genderIdentity");
  };
  onChangePracUser = (e) => {
    this.onChangeIdentificationOption(e, "pracUser", true);
  };
  translatePlaceHolder = (placeHolder) => {
    if (placeHolder === "Familiar graduado") return "someoneFamilyGraduate";
  };

  deleteButton = (rowData) => (
    <ButtonPrimary small onClick={() => this.deleteFamiliar(rowData)}>
      Deletar
    </ButtonPrimary>
  );

  addFamilar = () => {
    let familiarGroup = this.state.familiarGroup;
    const rowData = this.state.rowData;

    const haveSameCpf = familiarGroup.reduce((acc, familiar) => {
      if (familiar.cpf === rowData.cpf.value) acc = true;
      return acc;
    }, false);
    if (Number(rowData["age"].value) >= 18) {
      if (haveSameCpf) {
        this.growl.show({
          severity: "error",
          summary: "Já existe um familiar com o mesmo cpf",
        });
      } else if (rowData.cpf.value.trim() === "") {
        this.growl.show({
          severity: "error",
          summary: "Informe o CPF.",
        });
      }
    } else {
      if (rowData.cpf.value.trim() === "") {
        rowData.cpf.value = "-";
      }
    }

    const validate =
      Object.keys(rowData)
        .map((key) => rowData[key].value)
        .reduce((accumulate, currentValue) => {
          if (currentValue === "") accumulate += 1;
          return accumulate;
        }, 0) === 0;
    if (validate) {
      const newLine = Object.keys(rowData).reduce((accumulate, key) => {
        accumulate[key] = rowData[key].value;
        return accumulate;
      }, {});
      familiarGroup.push(newLine);

      this.setState({
        familiarGroup: familiarGroup,
        rowData: this.rowDataEmpty(),
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Por favor insira todos os campos.",
      });
    }
  };
  deleteFamiliar = (rowData) => {
    const familiarGroup = this.state.familiarGroup;
    let stateRowData = this.state.rowData;
    const userCpf = this.state.identification.cpf;
    const newData = familiarGroup.filter(
      (familiar) =>
        Object.keys(familiar).filter((key) => familiar[key] === rowData[key])
          .length < 8
    );

    if (
      userCpf === rowData.cpf ||
      familiarGroup.filter(
        (familiar) => familiar.cpf === this.state.identification.cpf
      ).length === 0
    ) {
      stateRowData = this.studentFamilarRowData(this.state.identification);
    }

    this.setState({ familiarGroup: newData, rowData: stateRowData });
  };

  rowDataEmpty = () => {
    return {
      name: { value: "", isEmpty: false },
      cpf: { value: "", isEmpty: false },
      age: { value: "", isEmpty: false },
      kinship: { value: "", isEmpty: false },
      maritalStatus: { value: "", isEmpty: false },
      schooling: { value: "", isEmpty: false },
      profession: { value: "", isEmpty: false },
      grossMonthlyIncome: { value: "", isEmpty: false },
    };
  };
  onChangeFamilyAssetsRowData = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    let familyAssetsRowData = this.state.familyAssetsRowData;

    familyAssetsRowData[type] = value;

    this.setState({ familyAssetsRowData: familyAssetsRowData });
  };
  familyAssetsRowDataEmpty = () => {
    return {
      description: "",
      city: "",
      value: "",
    };
  };
  addFamilyAssets = () => {
    let familyAssets = this.state.familyAssets;
    const rowData = this.state.familyAssetsRowData;

    const validate =
      Object.keys(rowData).reduce((accumulate, key) => {
        const currentValue = rowData[key];
        if (currentValue === "") accumulate += 1;
        return accumulate;
      }, 0) === 0;

    if (validate) {
      familyAssets.push(rowData);

      this.setState({
        familyAssets: familyAssets,
        familyAssetsRowData: this.familyAssetsRowDataEmpty(),
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Por favor insira todos os campos.",
      });
    }
  };
  deleteFamilyAssets = (rowData) => {
    const familyAssets = this.state.familyAssets;

    const newData = familyAssets.filter(
      (value) =>
        value.description !== rowData.description &&
        value.city !== rowData.city &&
        value.value !== rowData.value
    );

    this.setState({ familyAssets: newData });
  };
  deleteButtonFamilyAssets = (rowData) => {
    return (
      <ButtonPrimary small onClick={() => this.deleteFamilyAssets(rowData)}>
        Deletar
      </ButtonPrimary>
    );
  };
  addOtherIncomeReceivedFromWho = () => {
    const fromWhoInput = this.state.fromWhoInput;

    if (fromWhoInput === "")
      this.growl.show({
        severity: "error",
        summary: "Por favor insira um nome.",
      });
    else {
      let fromWho = this.state.otherIncomeReceived.alimony.fromWho;
      fromWho.push(fromWhoInput);

      let otherIncomeReceived = this.state.otherIncomeReceived;
      otherIncomeReceived.alimony.fromWho = fromWho;

      this.setState({
        otherIncomeReceived: otherIncomeReceived,
        fromWhoInput: "",
      });
    }
  };
  goTo = (path) => this.props.history.push(path);
  deleteOtherIncomeReceivedFromWho = (rowData) => {
    let fromWho = this.state.otherIncomeReceived.alimony.fromWho;
    const newData = fromWho.filter((value) => value !== rowData.name);
    let otherIncomeReceived = this.state.otherIncomeReceived;
    otherIncomeReceived.alimony.fromWho = newData;

    this.setState({ otherIncomeReceived: otherIncomeReceived });
  };
  deleteButtonOtherIncomeReceived = (rowData) => {
    return (
      <ButtonPrimary
        small
        onClick={() => this.deleteOtherIncomeReceivedFromWho(rowData)}
      >
        Deletar
      </ButtonPrimary>
    );
  };
  OnchangeFromWhoInput = (e) => {
    this.setState({ fromWhoInput: e.value });
  };
  onChangeFamilyHealthSituationServicesTypeService = (e) => {
    let familyHealthSituation = this.state.familyHealthSituation;
    const value = e.value;
    if (!familyHealthSituation.services) {
      familyHealthSituation.services = {};
    }

    familyHealthSituation.services.typeService = value;
    this.setState({ familyHealthSituation: familyHealthSituation });
  };
  onChangeFamilyHealthSituationServicesValue = (e) => {
    let familyHealthSituation = this.state.familyHealthSituation;
    const value = e.target.value;

    if (!familyHealthSituation.services) {
      familyHealthSituation.services = {};
    }

    familyHealthSituation.services.value = value;

    this.setState({ familyHealthSituation: familyHealthSituation });
  };
  membersChronicIllnessRowDataEmpty = () => {
    return {
      name: "",
      typeIllness: "",
    };
  };
  addMembersChronicIllness = () => {
    let familyHealthSituation = this.state.familyHealthSituation;

    const rowData = this.state.familyHealthSituationRowData;

    if (rowData.name !== "" && rowData.typeIllness !== "") {
      familyHealthSituation.membersChronicIllness.push(rowData);

      this.setState({
        familyHealthSituationRowData: {
          name: "",
          typeIllness: "",
        },
        familyHealthSituation: familyHealthSituation,
        familyAssetsRowData: this.membersChronicIllnessRowDataEmpty(),
      });
    } else {
      this.growl.show({
        severity: "error",
        summary: "Por favor insira todos os campos.",
      });
    }
  };
  deleteMembersChronicIllness = (rowData) => {
    let familyHealthSituation = this.state.familyHealthSituation;
    let membersChronicIllness = familyHealthSituation.membersChronicIllness;

    const newData = membersChronicIllness.filter((value) => {
      return value.name !== rowData.name;
    });

    familyHealthSituation.membersChronicIllness = newData;

    this.setState({ familyHealthSituation: familyHealthSituation });
  };
  deleteButtonDeleteMembersChronicIllness = (rowData) => {
    return (
      <ButtonPrimary
        micro
        onClick={() => this.deleteMembersChronicIllness(rowData)}
      >
        Deletar
      </ButtonPrimary>
    );
  };
  deleteButtonOtherIncomeReceived = (rowData) => {
    return (
      <ButtonPrimary
        micro
        onClick={() => this.deleteOtherIncomeReceivedFromWho(rowData)}
      >
        Deletar
      </ButtonPrimary>
    );
  };
  onChangeFamilyHealthSituationRowDataTypes = (e) => {
    if (typeof e.target === "undefined") {
      this.setState({
        familyHealthSituationRowData: {
          ...this.state.familyHealthSituationRowData,
          name: e.value,
        },
      });
    } else {
      this.setState({
        familyHealthSituationRowData: {
          ...this.state.familyHealthSituationRowData,
          typeIllness: e.target.value,
        },
      });
    }
  };
  onchangeModalStatus = () =>
    this.setState({ modalStatus: !this.state.modalStatus });
  onChangeBankData = (e) => {
    const type = e.target.name;
    const value = e.target.value;

    let bankData = this.state.bankData;
    bankData[type] = value;

    this.setState({ bankData: bankData });
  };
  onChangeTab = (value) => this.setState({ currentTab: value });
  onChangeTypePrac = (e) => {
    const { identification } = this.state;
    const { pracUser } = identification;

    this.setState({
      identification: {
        ...identification,
        pracUser: { ...pracUser, typePRAC: e.target.value },
      },
    });
  };
  closeFirstTimeStatusModal = () => this.setState({ firstTimeStatus: false });
  onChangeOtherGraduateOption = (e) =>
    this.onChangeIdentificationOption(e, "otherGraduate", true);
  onChangeScholarship = (e) => {
    const { name, value } = e.target
      ? {
        value: e.target.value,
        name: e.target.name,
      }
      : {
        value: e.value === "Sim" ? true : false,
        name: "option",
      };
    const identification = this.state.identification;
    let rowData = this.state.rowData;
    let { scholarship } = identification;
    if (name === "option" && !value) {
      delete scholarship.typeScholarship;
      delete scholarship.value;
      rowData.grossMonthlyIncome = { value: 0, isEmpty: false };
    }
    scholarship[name] = value;

    this.setState({
      identification: {
        ...identification,
        scholarship: scholarship,
      },
    });
  };

  verifyFunctions = (index) => {
    switch (index) {
      case 0:
        return this.verifyIdentificationView();

      case 1:
        return this.verifyAddressView();

      case 2:
        return this.verifyParentsAddressView();

      case 3:
        return this.verifyStudentHousingView();

      case 4:
        return this.verifyParentsHousingView();

      case 5:
        return this.verifyMonthlyExpenseLocomotionView();

      case 7:
        return this.verifyOtherIncomeReceivedTypes();

      case 9:
        return this.verifyFamilyHealthSituationView();

      default:
        return true;
    }
  };
  onChangeTypeAlimony = (e) => {
    const { otherIncomeReceived } = this.state;
    this.setState({
      otherIncomeReceived: {
        ...otherIncomeReceived,
        alimony: {
          ...otherIncomeReceived.alimony,
          typeAlimony: e.target.value,
        },
      },
    });
  };
  onChangeEadMode = (type, value) => {
    const { identification } = this.state;
    const eadMode = { ...identification.eadMode };

    if (type === "haveEquipment" && !value) {
      delete eadMode.equipmentType;
    } else if (type === "haveInternetAccess" && !value) {
      delete eadMode.internetType;
    }

    this.setState({
      identification: {
        ...identification,
        eadMode: {
          ...eadMode,
          [type]: value,
        },
      },
    });
  };

  onChangeOption = (e, prop, type) => {
    let value = e.value;
    const propState = this.state[prop];

    let newProp = { ...propState, [type]: value };

    this.setState({
      [prop]: {
        ...newProp
      }
    });
  };

  onChangeProp = (e, prop, type) => {
    let value = e.target.value;
    const propState = this.state[prop];

    let newProp = { ...propState, [type]: value };

    this.setState({
      [prop]: {
        ...newProp
      }
    });
  };

  onChangeBooleanOption = (e, prop, type, haveOption) => {
    let value = e.value === "Sim" ? true : false;
    const propState = this.state[prop];

    let newProp = haveOption ? { ...propState, [type]: { option: value } } : { ...propState, [type]: value };

    this.setState({
      [prop]: {
        ...newProp
      }
    });
  };

  onChangeEquipmentUsing = (e) => {
    this.onChangeBooleanOption(e, "ticInformation", "equipmentUsing", false);
  };
  onChangeTicEquipment = (e) => {
    this.onChangeOption(e, "ticInformation", "ticEquipment");
  };

  onChangeOwnEquipment = (e) => {
    this.onChangeBooleanOption(e, "ticInformation", "ownEquipment", false);
  };
  onChangeEquipmentConditions = (e) => {
    this.onChangeBooleanOption(e, "ticInformation", "equipmentConditions", false);
  };

  onChangeInternetAccess = (e) => {
    this.onChangeBooleanOption(e, "ticInformation", "internetAccess", false);
  };
  onChangeInternetType = (e) => {
    this.onChangeOption(e, "ticInformation", "internetType");
  };

  ticCallbacks = {
    onChangeEquipmentUsing: this.onChangeEquipmentUsing,
    onChangeTicEquipment: this.onChangeTicEquipment,
    onChangeOwnEquipment: this.onChangeOwnEquipment,
    onChangeEquipmentConditions: this.onChangeEquipmentConditions,
    onChangeInternetAccess: this.onChangeInternetAccess,
    onChangeInternetType: this.onChangeInternetType,
  };

  onChangeMainProvider = (e) => {
    this.onChangeProp(e, "familyIncomeInfo", "mainProvider");
  };

  onChangeWorkType = (e) => {
    this.onChangeProp(e, "familyIncomeInfo", "workType");
  };

  onChangeEmploymentRelationship = (e) => {
    this.onChangeOption(e, "familyIncomeInfo", "employmentRelationship");
  };

  familyIncomeInfoCallbacks = {
    onChangeMainProvider: this.onChangeMainProvider,
    onChangeWorkType: this.onChangeWorkType,
    onChangeEmploymentRelationship: this.onChangeEmploymentRelationship
  }

  onChangeHighSchoolType = (e) => {
    this.onChangeIdentificationOption(e, "highSchoolType", false);
  }

  render() {
    const {
      identification,
      ticInformation,
      address,
      parentsAddress,
      housing,
      housingParents,
      monthlyExpenseLocomotion,
      edit,
      familiarGroup,
      familyAssetsRowData,
      familyIncomeInfo,
      otherIncomeReceived,
      familyAssets,
      familyHealthSituation,
      bankData,
      page,
      rowData,
      parentsCepError,
      studentCepError,
      fromWhoInput,
      familyHealthSituationRowData,
      parentHaveSameCep,
      modalStatus,
      sucessMessage,
      loading,
      firstTimeStatus,
      admMode,
    } = this.state;

    const familyHealthSituationListName =
      familyHealthSituation.membersChronicIllness.map(
        (familiar) => familiar.name
      );
    const familiarGroupListNameFiltered = familiarGroup
      .map((familiar) => familiar.name)
      .filter((familiar) => !familyHealthSituationListName.includes(familiar));

    return (
      <>
        <Growl ref={(el) => (this.growl = el)} />
        {loading ? (
          <Loader start={loading} />
        ) : (
          <StudentFormView
            admMode={admMode}
            onChangeTypeAlimony={this.onChangeTypeAlimony}
            familiarGroupListNameFiltered={familiarGroupListNameFiltered}
            edit={edit}
            identification={identification}
            ticInformation={ticInformation}
            ticCallbacks={this.ticCallbacks}
            familyIncomeInfo={familyIncomeInfo}
            familyIncomeInfoCallbacks={this.familyIncomeInfoCallbacks}
            onChangeIdentificationOption={this.onChangeIdentificationOption}
            onChangeSomeoneFamilyGraduate={this.onChangeSomeoneFamilyGraduate}
            address={address}
            rowData={rowData}
            parentsCepError={parentsCepError}
            studentCepError={studentCepError}
            addFamilar={this.addFamilar}
            familiarGroup={familiarGroup}
            onChangeRowData={this.onChangeRowData}
            isTrueTxt={this.isTrueTxt}
            onChangeParentAddress={this.onChangeParentAddress}
            familyAssetsRowData={familyAssetsRowData}
            onChangeFamilyAssetsRowData={this.onChangeFamilyAssetsRowData}
            OnchangeFromWhoInput={this.OnchangeFromWhoInput}
            fromWhoInput={fromWhoInput}
            addOtherIncomeReceivedFromWho={this.addOtherIncomeReceivedFromWho}
            onChangePracUser={this.onChangePracUser}
            onchangeOtherGraduateCourse={this.onchangeOtherGraduateCourse}
            onChangeElementarySchoolType={this.onChangeElementarySchoolType}
            onChangeEthnicRacialBelongingOptions={
              this.onChangeEthnicRacialBelongingOptions
            }
            onChangeSexualOrientationOptions={
              this.onChangeSexualOrientationOptions
            }
            onChangeGenderIdentityOptions={this.onChangeGenderIdentityOptions}
            onChangeRg={this.onChangeRg}
            onChangeAge={this.onChangeAge}
            onChangeSocialName={this.onChangeSocialName}
            parentsAddress={parentsAddress}
            onChangeAddress={this.onChangeAddress}
            onChangeWhoWithResideOther={this.onChangeWhoWithResideOther}
            onChangeSituationTypeHousingOther={
              this.onChangeSituationTypeHousingOther
            }
            housing={housing}
            onChangeTypeLocomotion={this.onChangeTypeLocomotion}
            onChangeMonthlyExpenseLocomotion={
              this.onChangeMonthlyExpenseLocomotion
            }
            housingParents={housingParents}
            monthlyExpenseLocomotion={monthlyExpenseLocomotion}
            otherIncomeReceived={otherIncomeReceived}
            familyAssets={familyAssets}
            onChangeOtherGraduateOption={this.onChangeOtherGraduateOption}
            familyHealthSituation={familyHealthSituation}
            onChangeSituationHowManyPersons={
              this.onChangeSituationHowManyPersons
            }
            onChangeSituationOther={this.onChangeSituationOther}
            onChangeSituationValue={this.onChangeSituationValue}
            actionTemplate={this.actionTemplate}
            onChangeOtherIncomeReceivedTypes={
              this.onChangeOtherIncomeReceivedTypes
            }
            onChangeSituationDividends={this.onChangeSituationDividends}
            onChangeWhoWithReside={this.onChangeWhoWithReside}
            onChangeSituationTypeHousing={this.onChangeSituationTypeHousing}
            onChangeHousingParents={this.onChangeHousingParents}
            onChangeOtherIncomeReceivedAlimony={
              this.onChangeOtherIncomeReceivedAlimony
            }
            onChangeOtherIncomeReceivedTypeIncome={
              this.onChangeOtherIncomeReceivedTypeIncome
            }
            onchangeParentsHousingConditions={
              this.onchangeParentsHousingConditions
            }
            onchangeParentsHousingTypeHousingOther={
              this.onchangeParentsHousingTypeHousingOther
            }
            onchangeParentsHousingValue={this.onchangeParentsHousingValue}
            page={page}
            nextPage={this.nextPage}
            deleteButton={this.deleteButton}
            deleteButtonFamilyAssets={this.deleteButtonFamilyAssets}
            addFamilyAssets={this.addFamilyAssets}
            addMembersChronicIllness={this.addMembersChronicIllness}
            deleteButtonOtherIncomeReceived={
              this.deleteButtonOtherIncomeReceived
            }
            onChangeFamilyHealthSituationServicesValue={
              this.onChangeFamilyHealthSituationServicesValue
            }
            onChangeFamilyHealthSituationServicesTypeService={
              this.onChangeFamilyHealthSituationServicesTypeService
            }
            onChangeFamilyHealthSituationRowDataTypes={
              this.onChangeFamilyHealthSituationRowDataTypes
            }
            familyHealthSituationRowData={familyHealthSituationRowData}
            bankData={bankData}
            onChangeBankData={this.onChangeBankData}
            handleSubmit={this.handleSubmit}
            onChangeParentHaveSameCep={this.onChangeParentHaveSameCep}
            parentHaveSameCep={parentHaveSameCep}
            onChangeKinship={this.onChangeKinship}
            onChangeMaritalStatus={this.onChangeMaritalStatus}
            onChangeSchooling={this.onChangeSchooling}
            onchangeModalStatus={this.onchangeModalStatus}
            modalStatus={modalStatus}
            sucessMessage={sucessMessage}
            goTo={this.goTo}
            onChangeTypePrac={this.onChangeTypePrac}
            firstTimeStatus={firstTimeStatus}
            closeFirstTimeStatusModal={this.closeFirstTimeStatusModal}
            onChangeScholarship={this.onChangeScholarship}
            onChangeDisabilities={this.onChangeDisabilities}
            onChangeHighSchoolType={this.onChangeHighSchoolType}
            onChangeDisabilitiesOptions={this.onChangeDisabilitiesOptions}
            onChangeFinancialIndependence={this.onChangeFinancialIndependence}
            onClickToChangeTab={this.onClickToChangeTab}
            deleteButtonDeleteMembersChronicIllness={
              this.deleteButtonDeleteMembersChronicIllness
            }
            onChangeEadMode={this.onChangeEadMode}
          />
        )}
      </>
    );
  }
}
