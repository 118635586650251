import styled from 'styled-components';

const ButtonDefault = styled.button`
	display: inline-block;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	border: none;
	cursor: pointer;
	:focus {
		outline: none;
	}
	:-moz-focus {
		outline: none;
	}
	:-webkit-focus {
		outline: none;
	}
`;
export const ArrowButtonRight = styled(ButtonDefault)`
	width: 100%;
	background: #2e82c8;
	max-width: 201px;
	position: relative;
	margin-right: 20px;
	font-size: 21px;
	height: 50px;
	color: #ffff;
	border-radius: 10px 0px 0px 10px;
	&:after {
		content: '';
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		position: absolute;
		top: 0;
	}
	&:after {
		border-left: 20px solid #2e82c8;
		right: -10%;
	}
	&:hover {
		background: #6277fc;
		&:after {
			border-left: 20px solid #6277fc;
		}
	}
	@media (max-width: 720px) {
		max-width: 139px;
		&:after {
			border-top: 26px solid transparent;
			border-bottom: 26px solid transparent;
			right: -14%;
			top: -2%;
		}
	}
`;
export const ArrowButtonLeft = styled(ButtonDefault)`
	width: 100%;
	height: 50px;
	background: #2e82c8;
	max-width: 200px;
	position: relative;
	margin-left: 20px;
	font-size: 21px;
	color: #ffff;
	border-radius: 0px 10px 10px 0px;
	&:before {
		content: '';
		border-top: 25px solid transparent;
		border-bottom: 25px solid transparent;
		position: absolute;
		top: 0;
	}
	&:before {
		border-right: 20px solid #2e82c8;
		left: -10%;
	}
	:hover {
		background: #6277fc;
		&:before {
			border-right: 20px solid #6277fc;
		}
	}
	@media (max-width: 720px) {
		max-width: 139px;
		&:before {
			border-top: 26px solid transparent;
			border-bottom: 26px solid transparent;
			left: -14%;
			top: -2%;
		}
	}
`;

export const ButtonShadow = styled(ButtonDefault)`
	padding: 10px 60px;
	width: 100%;
	border-radius: 30px;
	box-sizing: border-box;
	font-size: 16px;
	text-decoration: none;
	font-weight: 500;
	color: #ffffff;
	transition: all 0.3s;
	/* background-color: #d69b6b; */
	background: #6277fc;
	/* box-shadow: inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12); */
	-webkit-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
	box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
	text-align: center;
	${(props) =>
		props.medium &&
		`
    max-width: 200px;
  `}
	${(props) =>
		props.square &&
		`
    border-radius: 3px;
  `}
  ${(props) =>
		props.small &&
		`
    max-width: 100px;
  `}
  /* :active {
    box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
  } */
  &:hover {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
`;

export const ButtonPrimary = styled(ButtonDefault)`
  padding: 8px 18px;
  box-sizing: border-box;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : `#ffffff`)};
  transition: all 0.3s;
  background: ${(props) => (props.background ? props.background : `#3158AD`)};
  position:relative;
  border-radius:15px;
  ${(props) =>
		props.fixedWidth
			? `
      min-width:${props.width}px;
    width: ${props.width}px;
  `
			: `width: 100%;`}
  ${(props) =>
		props.medium &&
		`
    max-width: 300px;
  `}
  ${(props) =>
		props.medium1 &&
		`
    max-width: 170px;
  `}
  ${(props) =>
		props.medium2 &&
		`
    max-width: 150px;
  `}
  ${(props) =>
		props.micro &&
		`
      max-width: 80px;
    `}
  ${(props) =>
		props.small &&
		`
    max-width: 100px;
  `}
  ${(props) =>
		props.ghost &&
		`
    background: transparent;
    &&:hover {
      background: #6277fc;
    }
  `}
  ${(props) =>
		props.moreBlue &&
		`
    background: #0986d9;
    &&:hover {
      background: #097ac5;
    }
    
  `}
  ${(props) =>
		props.menu &&
		`
    width: 150px;
    height: 48px;`}
  ${(props) =>
		props.shadow &&
		`
  -webkit-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  `}
  &:hover {
    background: #7AA4FF;
  }

  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;
export const ButtonLogin = styled(ButtonDefault)`
  padding:10px 15px;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 20px;
  text-decoration: none;
  font-weight: 500;
  color: #ffffff;
  background: rgb(41,128,185);
  background: linear-gradient(90deg, rgba(41,128,185,1) 0%, rgba(95,144,250,1) 100%); 
  border-radius:30px;
  ${(props) =>
		props.medium &&
		`
    max-width: 200px;
  `}
  ${(props) =>
		props.big &&
		`
    max-width: 400px;
  `}
 ${(props) =>
		props.small &&
		`
    max-width: 100px;
  `}
  
  ${(props) =>
		props.moreBlue &&
		`
    background: #0986d9;
    &&:hover {
      background: #097ac5;
    }
    
  `}
  ${(props) =>
		props.menu &&
		`
    width: 150px;
    height: 48px;`}
  ${(props) =>
		props.shadow &&
		`
  -webkit-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  box-shadow: -1px 8px 11px -2px rgba(0, 0, 0, 0.2);
  `}
  &:hover {
    background: #7AA4FF;
  }

  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;
