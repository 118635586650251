import React, { useState, useEffect } from "react";
import rank from "../../services/rankApi";
import { STUDENT_RANK_COLUMNS, STUDENT_ADM_RANK_COLUMNS } from "../../utils/constants";
import { list } from "../../utils/index";
import View from "../../view/selections/admin/rank/index";

const Rank = (props) => {
  const [loading, setLoading] = useState(true);
  const [deferidos, setDeferidos] = useState([]);
  const [indeferidos, setIndeferidos] = useState([]);
  const [studentProps, setStudentProps] = useState([]);
  const [isADM, setIsADM] = useState(false);
  const [selectionName, setSelectionName] = useState('');

  const getQueryString = () => list(props)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { n: _idNotice, s: _idSelection, t: type, s_n: selectionName } = list(props);
        const { deferidos, indeferidos } = await (
          await rank.getRank(_idNotice, _idSelection, type)
        ).data.message;
        setLoading(false);
        const isADM = deferidos.length > 0 ? !!deferidos[0].rank :
          indeferidos.length > 0 ? !!indeferidos[0].rank : false;
        setStudentProps(isADM ? STUDENT_ADM_RANK_COLUMNS : STUDENT_RANK_COLUMNS)
        setIsADM(isADM)
        setSelectionName(selectionName)
        setDeferidos(isADM ? [...deferidos.map((student) => ({ ...student, fluxoLiquido: student.fluxoLiquido.toFixed(5).replace('.', ',') }))] : [...deferidos]);
        setIndeferidos([...indeferidos]);
      } catch (error) {

      }
    }
    fetchData();
  }, [props]);

  return (
    <View
      type={getQueryString().t}
      selectionName={selectionName}
      isADM={isADM}
      loading={loading}
      deferidos={deferidos}
      indeferidos={indeferidos}
      studentProps={studentProps}
    />
  );
};

export default Rank;
