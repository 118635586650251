import React from 'react';
import { CardMenu, SelectionRow400px, TitleSelectionRow } from '../../Styles';
import InputFloat from '../../../../components/InputFloat';
import Dropdown from '../../../../components/dropdown/Dropdown';

export default function identification(props) {
	const {
		identification,
		isTrueTxt,
		onChangePracUser,
		onChangeGenderIdentityOptions,
		onChangeEthnicRacialBelongingOptions,
		onChangeSexualOrientationOptions,
		onChangeElementarySchoolType,
		onChangeSomeoneFamilyGraduate,
		onChangeAge,
		onChangeRg,
		onChangeSocialName,
		onchangeOtherGraduateCourse,
		identificationDropdowns,
		booleanDropDown,
		onChangeTypePrac,
		onChangeOtherGraduateOption,
		onChangeScholarship,
		onChangeFinancialIndependence,
		onChangeDisabilities,
		onChangeHighSchoolType,
		onChangeDisabilitiesOptions,
		admMode
	} = props;

	return (
		<CardMenu>
			<SelectionRow400px>
				<InputFloat label="Nome civil" name={'name'} disabled value={identification.name} />
				<InputFloat label="Nome social (caso utilize)" name={'socialName'} value={identification.socialName} onChange={onChangeSocialName} />

				<InputFloat label="Curso" name={'course'} disabled value={identification.course} />
				<InputFloat label="Sexo biológico" name={'sex'} disabled value={identification.sex} />
				<Dropdown
					smallFont
					placeholder="Identidade de gênero"
					options={identificationDropdowns.genderIdentityOptions}
					value={identification.genderIdentity}
					onChange={onChangeGenderIdentityOptions}
				/>
				<Dropdown
					smallFont
					placeholder="Orientação sexual"
					options={identificationDropdowns.sexualOrientationOptions}
					value={identification.sexualOrientation}
					onChange={onChangeSexualOrientationOptions}
				/>
				<InputFloat
					label="Estado civil"
					name={'maritalStatus'}
					disabled
					value={identification.maritalStatus}
				/>
				<InputFloat label="CPF" name={'cpf'} disabled value={identification.cpf} />
				<InputFloat label="RG" disabled value={identification.rg} onChange={onChangeRg} />

				<InputFloat
					label="Data de anivérsario"
					name={'birthday'}
					disabled
					value={identification.birthday}
				/>

				<InputFloat label="Telefone" disabled value={identification.phone} />
				<InputFloat label="Email" name={'email'} disabled value={identification.email} />
				<InputFloat
					label="Forma de ingresso"
					name={'highSchoolType'}
					disabled
					value={identification.entryForm.option}
				/>
				<InputFloat label="Idade" disabled value={String(identification.age)} onChange={onChangeAge} />
				<InputFloat label="Cor/raça" name={'color'} disabled value={identification.color} />
			</SelectionRow400px >
			<SelectionRow400px >
				<InputFloat
					label="Deficiência ♿*"
					name={'disables'}
					disabled
					value={identification.originalData && identification.originalData.disabilities ? (
						((identification.originalData.disabilities.length > 0) && admMode) ?
							identification.originalData.disabilities.join(', ') :
							isTrueTxt(identification.originalData.disabilities.length > 0)

					) : isTrueTxt(identification.disabilities.option)
					}
					helperText={'*Declarado no Controle Acadêmico'}
				/>
				<InputFloat
					smallFont
					label="Onde cursou o Ensino Médio 🏫*"
					name={'highSchoolType'}
					disabled
					value={identification.originalData && identification.originalData.highSchoolType ?
						identification.originalData.highSchoolType :
						identification.highSchoolType}
					helperText={'*Declarado no Controle Académico'}
				/>
			</SelectionRow400px >

			<TitleSelectionRow middleTitle>
				<p>Informações Adicionais</p>
			</TitleSelectionRow>
			<SelectionRow400px>
				<Dropdown
					placeholder="PcD ♿"
					value={isTrueTxt(identification.disabilities.option)}
					onChange={onChangeDisabilities}
					options={booleanDropDown}
				/>
				{identification.disabilities.option && (
					<Dropdown
						placeholder="Tipo de deficiência ♿"
						options={identificationDropdowns.disabilitiesType}
						value={!!identification.disabilities.types && identification.disabilities.types.length > 0 ? identification.disabilities.types.map((disability) => disability.disabilityType).join(', ') : ''}
						onChange={onChangeDisabilitiesOptions}
					/>
				)}

				<Dropdown
					placeholder="Onde cursou o Ensino Médio 🏫"
					value={identification.highSchoolType}
					onChange={onChangeHighSchoolType}
					options={identificationDropdowns.highSchoolType}
				/>

				<Dropdown
					smallFont
					placeholder="Onde cursou o Ensino Fundamental 🛴"
					options={identificationDropdowns.elementarySchoolType}
					value={identification.elementarySchoolType}
					onChange={onChangeElementarySchoolType}
				/>

				<Dropdown
					placeholder="Tem bolsa acadêmica"
					options={booleanDropDown}
					value={isTrueTxt(identification.scholarship.option)}
					onChange={onChangeScholarship}
				/>

				{identification.scholarship.option && (
					<>
						<InputFloat
							label="Tipo da bolsa acadêmica"
							name="typeScholarship"
							value={identification.scholarship.typeScholarship}
							onChange={onChangeScholarship}
						/>
						<InputFloat
							isMoney
							label="Valor da bolsa acadêmica"
							name="value"
							value={identification.scholarship.value ? String(identification.scholarship.value) : ''}
							onChange={onChangeScholarship}
						/>
					</>
				)}

				<Dropdown
					smallFont
					placeholder="Pertencimento étnico-racial"
					options={identificationDropdowns.ethnicRacialBelongingOptions}
					value={identification.ethnicRacialBelonging}
					onChange={onChangeEthnicRacialBelongingOptions}
				/>

				<Dropdown
					placeholder="Usuário da prac"
					options={booleanDropDown}
					value={isTrueTxt(identification.pracUser.option)}
					onChange={onChangePracUser}
				/>
				{identification.pracUser.option && (
					<InputFloat
						label="Tipo de usuário da prac"
						onChange={onChangeTypePrac}
						value={identification.pracUser.typePRAC}
					/>
				)}
				<Dropdown
					placeholder="Familiar graduado"
					options={booleanDropDown}
					value={isTrueTxt(identification.someoneFamilyGraduate)}
					onChange={onChangeSomeoneFamilyGraduate}
				/>
				<Dropdown
					placeholder="Outra graduação"
					options={booleanDropDown}
					value={isTrueTxt(identification.otherGraduate.option)}
					onChange={onChangeOtherGraduateOption}
				/>
				{identification.otherGraduate.option && (
					<InputFloat
						label="Qual a graduação?"
						value={identification.otherGraduate.course}
						onChange={onchangeOtherGraduateCourse}
					/>
				)}
				<Dropdown
					placeholder="É independente financeiramente?"
					options={booleanDropDown}
					value={isTrueTxt(identification.financialIndependence)}
					onChange={onChangeFinancialIndependence}
				/>
			</SelectionRow400px>

			{/* <SelectionRow200px style={{ marginTop: '0.5em' }}>
				<Dropdown
					placeholder="Você possui equipamento para participação das atividades acadêmicas remotas?"
					options={booleanDropDown}
					value={isTrueTxt(identification.eadMode.haveEquipment)}
					onChange={(e) => onChangeEadMode('haveEquipment', e.value === 'Sim' ? true : false)}
				/>
				{identification.eadMode.haveEquipment &&
					<Dropdown
						placeholder="Qual dos equipamentos utiliza para as atividades acadêmicas remotas?"
						options={identificationDropdowns.eadMode.equipmentType}
						value={identification.eadMode.equipmentType}
						onChange={(e) => onChangeEadMode('equipmentType', e.value)}
					/>
				}
			</SelectionRow200px>
			<SelectionRow200px style={{ marginTop: '0.5em' }}>
				<Dropdown
					placeholder="Você possui acesso à rede de internet?"
					options={booleanDropDown}
					min
					value={isTrueTxt(identification.eadMode.haveInternetAccess)}
					onChange={(e) => onChangeEadMode('haveInternetAccess', e.value === 'Sim' ? true : false)}
				/>
				{identification.eadMode.haveInternetAccess &&
					<Dropdown
						placeholder="Qual tipo de rede utiliza?"
						options={identificationDropdowns.eadMode.internetType}
						value={identification.eadMode.internetType}
						onChange={(e) => onChangeEadMode('internetType', e.value)}
					/>
				}
			</SelectionRow200px> */}


		</CardMenu>
	);
}
