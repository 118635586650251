import React, { Component } from 'react';
import NoticeApi from '../../services/NoticeApi';
import Loader from '../../components/loader/Loder';
import TransparentButton from '../../components/transparentButton/TransparentButton';
import NoticeView from '../../view/notice/NoticeView';
import moment from 'moment';
import { Growl } from 'primereact/growl';
import 'moment/locale/pt-br';
export default class NoticesController extends Component {
	constructor(props) {
		super(props);

		this.state = {
			notices: [],
			loading: true,
			selectedNotice: {},
			noticeView: false,
			isSuperADM: false,
			modalStatus: false,
			noticeModalData: {
				name: '',
				registrationPeriodBegins: '',
				registrationPeriodEnds: '',
				parcialDeadline: '',
				finalDeadline: '',
			},
		};
	}
	componentDidMount() {
		Promise.all([NoticeApi.getNotices("all"), NoticeApi.isSuperADM()]).then(
			([res, res1]) =>
				this.setState({
					loading: false,
					notices: res.data.message,
					isSuperADM: res1.data.success,
				})
		);
	}
	buttonContainer = (rowData) => {
		const { notices } = this.state;
		const { _id } = rowData;
		const selectedNotice = notices.filter((notice) => notice._id === _id)[0];
		return (
			<div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
				<TransparentButton
					onClick={() => this.props.history.push(`/usuario/admin/selecoes?noticeId=${selectedNotice._id}`)}
				>
					Ver
				</TransparentButton>
				{this.deleteButton(rowData)}
			</div>
		);
	};
	isPatternNotice = (boolean) => (boolean.patternNotice ? 'Sim' : 'Não');
	formatRowDate = (rowData, column) => moment(rowData[column.field]).format('L');
	deleteButton = (rowData) =>
		rowData.canBeDeleted &&
		<TransparentButton
			onClick={() => this.deleteNotice(rowData._id)}
		>
			Deletar
		</TransparentButton >

	deleteNotice = (id) =>
		this.setState({ loading: true }, () => {
			NoticeApi.deleteNotice(id).then((res) => {
				NoticeApi.getNotices('all').then((res) =>
					this.setState({ loading: false, notices: res.data.message }, () => this.showMessage('success', "Edital criado"))
					, (err) =>
						this.setState({ loading: false }, () => this.showMessage('error', err.response.data.message))

				)
			}, (err) => this.setState({ loading: false }, () => this.showMessage('error', err.response.data.message)))
		})
	createNotice = () =>

		NoticeApi.createNotice({ ...this.state.noticeModalData }).then(
			() =>
				NoticeApi.getNotices('all').then((res) =>
					this.setState({
						notices: res.data.message, modalStatus: false,
						noticeModalData: {
							name: '',
							registrationPeriodBegins: '',
							registrationPeriodEnds: '',
							parcialDeadline: '',
							finalDeadline: '',
						},
					},
						() => this.showMessage('success', "Edital criado"))
					, (err) =>
						this.showMessage('error', err.response.data.message)
				)
			, (err) =>
				this.showMessage('error', err.response.data.message)
		)
	showMessage = (type, message) =>
		this.growl.show({
			severity: type,
			summary: message
		})
	onChangeNoticeData = (e, type) =>
		this.setState({ noticeModalData: { ...this.state.noticeModalData, [type]: e.target.value } });
	onChangeModalStatus = () => this.setState({ modalStatus: !this.state.modalStatus });
	goTo = (path) => this.props.history.push(path);

	render() {
		const { notices, loading, isSuperADM, noticeModalData, modalStatus } = this.state;
		return (
			<>
				{loading ? (
					<Loader start={loading} />
				) : (
					<>
						<Growl ref={(el) => (this.growl = el)} />

						<NoticeView
							notices={notices}
							modalStatus={modalStatus}
							noticeModalData={noticeModalData}
							isPatternNotice={this.isPatternNotice}
							formatRowDate={this.formatRowDate}
							buttonContainer={this.buttonContainer}
							isSuperADM={isSuperADM}
							onChangeNoticeData={this.onChangeNoticeData}
							onChangeModalStatus={this.onChangeModalStatus}
							createNotice={this.createNotice}
							deleteButton={this.deleteButton}
							goTo={this.goTo}
						/>
					</>
				)}
			</>
		);
	}
}
