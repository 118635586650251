import styled from 'styled-components';

export const Container = styled.div`
	background-color: #8DC4F2;
	border-radius: 13px;
	border: none;
	box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.25);
	div{
		width: 100%;
	}
	${props => props.disabled && `
		background-color: #F0F0F0 !important;
		border-radius: 13px !important;
		border: none !important;
		color: black !important;
		box-shadow: none !important;
		`

	}
`
