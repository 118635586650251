const DistanceTypeEnum = Object.freeze({
    ORIGIN_TO_CAMPUS: "origin",
    HOME_TO_CAMPUS: "campus",

    values: function () {
        return Object.values(this);
    },

    find(filter) {
        const types = this.values();
        return types.find((type) => type === filter);
    }
});

module.exports = DistanceTypeEnum;