import React from "react";
import {
  CardMenu,
  ButtonSpace,
  DataTableFamiliarGroup,
  DataTableContainer,
  SelectionRow200px,
  Text,
} from "../../Styles";
import InputFloat from "../../../../components/InputFloat";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
const StyledColumn = {
  border: "none",
  borderBottom: "1px solid gray",
  background: "#ffffff",
  textAlign: "center",
};

export default function familyAssets(props) {
  const {
    familyAssetsRowData,
    onChangeFamilyAssetsRowData,
    familyAssets,
    addFamilyAssets,
    deleteButtonFamilyAssets,
  } = props;

  return (
    <CardMenu>
      <Text>
        Descrição do Bem (Casa, apartamento, sala comercial, veículo
        [ano/marca/modelo], cotas de empresa, aplicações financeiras, poupança,
        lote rural, implementos agrícolas, gado, etc.)
      </Text>
      <SelectionRow200px>
        <InputFloat
          label="Descrição"
          name="description"
          value={familyAssetsRowData.description}
          onChange={onChangeFamilyAssetsRowData}
        />
        <InputFloat
          label="Município"
          name="city"
          value={familyAssetsRowData.city}
          onChange={onChangeFamilyAssetsRowData}
        />
        <InputFloat
          label="Valor (R$)"
          isMoney
          name="value"
          value={familyAssetsRowData.value}
          onChange={onChangeFamilyAssetsRowData}
        />
      </SelectionRow200px>
      <ButtonSpace>
        <Button onClick={addFamilyAssets} label="adicionar"></Button>
      </ButtonSpace>
      <DataTableContainer length={familyAssets.length}>
        <DataTableFamiliarGroup>
          <DataTable emptyMessage={"Não há bens"} value={familyAssets}>
            <Column
              field="description"
              header="Descrição"
              style={StyledColumn}
            />
            <Column field="city" header="Município" style={StyledColumn} />
            <Column field="value" header="Valor" style={StyledColumn} />

            <Column
              style={StyledColumn}
              body={deleteButtonFamilyAssets.bind(this)}
            />
          </DataTable>
        </DataTableFamiliarGroup>
      </DataTableContainer>
    </CardMenu>
  );
}
