const DisabilityEnum = Object.freeze({
    DEFICIENCIA_AUDITIVA: "Deficiência auditiva",
    SUDEZ: "Surdez",
    CEGUEIRA: "Cegueira",
    BAIXA_VISAO: "Baixa visão",
    BAIXA_VISAO_OU_VISAO_MONOCULAR: "Baixa visão ou visão monocular",
    DEFICIENCIA_FISICA: "Deficiência física",
    DEFICIENCIA_MULTIPLO: "Deficiência múltipla",
    SURDOCEGUEIRA: "Surdocegueira",
    DEFICIENCIA_INTELECTUAL: "Deficiência intelectual",
    AUTISMO: "Autismo",
    SINDROME_ASPERGER: "Síndrome de Asperger",
    SINDROME_RETT: "Síndrome de Rett",
    TRANSTORNO_DESINTEGRATIVO_DA_INFANCIA: "Transtorno Desintegrativo da Infência",
    TRANSTORNO_ESPECTRO_AUTISTA: "Transtorno do espectro autista",
    OUTRA: "Outra",
   
    values: function () {
        return Object.values(this);
    }
});

module.exports = DisabilityEnum;