import React, { useState, useEffect } from "react";
import RankApi from "../../services/rankApi";
import View from "../../view/selections/student/rank/index";

const Rank = (props) => {
  const [loading, setLoading] = useState(true);
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { message: selections } = (await RankApi.getSelections()).data;
        setLoading(false);
        setSelections([...selections]);

      } catch (error) {

      }
    }
    fetchData();
  }, [props]);
  const goTo = (path) => props.history.push(path);

  return (
    <View
      loading={loading}
      selections={selections}
      goTo={goTo}
    />
  );
};

export default Rank;
